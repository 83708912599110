import { useTranslation } from 'react-i18next';
import useCommonStore from "../../../store/common/store";
import useStore from "../../../store/signup/store";

const Submit = ({ showSubmitBtn, guardianAgree, handleRegister }) => {
  const { t } = useTranslation();                                                                  // 다국어
  const langCd = useCommonStore((state) => state.langCd);                                          // Get 언어
  const user = useStore((state) => state.user);                                                    // Get 유저
  const policy = useStore((state) => state.policy);                                                // Get 약관
  const error = useStore((state) => state.error);                                                  // Get 에러문구
  const setError = useStore((state) => state.setError);                                            // Set 에러문구
  const setPopup = useStore((state) => state.setPopup);                                            // Set Popup
  const isAsia = (user.phoneNationNum === '82' || user.phoneNationNum === '81') ? true : false;    // 한국+일본인지?

  // 가입 완료
  const onRegister = () => {
    if (error.email !== '' || error.birthday !== '' || error.id !== '' || error.password !== '' || error.confirmPassword !== '') {
      return;
    }
    if (user.password !== user.confirmPassword) {
      setError({ ...error, password: '', confirmPassword: t("비밀번호가 일치하지 않아요.") });
      return;
    }
    if (user.showAuth) {
      if (user.guardiansNm === '' || user.guardiansMphoneNum.length === '') {
        setPopup({ type: 'alert', message: t("14세 미만 회원가입은 보호자의 가입동의 휴대폰 본인 인증이 필요해요."), isOpen: true });
        return;
      }
      if (!guardianAgree) {
        setPopup({ type: 'alert', message: t("보호자 가입동의에 체크해 주세요."), isOpen: true });
        return;
      }
    }
    const filterPolicy = policy.map((p) => {
      delete p.essenOrSelectGbnCdNm;
      delete p.langCdNm;
      delete p.policyTypeNm;
      p.userID = user.userID;
      p.langCd = langCd;
      return p;
    });
    const month = '0' + user.birthday.month;
    const day = '0' + user.birthday.day;
    const registerData = {
      userID: user.userID,
      pwd: user.joinTypeCd === '20' ? '' : user.password,
      nmFamily: user.nmFamily,
      nmFirst: user.nmFirst,
      nmFull: isAsia ? user.nmFamily + user.nmFirst : user.nmFirst + ' ' + user.nmFamily,
      phoneNationNum: user.phoneNationNum,
      mPhoneNum: user.mPhoneNum,
      nationCd: user.nationCd,
      authCode: user.authCode,
      isAuth: user.isAuth,
      birthDt: user.birthday.year + '-' + month.slice(-2) + '-' + day.slice(-2),
      email: user.email,
      joinTypeCd: user.joinTypeCd,
      openIDTypeCd: user.openIDTypeCd,
      is14UnderAge: user.showAuth ? true : false,
      guardiansNm: user.guardiansNm,
      guardiansMphoneNum: user.guardiansMphoneNum,
      dormantPreventionTermCd: '',
      isAgreeMarketingSmsMsg: user.isAgreeMarketingSmsMsg,
      isAgreeMarketingEmail: user.isAgreeMarketingEmail,
      isJitalkID: user.isJitalkID,
      policyJson: JSON.stringify(filterPolicy),
    }
    handleRegister(registerData);
  }

  return (
    <div className="btn-wrap fixed">
      {showSubmitBtn
        ? <button type='button' className='btn lg green' onClick={onRegister}>{t("가입 완료")}</button>
        : <button type='button' className='btn lg gray'>{t("가입 완료")}</button>
      }
    </div>
  )
}

export default Submit