import { useState } from 'react'
import client from '../../lib/api/signup';
import { useTranslation } from 'react-i18next';
import useCommonStore from "../../store/common/store";
import useStore from "../../store/signup/store";
import Register from '../../components/signup/register/Register';
import Header from '../../components/signup/Header';
import Alert from '../../components/signup/Alert';
import Loader from '../../components/common/Loader';
import { useEffect } from 'react';

const RegisterPage = () => {
  const [isLoading, setIsLoading] = useState(false);           // 로딩
  const { t } = useTranslation();                              // 다국어
  const langCd = useCommonStore((state) => state.langCd);
  const setLangCd = useCommonStore((state) => state.setLangCd);
  const setNationCd = useCommonStore((state) => state.setNationCd);
  const nationCd = useCommonStore((state) => state.nationCd);
  const policy = useStore((state) => state.policy);            // Get 약관
  const setPolicy = useStore((state) => state.setPolicy);      // Set 약관
  const user = useStore((state) => state.user);                // Get 유저
  const setUser = useStore((state) => state.setUser);          // Set 유저
  const error = useStore((state) => state.error);              // Get 에러문구
  const setError = useStore((state) => state.setError);        // Set 에러문구
  const popup = useStore((state) => state.popup);              // Get Popup
  const setPopup = useStore((state) => state.setPopup);        // Set Popup
  const [password, setPassword] = useState('');                // 패스워드

  const setCookie = function(name, value, exp) {
    const date = new Date();
    date.setTime(date.getTime() + exp*24*60*60*1000);
    document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + ';path=/';
  };

  // 보호자 본인 인증
  const handleCertification = () => {
    setCookie("earthtalkCommon", JSON.stringify({ langCd: langCd, nationCd: nationCd }), 1);
    setCookie("earthtalkUser", JSON.stringify(user), 1);
    setCookie("earthtalkPolicy", JSON.stringify(policy), 1);
    window.location.href = `https://wsapi.brainworld.com/MemberCertification/AuthCall.aspx?srvNo=012001&retUrl=${escape('https://testfront.earthtalk.app/signup/authentication/callback')}`;
    // window.location.href = `https://testfront.earthtalk.app/signup/authentication/callback?Name=jeon&cellNo=01072781107`;

    /*
    // 웹뷰 띄우기 호출
    window.open("https://wsapi.brainworld.com/MemberCertification/AuthCall.aspx?srvNo=012001&retUrl=" + escape("https://testfront.earthtalk.app/signup/authentication/callback"), "authentication", `width = 200, height = 200, top = 0, left = 0, location = no`);

    // 네이티브로부터 값을 받아오면 셋팅
    setUser({ ...user, guardiansNm: name, guardiansMphoneNum: phonenumber });
    setError({ ...error, birthday: '' });
    */
  }

  // 아이디 중복 체크
  const handleUserUsedUserIdCheck = async (id) => {
    try {
      const { data } = await client.post('/member/userUsedUserIDCheck', { userID: id });
      if (data.content.Msg === 'Use') {
        setError({ ...error, id: t('사용할 수 없는 아이디에요. 다른 아이디를 입력해주세요.') });
      } else if (data.content.Msg === 'Empty') {
        setError({ ...error, id: '' });
      }
    } catch (e) {
      setPopup({ type: 'alert', message: 'Server Error.', isOpen: true });
    }
  }

  // 가입하기
  const handleRegister = async (registerData) => {
    setIsLoading(true);
    try {
      setPassword(registerData.pwd);
      if (user.joinTypeCd !== '20') {
        const resPassword = await client.post('/sha256', { password: registerData.pwd });
        registerData.pwd = resPassword.data;
      }
      const { data } = await client.post('/member/userInsert', registerData);
      setIsLoading(false);
      if (data.content.status === 'failed') {
        setPopup({ type: 'alert', message: data.content.data, isOpen: true });
        return;
      }
      setPopup({ type: 'completeRegister', message: '', isOpen: true });
    } catch (e) {
      setIsLoading(false);
      setPopup({ type: 'alert', message: 'Server Error.', isOpen: true });
    }
  }

  // 회원가입 완료 > 로그인
  const handleLogin = () => {
    const agent = navigator.userAgent.toLowerCase();
    let userData = {
      userID: user.userID,
      pwd: password,
      social: user.openIDTypeCd
    }
    if (agent.indexOf('android') > -1) {
      window.Android.authSubmitComplete(JSON.stringify(userData));
    } else if (agent.indexOf("iphone") > -1 || agent.indexOf("ipad") > -1 || agent.indexOf("ipod") > -1 || agent.indexOf("apple") > -1) {
      window.webkit.messageHandlers.authSubmitComplete.postMessage(JSON.stringify(userData));
    } else {
      setPopup({ type: 'alert', message: 'No native APIs found.', isOpen: true });
    }
  }

  const getCookie = function(name) {
    const value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
      return value ? value[2] : null;
  };

  useEffect(() => {
    try {
      const commonCookie = JSON.parse(getCookie("earthtalkCommon")) ?? '';
      if (commonCookie !== '') {
        setLangCd(commonCookie.langCd);
        setNationCd(commonCookie.nationCd);
        setUser({ ...JSON.parse(getCookie("earthtalkUser"))});
        setPolicy(JSON.parse(getCookie("earthtalkPolicy")));
      }
    } catch (e) {
      setPopup({ type: 'alert', message: e, isOpen: true });
    }
  }, []);

  return (
    <div className='signup'>
      {isLoading && <Loader />}
      <Header prev='home' />
      <Register
        handleUserUsedUserIdCheck={handleUserUsedUserIdCheck}
        handleRegister={handleRegister}
        handleCertification={handleCertification}
      />
      {popup.isOpen &&
        <Alert
          handleLogin={handleLogin}
      />}
    </div>
  )
}

export default RegisterPage