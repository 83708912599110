import { useTranslation } from 'react-i18next';
import Parser from "html-react-parser";
import Header from './Header';
import styled from 'styled-components';
import { useEffect } from 'react';

const PolicyDetail = ({ policyDetailContent, setPolicyDetailContent, setOpenPolicyDetail, onCheckedElement }) => {
  const { t } = useTranslation();

  // 뒤로가기
  const onClosePopup = () => {
    setOpenPolicyDetail(false);
  }

  // 약관 동의
  const onAgree = () => {
    onCheckedElement(true, policyDetailContent.policyTypeCd);
    setOpenPolicyDetail(false);
  }

  useEffect(() => {
    return () => {
      setPolicyDetailContent('');
    }
  }, []);

  return (
    <Container>
      <Header prev='closePopup' onClosePopup={onClosePopup} title={policyDetailContent.policyTypeNm} />
      <div className="body">
        <div className="content-box">
          {policyDetailContent?.policyContent && Parser(policyDetailContent?.policyContent)}
        </div>
        <div className="btn-box">
          <button type='button' className='btn lg green' onClick={onAgree}>{t("동의하기")}</button>
        </div>
      </div>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 19;
  .body {
    max-height: calc(100vh - 5.2rem);
    overflow-y: auto;
    .content-box {
      padding: 2rem;
      color: #777;
      background-color: #f9f9f9;
    }
    .check-box {
      margin-top: 2rem;
      label {
        cursor: pointer;
      }
    }
    .btn-box {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }
`;

export default PolicyDetail