import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Popup from '../layout/Popup';
import Button from '../layout/Button';
import styled from "styled-components";

const List = () => {
  const navigate = useNavigate();
  const [showNicknamePopup, setShowNicknamePopup] = useState(false);

  useEffect(() => {
    // 닉네임 설정하지 않았으면
    setShowNicknamePopup(true);
  }, []);

  return (
    <Container>
      닉네임 설정하기 누르면 닉네임 설정 화면으로 이동<br/>
      최초 1회 튜토리얼 팝업 띄움 (슬라이드)
      {showNicknamePopup &&
        <Popup hasClose onClose={() => setShowNicknamePopup(false)}>
          <div className="popup">
            <h1>e타운 프로필 설정</h1>
            <p>e타운 내 다양한 활동을 위해서는 닉네임 설정이 꼭 필요해요.</p>
            <p><span className="fc-red">지금 바로 닉네임 설정을 해주세요.</span></p>
            <div className='btn-wrap'>
              <Button type='button' color='mint' title='닉네임 설정하기' onClick={() => navigate('/cummunity/mypage/profile')} />
              <Button type='button' color='yellow' title='대화명 그대로 쓰기' marginTop onClick={() => setShowNicknamePopup(false)} />
            </div>
          </div>
        </Popup>
      }
    </Container>
  )
}

const Container = styled.div`
  .popup {
    text-align: center;
    word-break: keep-all;
    h1 {
      font-size: 1.7rem;
      font-weight: 700;
      color: #222;
    }
    p {
      margin-top: 1rem;
      font-size: 1.7rem;
      color: #222;
    }
    .btn-wrap {
      margin-top: 2.6rem;
    }
  }
`;

export default List;