import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useCommonStore from '../../../store/common/store';
import useStore from '../../../store/hwadap/store';
import styled from "styled-components";

const Navigation = () => {
  const navigate = useNavigate();                                            // 네비게이트
  const langCd = useCommonStore((state) => state.langCd);                    // Get 언어
  const isPC = useCommonStore((state) => state.isPC);                        // Get IsPC
  const hwadap = useStore((state) => state.hwadap);                          // Get 화답 리스트
  const setIsIncomplete = useStore((state) => state.setIsIncomplete);        // Set 미화답 체크 여부
  const setRoomScrollTop = useStore((state) => state.setRoomScrollTop);      // Set 스크롤 위치
  const [count, setCount] = useState(0);                                     // 미화답 카운트

  /** 전체 화답 리스트로 이동 */
  const goAllHwadap = () => {
    setIsIncomplete(false);
    setRoomScrollTop(window.scrollY);
    navigate(`/hwadap/all?langcd=${langCd}`);
  }

  /** 미화답 리스트로 이동 */
  const goIncomplete = () => {
    setIsIncomplete(true);
    setRoomScrollTop(window.scrollY);
    navigate(`/hwadap/all?langcd=${langCd}`);
  }

  /** 미화답 카운트 */
  useEffect(() => {
    const today = new Date();
    const list = hwadap.filter((i) => {
      const magamDay = new Date(i.magamTimeZoneDtm);
      const length = i.myHdap?.length ?? 0;
      if (magamDay > today && length < 1) {
        return i;
      }
    });
    setCount(list.length);
  }, [hwadap]);

  return (
    <Container className={isPC && 'pc'}>
      <button className='list' onClick={goAllHwadap}>
        <span><span className="blind">전체 화답 리스트</span></span>
      </button>
      <button className='incomplete' onClick={goIncomplete}>
        <span><span className="blind">미화답 리스트</span><span className="count">{count}</span></span>
      </button>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  width: 100%;
  background-color: #63696f;
  z-index: 9;
  &.pc {
    a, button {
      > span {
        width: 3rem;
        height: 3rem;
      }
      &.incomplete {
        > span {
          .count {
            height: 1.8rem;
            line-height: 1.5rem;
          }
        }
      }
    }
  }
  a, button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 5rem;
    text-align: center;
    > span {
      width: 2.5rem;
      height: 2.5rem;
      background-size: 100%;
    }
    &.list {
      > span {
        background-image: url("${require('../../../assets/images/hwadap/icon_list.png')}");
      }
    }
    &.incomplete {
      border-left: 1px solid #929699;
      > span {
        display: flex;
        padding-left: 2.2rem;
        background-image: url("${require('../../../assets/images/hwadap/icon_incomplete.png')}");
        .count {
          height: 2rem;
          padding: 0 0.5rem 0.1rem 0.5rem;
          font-size: 1.2rem;
          font-weight: 700;
          color: #fff;
          border-radius: 0.5rem;
          background-color: #ff5d18;
          transform: translateY(-0.4rem);
        }
      }
    }
  }
`;

export default Navigation