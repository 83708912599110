import styled from "styled-components"

const Attach = ({ messageLang, attach, onMedia, handleFileDownload }) => {
  const getFileName = (oriFileNm) => {
    const fileLength = oriFileNm.length;
    const lastDot = oriFileNm.lastIndexOf('.');
    const fileExtension = oriFileNm.substring(lastDot + 1, fileLength);
    let fileName = oriFileNm.substring(0, lastDot);
    const length = window.innerWidth / 50;
    if (fileLength > length) fileName = fileName.substr(0, length) + '..';
    return fileName + fileExtension;
  }

  return (
    <Container>
      <ul>
        {attach.map((file, index) => (
          <li key={index} className={messageLang !== file.langCd ? 'hide' : ''}>
            {file.mediaTypecd === 'FILE' &&
              <>
                <h3>{getFileName(file.oriFileNm)}</h3>
                <button type='button' className="download" onClick={() => handleFileDownload(file.filePath + file.fileNm, file.oriFileNm)}>
                  <span className="blind">다운로드</span>
                </button>
              </>
            }
            {file.mediaTypecd === 'LNK' &&
              <a className='link' href={file.linkUrl} target="_blank">{file.linkUrl}</a>
            }
            {file.mediaTypecd === 'IMG' &&
              <>
                <h3>{getFileName(file.oriFileNm)}</h3>
                <button type='button' className="download" onClick={(e) => handleFileDownload(file.filePath + file.fileNm, file.oriFileNm)}>
                  <span className="blind">다운로드</span>
                </button>
              </>
            }
            {(file.mediaTypecd === 'MOV' || file.mediaTypecd === 'AUD') &&
              <>
                <h3>{getFileName(file.oriFileNm)}</h3>
                <button type='button' className="play" onClick={() => onMedia(file.mediaKey)}>
                  <span className="blind">재생</span>
                </button>
              </>
            }
          </li>
        ))}
      </ul>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 1.8rem;
  padding-top: 1.8rem;
  border-top: 1px solid #dfdfdf;
  ul {
    background-color: #f8f8f8;
    border: 1px solid #dfdfdf;
    border-radius: 0.7rem;
    overflow: hidden;
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.6rem 1.2rem;
      min-height: 4.3rem;
      border-bottom: 1px solid #dfdfdf;
      &.hide {
        display: none;
      }
      &:last-of-type {
        border-bottom: 0;
      }
      h3, a {
        width: 100%;
        padding-right: 1rem;
        font-size: 1.5rem;
        font-weight: 400;
        color: #317fc8;
        &.link {
          padding-right: 0;
          text-decoration: underline;
          background-image: url("${require('../../../assets/images/hwadap/icon_attach_link.png')}");
          background-repeat: no-repeat;
          background-size: 2.2rem;
          background-position: right 0.6rem center;
        }
      }
      button {
        width: 3.05rem;
        height: 3.05rem;
        background-size: 100%;
        flex-shrink: 0;
        &.play {
          background-image: url("${require('../../../assets/images/hwadap/icon_play_attach.png')}");
        }
        &.img {
          background-image: url("${require('../../../assets/images/hwadap/icon_attach_image.png')}");
        }
        &.download {
          background-image: url("${require('../../../assets/images/hwadap/icon_download_attach.png')}");
        }
      }
    }
  }
`;

export default Attach