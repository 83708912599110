import React from 'react'
import Header from "../../components/site/layout/Header";
import Home from "../../components/site/Home";
import Footer from "../../components/site/layout/Footer";

const HomePage = () => {
  return (
    <div className="site">
      <Header title="home" />
      <Home />
      <Footer />
    </div>
  )
}

export default HomePage