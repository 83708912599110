import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import useCommonStore from '../../../store/site/common';
import useStore from '../../../store/site/store';
import client from '../../../lib/api/customer';
import Header from '../../../components/site/layout/Header';
import HelpDetail from '../../../components/site/help/HelpDetail';
import Footer from '../../../components/site/layout/Footer';

const HelpDetailPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const langCd = useCommonStore((state) => state.langCd);
  const user = useStore((state) => state.user);
  const [helpDetail, setHelpDetail] = useState('');

  const getHelpDetail = async () => {
    try {
      const { data } = await client.post("/homepage/NoticeView", {
        langCd,
        userId: user.userId,
        noticeIdx: id.toString(),
      });
      setHelpDetail(data.content);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getHelpDetail();
  }, []);

  return (
    <>
      <Helmet>
        {langCd === "ko"
          ? <title>Zero(지로) 도움말</title>
          : <title>Zero - Help</title>
        }
        <meta name="url" property="og:url" content={`www.zerohsp.com/customer/help/${id}`} />
      </Helmet>
      <div className="site">
        <Header title={t('도움말')} prev='back' page="detail" />
        {helpDetail && <HelpDetail helpDetail={helpDetail} />}
        <Footer />
      </div>
    </>
  )
}

export default HelpDetailPage