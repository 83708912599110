import { useEffect, useState, useRef } from 'react';
import useStore from "../../../store/signup/store";
import Email from './Email';
import Password from '../Password';
import Birthday from './Birthday';
import ID from './ID';
import Submit from './Submit';
import styled from 'styled-components';

const Register = ({ handleUserUsedUserIdCheck, handleRegister, handleCertification }) => {
  const wrapRef = useRef();                                              // DIV Ref
  const user = useStore((state) => state.user);                          // Get 언어
  const error = useStore((state) => state.error);                        // Get 에러문구
  const [guardianAgree, setGuardianAgree] = useState(false);             // 보호자 가입 동의 여부
  const [showSubmitBtn, setShowSubmitBtn] = useState(false);             // 가입하기 버튼

  // 스크롤 맨 아래로
  const scrollToBottom = () => {
    setTimeout(() => {
      wrapRef.current.scrollIntoView({ behavior: 'auto', block: 'end', inline: 'nearest' });
    }, 100);
  }

  // 가입완료 버튼 핸들링
  useEffect(() => {
    // SNS 회원가입일 경우
    if (user.joinTypeCd === '20') {
      if (error.email !== '' || error.birthday !== '') {
        setShowSubmitBtn(false);
        return;
      }
      if (user.birthday.year === '' || user.birthday.month === '' || user.birthday.day === '') {
        setShowSubmitBtn(false);
        return;
      }
    }
    // 일반 회원가입일 경우
    else {
      if (error.email !== '' || error.birthday !== '' || error.id !== '' || error.password !== '' || error.confirmPassword !== '' || user.birthday.year === '' || user.birthday.month === '' || user.birthday.day === '' || user.password === '' || user.confirmPassword === '' || user.userID === '') {
        setShowSubmitBtn(false);
        return;
      }
    }
    // 보호자 인증 후 가입 동의 미체크시
    if (user.guardiansNm !== '' && !guardianAgree) {
      setShowSubmitBtn(false);
      return;
    }
    setShowSubmitBtn(true);
  }, [error, user.email, user.birthday, user.userID, user.password, user.confirmPassword]);

  return (
    <Container className='container' ref={wrapRef}>
      {/* <button type='button' onClick={handleCertification}>Error Test</button> */}
      <Email
        setShowSubmitBtn={setShowSubmitBtn}
      />
      <Birthday
        handleCertification={handleCertification}
        guardianAgree={guardianAgree}
        setGuardianAgree={setGuardianAgree}
        setShowSubmitBtn={setShowSubmitBtn}
      />
      <ID handleUserUsedUserIdCheck={handleUserUsedUserIdCheck} />
      {user.joinTypeCd !== '20' &&
        <Password
          setShowSubmitBtn={setShowSubmitBtn}
          scrollToBottom={scrollToBottom}
        />
      }
      <Submit
        guardianAgree={guardianAgree}
        showSubmitBtn={showSubmitBtn}
        handleRegister={handleRegister}
      />
    </Container>
  )
}

const Container = styled.div`
  padding-bottom: 10rem;
  .btn-wrap {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
`;

export default Register;