import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Controller } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import Popup from "../layout/Popup";
import Button from "../layout/Button";
import styled from "styled-components";

const Intro = () => {
  const navigate = useNavigate();
  
  return (
    <Container>
      <Popup>
        <Swiper modules={[Pagination, Controller]} pagination={{ clickable: true }}>
          <SwiperSlide className="slide-item">
            <h1>전 세계 지구시민들의<br/>공생라이프 스타일을 만나세요.</h1>
            <p>언어가 달라도 괜찮아요.<br/>사진과 영상으로 전해지는<br/>광명의 순간, 행복한 경험을 공유합니다.</p>
            <img src={require("../../../assets/images/community/intro_01.png")} alt="" />
          </SwiperSlide>
          <SwiperSlide className="slide-item">
            <h1>글쓰기가 너무 쉬워요.<br/>사진 하나·영상 하나만으로도<br/>게시 끝!</h1>
            <p>글쓰기 고민할 시간에 글로벌<br/>공생 라이프스타일을 탐색하세요!</p>
            <img src={require("../../../assets/images/community/intro_02.png")} alt="" />
          </SwiperSlide>
          <SwiperSlide className="slide-item">
            <h1>Z타운에서 활동할 나만의<br/>프로필을 완성해보세요!</h1>
            <p>내 이름이 아닌 닉네임으로<br/>활동할 수 있어요.<br/>나를 소개하는 태그와 링크도 OK</p>
            <img src={require("../../../assets/images/community/intro_03.png")} alt="" />
          </SwiperSlide>
          <SwiperSlide className="slide-item">
            <h1>나의 Z타운 활동을<br/>한 눈에 확인하세요.</h1>
            <p>나의 게시물은 모아볼 수 있고,<br/>남겨두고 싶었던 컨텐츠는<br/>여기에 보관하세요.</p>
            <img src={require("../../../assets/images/community/intro_04.png")} alt="" />
          </SwiperSlide>
        </Swiper>
        <div className='btn-wrap'>
          <Button type='button' onClick={() => navigate('/cummunity/feed')} color='mint' title='바로 시작' />
        </div>
      </Popup>
    </Container>
  )
}

const Container = styled.div`
  text-align: center;
  .slide-item {
    h1 {
      font-size: 2rem;
      font-weight: 700;
      color: #222;
    }
    p {
      margin-top: 1.2rem;
      font-size: 1.6rem;
      color: #454545;
    }
    img {
      display: block;
      margin-top: 3.3rem;
      max-width: 100%;
    }
  }
  .swiper-pagination {
    position: relative;
    margin-top: 3.2rem;
    .swiper-pagination-bullet {
      width: 0.8rem;
      height: 0.8rem;
      background-color: #d9d9d9;
      box-shadow: 0 1px 2px rgba(0,0,0,0.1);
      &.swiper-pagination-bullet-active {
        background-color: #31c5af;
      }
    }
  }
  .btn-wrap {
    margin-top: 1rem;
  }
`;

export default Intro;