import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import useStore from "../../store/signup/store";
import Loader from '../../components/common/Loader';

const OauthCallbackPage = () => {
  const navigate = useNavigate();
  const user = useStore((state) => state.user);
  const setUser = useStore((state) => state.setUser);
  const [message, setMessage] = useState('');
  const lang = new URL(encodeURI(window.location.href)).searchParams.get("langcd") ?? '';
  const nation = new URL(encodeURI(window.location.href)).searchParams.get("nationcd") ?? '';
  const snsType = new URL(encodeURI(window.location.href)).searchParams.get("snstype") ?? '';
  const id = new URL(encodeURI(window.location.href)).searchParams.get("id") ?? '';
  const email = new URL(encodeURI(window.location.href)).searchParams.get("email") ?? '';

  useEffect(() => {
    if (snsType === '') {
      setMessage('snsType이 없습니다.');
      return;
    }
    if (id === '') {
      setMessage('id가 없습니다.');
      return;
    }
    if (id.includes('ka') || id.includes('nv') || id.includes('ap') || id.includes('gg') || id.includes('fb') || id.includes('ln')) {
      setUser({ ...user, userID: id, email: email, joinTypeCd: '20', openIDTypeCd: snsType });
      navigate(`/signup/auth/sns?langcd=${lang}&nationcd=${nation}`);
    } else {
      setMessage('sns id는 최초 두글자가 snsType이어야 합니다.');
      return;
    }
  }, []);

  return (
    <>
      <Loader />
      <h1>{message}</h1>
    </>
  );
}

export default OauthCallbackPage;