import { useState, useCallback } from 'react'
import { useNavigate } from "react-router-dom";
import useCommonStore from "../../store/common/store";
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const AgreeInfo = () => {
  const navigate = useNavigate();                               // 네비게이트
  const { t } = useTranslation();                               // 다국어
  const langCd = useCommonStore((state) => state.langCd);
  const nationCd = useCommonStore((state) => state.nationCd);
  const [showAgreeBtn, setShowAgreeBtn] = useState(false);      // 동의하기 버튼

  // 전체 체크 클릭
  const onCheckedAll = useCallback((checked) => {
      if (checked) {
        setShowAgreeBtn(true);
      } else {
        setShowAgreeBtn(false);
      }
    },
  );

  // 동의하기
  const onAgree = () => {
    navigate(`/signup/register?langcd=${langCd}&nationcd=${nationCd}`);
  }

  return (
    <Container className='container'>
      <div className="contents-wrap">
        <div className="all-check">
          <input
            type="checkbox"
            id='checkAll'
            onChange={(e) => onCheckedAll(e.target.checked)}
          />
          <label htmlFor="checkAll">{t("정보제공 동의")}</label>
        </div>
        <div className="info">
          <p>{t("아래의 지구시민톡 회원 정보를 얼스톡에 제공하는데 동의하면 체크해 주세요. 본 제공 동의를 거부하면 새로 가입하셔야 해요.")}</p>
          <hr />
          <h3>{t("제공 정보")}</h3>
          <p>{t('이메일/생년월일/아이디')}</p>
        </div>
      </div>
      <div className="btn-wrap fixed">
        {showAgreeBtn
          ? <button type='button' className='btn lg green' onClick={onAgree}>{t("동의하기")}</button>
          : <button type='button' className='btn lg gray'>{t("동의하기")}</button>
        }
      </div>
    </Container>
  )
}

const Container = styled.div`
  .contents-wrap {
    padding: 0 2.7rem;
    .all-check {
      label {
        font-weight: 500;
      }
    }
    .info {
      padding-left: 3rem;
      p {
        color: #333;
      }
      hr {
        margin-top: 2.5rem;
        margin-bottom: 1.9rem;
        border-top: 1px solid #ededed;
      }
      h3 {
        margin-bottom: 0.5rem;
        font-weight: 500;
      }
      .check-wrap {
        display: flex;
        .list {
          margin-right: 1rem;
          label {
            font-size: 1.35rem;
          }
        }
      }
    }
  }
`;

export default AgreeInfo