import create from "zustand";
import { devtools, persist } from "zustand/middleware";

let state = (set) => ({
  /** 로그인 유저 */
  user: {
    userId: '',
  },
  setUser: (data) => set(() => ({ user: data })),

  /** 공지사항 */
  notice: [],
  setNotice: (data) => set(() => ({ notice: data })),
  lastNoticeIdx: 0,
  setLastNoticeIdx: (data) => set(() => ({ lastNoticeIdx: data })),
  
  /** 도움말 */
  help: [],
  setHelp: (data) => set(() => ({ help: data })),
  lastHelpIdx: 0,
  setLastHelpIdx: (data) => set(() => ({ lastHelpIdx: data })),

  /** 문의 카테고리 */
  contact: [],
  setContact: (data) => set(() => ({ contact: data })),
  firstContact: [],
  setFirstContact: (data) => set(() => ({ firstContact: data })),

  /** 먼저 체크 리스트 카테고리명 */
  categoryName: '',
  setCategoryName: (data) => set(() => ({ categoryName: data })),

  /** Alert */
  alert: {
    type: '',
    message: '',
    btn: '',
    isOpen: false,
  },
  setAlert: (data) => set(() => ({
    alert: data
  })),
  clearAlert: () => set(() => ({
    alert: {
      type: '',
      message: '',
      btn: '',
      isOpen: false,
    }
  })),
});

const useStore = create(devtools(state));

export default useStore;