import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useCommonStore from '../../../store/site/common';
import styled from 'styled-components';

const Tab = ({ category, getList }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const osTypeCd = useCommonStore((state) => state.osTypeCd);
  const [activeCategory, setActiveCategory] = useState('');

  const onChangeCategory = (cd) => {
    const initLastIdx = true;
    setActiveCategory(cd);
    if (cd === "10") {
      navigate('/policy/service')
    } else if (cd === "20") {
      navigate('/policy/privacy')
    } else {
      getList(cd, initLastIdx);
    }
  }

  useEffect(() => {
    if (id === "service") {
      setActiveCategory("10");
    } else if (id === "privacy") {
      setActiveCategory("20");
    } else {
      setActiveCategory(osTypeCd);
    }
  }, [id]);

  return (
    <Container>
      <ul>
        {category.map((i) => (
          <li key={i.id} className={i.cd === activeCategory ? 'active' : ''}>
            <button onClick={() => onChangeCategory(i.cd)}>{i.name}</button>
          </li>
        ))}
      </ul>
    </Container>
  )
}

const Container = styled.div`
  display: none;
  @media screen and (min-width: 800px) {
    display: block;
    margin-top: 2.5rem;
    margin-bottom: 4rem;
    padding: 0 1px;
    overflow: hidden;
    ul {
      display: flex;
      li {
        flex-grow: 1;
        margin-left: -1px;
        margin-right: -1px;
        border: 1px solid #E7E7E7;
        background-color: #fff;
        &.active {
          border: 1px solid #31C5AF;
          z-index: 2;
          button {
            color: #31C5AF;
          }
        }
        button {
          width: 100%;
          height: 5.6rem;
          font-size: 1.8rem;
          font-weight: 500;
          color: #484848;
        }
      }
    }
  }
`;

export default Tab