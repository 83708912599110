import { Link } from "react-router-dom";
import useStore from "../../store/signup/store";
import { useTranslation } from 'react-i18next';
import { compareAsc, format } from 'date-fns'
import Alert from '../../components/signup/Alert';
import styled from 'styled-components';

const FindResult = () => {
  const { t } = useTranslation();                       // 다국어
  const user = useStore((state) => state.user);         // Get 유저
  const popup = useStore((state) => state.popup);                          // Get Popup
  const setPopup = useStore((state) => state.setPopup);                    // Set Popup

  // 로그인 페이지로 이동
  const goLogin = () => {
    const agent = navigator.userAgent.toLowerCase();
    if (agent.indexOf('android') > -1) {
      window.Android.viewCloseGoLogin();
    } else if (agent.indexOf("iphone") > -1 || agent.indexOf("ipad") > -1 || agent.indexOf("ipod") > -1 || agent.indexOf("apple") > -1) {
      window.webkit.messageHandlers.viewCloseGoLogin.postMessage("1");
    } else {
      setPopup({ type: 'alert', message: 'No native APIs found.', isOpen: true });
    }
  }

  // 고객센터 띄우기
  const goCustomer = () => {
    window.open('/customer/contactus');
  }
  
  return (
    <Container className='container'>
      {user.joinTypeCd ==='10' &&
        <div className="content-wrap">
          <h2>{t('회원님의 아이디는 아래와 같아요.')}</h2>
          <div className="result">
            <div className="id">{t('아이디')} : <span>{user.userID}</span></div>
            <div className="date">({t('가입')} : {format(new Date(user.joinDtm), 'yyyy. MM. dd')})</div>
          </div>
          <div className="password">
            <span>{t('비밀번호를 모르시나요?')}</span>
            <Link to='/signup/newpassword' className='a-link'>{t('비밀번호 찾기/재설정')}</Link>
          </div>
        </div>
      }
      {user.joinTypeCd ==='20' && user.openIDTypeCd === 'ka' &&
        <div className="content-wrap">
          <h2>{t('카카오 계정으로 가입된 회원이에요.')}<br />{t('카카오로 로그인해 주세요.')}</h2>
          <div className="result">
            <div className="date">({t('가입')} : {format(new Date(user.joinDtm), 'yyyy. MM. dd')})</div>
          </div>
        </div>
      }
      {user.joinTypeCd ==='20' && user.openIDTypeCd === 'nv' &&
        <div className="content-wrap">
          <h2>{t('네이버 계정으로 가입된 회원이에요.')}<br />{t('네이버로 로그인해 주세요.')}</h2>
          <div className="result">
            <div className="date">({t('가입')} : {format(new Date(user.joinDtm), 'yyyy. MM. dd')})</div>
          </div>
        </div>
      }
      {user.joinTypeCd ==='20' && user.openIDTypeCd === 'ap' &&
        <div className="content-wrap">
          <h2>{t('애플 계정으로 가입된 회원이에요.')}<br />{t('애플로 로그인해 주세요.')}</h2>
          <div className="result">
            <div className="date">({t('가입')} : {format(new Date(user.joinDtm), 'yyyy. MM. dd')})</div>
          </div>
        </div>
      }
      {user.joinTypeCd ==='20' && user.openIDTypeCd === 'gg' &&
        <div className="content-wrap">
          <h2>{t('구글 계정으로 가입된 회원이에요.')}<br />{t('구글로 로그인해 주세요.')}</h2>
          <div className="result">
            <div className="date">({t('가입')} : {format(new Date(user.joinDtm), 'yyyy. MM. dd')})</div>
          </div>
        </div>
      }
      {user.joinTypeCd ==='20' && user.openIDTypeCd === 'fb' &&
        <div className="content-wrap">
          <h2>{t('페이스북 계정으로 가입된 회원이에요.')}<br />{t('페이스북으로 로그인해 주세요.')}</h2>
          <div className="result">
            <div className="date">({t('가입')} : {format(new Date(user.joinDtm), 'yyyy. MM. dd')})</div>
          </div>
        </div>
      }
      {user.joinTypeCd ==='20' && user.openIDTypeCd === 'ln' &&
        <div className="content-wrap">
          <h2>{t('라인 계정으로 가입된 회원이에요.')}<br />{t('라인으로 로그인해 주세요.')}</h2>
          <div className="result">
            <div className="date">({t('가입')} : {format(new Date(user.joinDtm), 'yyyy. MM. dd')})</div>
          </div>
        </div>
      }
      <div className="btn-wrap fixed">
        <div className="customer">
          <span>{t('본인이 가입한 아이디가 아닌가요?')}</span>
          <button type='button' className='a-link' onClick={goCustomer}>{t('고객문의')}</button>
        </div>
        <button type='button' className='btn lg green' onClick={goLogin}>{t("로그인 페이지")}</button>
      </div>
      {popup.isOpen && <Alert />}
    </Container>
  )
}

const Container = styled.div`
  .content-wrap {
    padding: 0 2.5rem;
    h2 {
      font-size: 1.9rem;
    }
    .result {
      margin-top: 1.1rem;
      .id {
        margin-bottom: 0.8rem;
        font-size: 1.9rem;
        font-weight: 700;
        color: #00d2b4;
      }
      .date {
        font-size: 1.3rem;
        color: #aaa;
      }
    }
    .password {
      margin-top: 3.5rem;
      color: #454545;
      a {
        margin-left: 0.7rem;
        color: #00d2b4;
        text-decoration: underline;
      }
    }
  }
  .customer {
    margin-bottom: 2.45rem;
    text-align: center;
    color: #454545;
    a {
      margin-left: 0.7rem;
      font-weight: 500;
      text-decoration: underline;
    }
  }
`;

export default FindResult