import { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import useCommonStore from '../../../store/site/common';
import useStore from '../../../store/site/store';
import styled from 'styled-components';

const Header = ({ prev, page, title }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const langCd = useCommonStore((state) => state.langCd);
  const isWeb = useCommonStore((state) => state.isWeb);
  const setHelp = useStore((state) => state.setHelp);
  const setLastHelpIdx = useStore((state) => state.setLastHelpIdx);
  const [toggleGnb, setToggleGnb] = useState(false);
  const [togglePolicy, setTogglePolicy] = useState(false);

  const onPrev = () => {
    if (prev === 'helplist') {
      setHelp([]);
      setLastHelpIdx("0");
      navigate('/customer/help');
    }
    if (prev === 'back') {
      navigate(-1);
    } else if (prev === 'close') {
      alert('웹뷰 닫기 호출합니다.');
      alert('새로고침 해주세요.');
      window.close();
    }
  }

  const onTogglePolicy = () => {
    setTogglePolicy(!togglePolicy);
  }

  const onToggle = () => {
    setTogglePolicy(!togglePolicy);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return (
    <>
    {isWeb
      ?
        <WebHeader>
          <div className="header-wrap">
            {page === "detail"
              ?
                <button type='button' className='prev' onClick={onPrev}><span className="blind">웹뷰 닫기</span></button>
              :
                <div className="gnb-mobile-open">
                  <button type="button" onClick={() => setToggleGnb(true)}>
                    <span className="blind">모바일 메뉴 열기</span>
                  </button>
                </div>
            }
            <nav className={toggleGnb ? "gnb-mobile active" : "gnb-mobile"}>
              <button type="button" className="close" onClick={() => setToggleGnb(false)}>
                <span className="blind">닫기</span>
              </button>
              <ul className="depth-0">
                <li>
                  <Link to='/'>소개</Link>
                </li>
                <li>
                  <Link to='/customer/notice'>공지사항</Link>
                </li>
                <li>
                  <Link to='/customer/help'>도움말</Link>
                </li>
                <li>
                  <Link to='/customer/contact'>문의하기</Link>
                </li>
                <li>
                  <button type="button" onClick={onTogglePolicy} className={togglePolicy ? 'active' : ''}>약관 및 정책</button>
                  {togglePolicy &&
                    <ul className="depth-1">
                      <li>
                        <Link to='/policy/service'>이용약관</Link>
                      </li>
                      <li>
                        <Link to='/policy/privacy'>개인정보 처리방침</Link>
                      </li>
                    </ul>
                  }
                </li>
              </ul>
            </nav>
            <div className={title === "home" ? 'logo' : 'logo if-m-hide'}>
              <a href="/">
                <img src={require("../../../assets/images/site/logo.png")} alt="" />
              </a>
            </div>
            {title !== "home" &&
              <div className="title">{title}</div>
            }
            <nav className="gnb">
              <ul>
                <li>
                  <Link to='/'>소개</Link>
                </li>
                <li>
                  <Link to='/customer/notice'>공지사항</Link>
                </li>
                <li>
                  <Link to='/customer/help'>도움말</Link>
                </li>
                <li>
                  <Link to='/customer/contact'>문의하기</Link>
                </li>
              </ul>
            </nav>
            <div className={title === "home" ? "lang" : "lang if-m-hide"}>
              <button type="button" className={langCd}>한국어</button>
            </div>
          </div>
        </WebHeader>
      :
        <AppHeader>
          <button type='button' className='prev' onClick={onPrev}><span className="blind">웹뷰 닫기</span></button>
          <h1 className='title'>{title}</h1>
        </AppHeader>
      }
    </>
  )
}

const WebHeader = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background-color: white;
  z-index: 10;
  .header-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 2rem;
    max-width: 128rem;
    .gnb-mobile-open {
      margin-right: 0.6rem;
      button {
        width: 2.9rem;
        height: 2.9rem;
        background-image: url("${require('../../../assets/images/site/icon_menu.png')}");
        background-size: 100%;
      }
    }
    .gnb-mobile {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 4.4rem 2.5rem;
      background-color: #fff;
      visibility: hidden;
      opacity: 0;
      z-index: 21;
      &.active {
        visibility: visible;
        opacity: 1;
      }
      .close {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        width: 2.9rem;
        height: 2.9rem;
        background-image: url("${require('../../../assets/images/site/icon_close.png')}");
        background-size: 100%;
      }
      .depth-0 {
        > li {
          > a,button {
            display: block;
            width: 100%;
            padding: 2rem 0;
            font-size: 1.7rem;
            font-weight: 500;
            color: #454545;
            text-align: left;
            border-bottom: 1px solid #d9d9d9;
          }
          > button {
            background-image: url("${require('../../../assets/images/site/arrow_gnb_mobile_down.png')}");
            background-repeat: no-repeat;
            background-position: right 1.15rem center;
            background-size: 1.5rem;
            &.active {
              background-image: url("${require('../../../assets/images/site/arrow_gnb_mobile_up.png')}");
            }
          }
          .depth-1 {
            margin-top: -1px;
            padding: 1rem 0;
            background-color: #f8f8f8;
            border-bottom: 1px solid #d9d9d9;
            > li {
              > a {
                display: block;
                padding: 1rem 2rem;
                font-size: 1.6rem;
              }
            }
          }
        }
      }
    }
    .prev {
      width: 2.9rem;
      height: 2.9rem;
      background-image: url("${require('../../../assets/images/customer/arrow_prev.png')}");
      background-size: 100%;
    }
    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.7rem;
      font-weight: 500;
    }
    .logo {
      margin-right: auto;
      a {
        img {
          display: block;
          width: 7.75rem;
        }
      }
    }
    .gnb {
      display: none;
    }
    .lang {
      
    }
  }
  .if-m-hide {
    display: none;
  }
  @media screen and (min-width: 800px) {
    height: 10rem;
    border-bottom: 1px solid #dedede;
    .if-m-hide {
      display: block;
    }
    .header-wrap {
      .prev {
        display: none;
      }
      .gnb-mobile-open {
        display: none;
      }
      .gnb-mobile {
        display: none;
      }
      .logo {
        a {
          img {
            width: auto;
          }
        }
      }
      .title {
        display: none;
      }
      .gnb {
        display: block;
        margin-right: auto;
        ul {
          display: flex;
          li {
            padding: 0 3.5rem;
            a {
              font-size: 1.7rem;
              font-weight: 600;
            }
          }
        }
      }
      .lang {
        button {
          height: 2.6rem;
          padding-left: 2.5rem;
          font-size: 1.6rem;
          font-weight: 600;
          text-decoration: underline;
          background-image: url("${require('../../../assets/images/site/icon_lang.png')}");
          background-repeat: no-repeat;
          background-position: 0 center;
        }
      }
    }
  }
  @media screen and (min-width: 1280px) {
    .header-wrap {
      padding: 0;
    }
  }
`;

const AppHeader = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5.2rem;
  padding: 0 1.5rem;
  background-color: #fff;
  border-bottom: 1px solid #EDEDED;
  z-index: 9;
  .prev {
    width: 2.9rem;
    height: 2.9rem;
    background-image: url("${require('../../../assets/images/customer/arrow_prev.png')}");
    background-size: 100%;
  }
  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.7rem;
    font-weight: 500;
  }
  @media screen and (min-width: 800px) {

  }
`;

export default Header