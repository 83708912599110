import { useEffect, useState, useRef } from 'react';
import client from '../../lib/api/hwadap';
import { useTranslation } from 'react-i18next';
import useCommonStore from '../../store/common/store';
import useStore from '../../store/hwadap/store';
import Room from '../../components/hwadap/room/Room';
import Toast from '../../components/hwadap/layout/Toast';
import Popup from '../../components/hwadap/layout/Popup';
import Loader from '../../components/common/Loader';

const RoomPage = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);                    // 로딩
  const [isFileLoading, setIsFileLoading] = useState(false);            // 파일 다운로드 로딩
  const langCd = useCommonStore((state) => state.langCd);               // Get Lang
  const user = useStore((state) => state.user);                         // Get User
  const hwadap = useStore((state) => state.hwadap);                     // Get 화답 리스트
  const setHwadap = useStore((state) => state.setHwadap);               // Set 화답 리스트
  const lastSendIdx = useStore((state) => state.lastSendIdx);           // Get lastSendIdx
  const setLastSendIdx = useStore((state) => state.setLastSendIdx);     // Set lastSendIdx
  const popup = useStore((state) => state.popup);                       // Get Popup
  const setPopup = useStore((state) => state.setPopup);                 // Set Popup
  const setClearPopup = useStore((state) => state.setClearPopup);       // Clear Popup
  const isScrolled = useStore((state) => state.isScrolled);             // Get 처음 스크롤 이동
  const setShowMoreBtn = useStore((state) => state.setShowMoreBtn);     // Set 10건 더보기
  const refreshTimerRef = useRef(null);                                 // 화답 가져오기 리프레시 타이머
  const [showToast, setShowToast] = useState(false);                    // 토스트 토글
  const [toastMessage, setToastMessage] = useState('');                 // 토스트 메세지
  const toastTimerRef = useRef(null);                                   // 토스트 메세지 타이머
  const [showWriteInput, setShowWriteInput] = useState(false);          // 화답 작성하기 Input 토글
  const [deleteHwadap, setDeleteHwadap] = useState({                    // 화답 삭제용
    sendIdx: '',
    idx: ''
  });

  /** 화답 작성하기 */
  const handleWrite = async (channelUrl, sendIdx, text) => {
    try {
      const { data } = await client.post('/hwadap/hdapInsert', {
        channelUrl,
        sendIdx,
        userId: user.userId,
        langCd,
        msgContent: text
      });
      if (data.content.status === "Success") {
        const filterHwadap = hwadap.map((i) => {
          if (i.sendIdx === parseInt(sendIdx)) {
            const length = i?.myHdap?.length ?? 0;
            if (length < 1) {
              return {
                ...i,
                cntAnsUser: i.cntAnsUser + 1,
                myHdap: [{ idx: data.content.data, regDtm: new Date(), msgContent: text }]
              };
            } else {
              return {
                ...i,
                myHdap: [...i.myHdap, { idx: data.content.data, regDtm: new Date(), msgContent: text }]
              }
            }
          }
          return i;
        });
        setShowWriteInput(false);
        setToastMessage(t('화답 작성을 완료했어요.'));
        setShowToast(true);
        setHwadap(filterHwadap);
        toastTimerRef.current = setTimeout(() => {
          setShowToast(false);
        }, 2000);
      }
    } catch (error) {
      alert(error);
    }
  }

  /** 화답 복사하기 */
  const handleCopyHwadap = (id) => {
    const el = document.getElementById(`message-${id}`);
    try {
      navigator.clipboard.writeText(el.innerText);
      setToastMessage(t('내 화답이 복사되었어요.'));
      setShowToast(true);
      toastTimerRef.current = setTimeout(() => {
        setShowToast(false);
      }, 2000);
    } catch (error) {
      alert(error);
    }
  }

  /** 화답 삭제하기 Alert */
  const onDeleteHwadap = (sendIdx, idx) => {
    setDeleteHwadap({ sendIdx, idx });
    setPopup({ type: 'deleteHwadap', isOpen: true });
  }

  /** 화답 삭제하기 */
  const handleDeleteHwadap = async () => {
    try {
      const { data } = await client.post('/hwadap/hdapDelete', {
        idx: deleteHwadap.idx,
      });
      if (data.content.status === "Success") {
        const filterHwadap = hwadap.map((i) => {
          if (i.sendIdx === deleteHwadap.sendIdx) {
            if (i.myHdap.length === 1) {
              return {
                ...i,
                cntAnsUser: i.cntAnsUser - 1,
                myHdap: i.myHdap.filter((j) => j.idx !== deleteHwadap.idx)
              };
            } else {
              return {
                ...i,
              myHdap: i.myHdap.filter((j) => j.idx !== deleteHwadap.idx)
              }
            }
          }
          return i;
        });
        setHwadap(filterHwadap);
        setClearPopup();
        setToastMessage(t('삭제되었어요.'));
        setShowToast(true);
        toastTimerRef.current = setTimeout(() => {
          setShowToast(false);
        }, 2000);
      }
    } catch (error) {
      alert(error);
    }
  }

  /** 화답 리스트 가져오기 */
  const handleGetHwadapList = async () => {
    if (!isScrolled) {
      setIsLoading(true);
    }
    try {
      const { data } = await client.post('/hwadap/hdMsgArea', {
        userid: user.userId,
        lastSendIdx: lastSendIdx
      });
      let currentDate = '';
      const filterData = data.content.map((item) => {
        let newItem = item.jsonString;
        newItem.messageLang = 'ko';
        newItem.Hwadoo.msgContent.msgContent_ko = '<h1>' + newItem.Hwadoo.title.title_ko + '</h1>' + newItem.Hwadoo.msgContent.msgContent_ko.replaceAll('contenteditable="false"', '');
        newItem.Hwadoo.msgContent.msgContent_en = '<h1>' + newItem.Hwadoo.title.title_en + '</h1>' + newItem.Hwadoo.msgContent.msgContent_en.replaceAll('contenteditable="false"', '');
        newItem.Hwadoo.msgContent.msgContent_ja = '<h1>' + newItem.Hwadoo.title.title_ja + '</h1>' + newItem.Hwadoo.msgContent.msgContent_ja.replaceAll('contenteditable="false"', '');

        const date = new Intl.DateTimeFormat("ko-KR", {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }).format(new Date(newItem.sendTimeZoneDtm));
        
        if (currentDate === date) {
          newItem.created = '';
        } else {
          currentDate = date;
          newItem.created = newItem.sendTimeZoneDtm;
        }
        return newItem;
      });
      if (data.content.length > 0) {
        setLastSendIdx(filterData[0].sendIdx);
      }
      if (data.content.length < 10) {
        setShowMoreBtn(false);
      }
      if (hwadap.length > 0) {
        setHwadap([...filterData, ...hwadap]);
      } else {
        setHwadap(filterData);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  }

  /** 파일 다운로드 */
  const handleFileDownload = async (fileUrl, fileName) => {
    setIsFileLoading(true);
    try {
      const { data } = await client.post('/file/S3/download',
        {
          filename: fileUrl
        },
        {
          responseType: 'blob',
        }
      );
      const href = URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(href);
      setIsFileLoading(false);
      setToastMessage(t('다운로드를 완료했어요.'));
      setShowToast(true);
      toastTimerRef.current = setTimeout(() => {
        setShowToast(false);
      }, 2000);
    } catch (e) {
      console.log(e);
      setIsFileLoading(false);
    }
  }
  
  /** Init + Clear */
  useEffect(() => {
    if (!isScrolled) {
      handleGetHwadapList();
    }
    return () => {
      clearTimeout(toastTimerRef.current);
      clearTimeout(refreshTimerRef.current);
    }
  }, []);

  return (
    <div className='hwadap'>
      {(isLoading || isFileLoading) && <Loader />}
      <Room
        isLoading={isLoading}
        toastTimerRef={toastTimerRef}
        setShowToast={setShowToast}
        setToastMessage={setToastMessage}
        showWriteInput={showWriteInput}
        setShowWriteInput={setShowWriteInput}
        handleWrite={handleWrite}
        handleGetHwadapList={handleGetHwadapList}
        handleCopyHwadap={handleCopyHwadap}
        onDeleteHwadap={onDeleteHwadap}
        handleFileDownload={handleFileDownload}
      />
      <div className={showToast ? 'toast active' : 'toast'}>
        <Toast page='myroom' message={toastMessage} />
      </div>
      {popup.isOpen && <Popup handleDeleteHwadap={handleDeleteHwadap} />}
    </div>
  )
}

export default RoomPage