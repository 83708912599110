import create from "zustand";
import { devtools, persist } from "zustand/middleware";

let state = (set) => ({
  user: {
    userID: '',
    email: '',
    nmFamily: '',
    nmFirst: '',
    phoneNationNum: '',
    nationCd: '',
    mPhoneNum: '',
    birthDt: '',
    birthday: {
      year: '',
      month: '',
      day: '',
    },
    joinTypeCd: '10',
    openIDTypeCd: '',
    joinDtm: '',
    authCode: '',
    isAuth: false,
    authReqNumber: '',
    guardiansNm: '',
    guardiansMphoneNum: '',
    isAgreeMarketingSmsMsg: false,
    isAgreeMarketingEmail: false,
    jitalkID: '',
    isJitalkID: false,
    password: '',
    confirmPassword: '',
    showAuth: false,
  },
  policy: [],
  nation: [],
  descript: {
    nmFamily: '',
    nmFirst: '',
    email: '',
    mPhoneNum: '',
    birthday: '',
    id: '',
    password: '',
  },
  error: {
    nmFamily: '',
    nmFirst: '',
    mPhoneNum: '',
    email: '',
    birthday: '',
    id: '',
    password: '',
    confirmPassword: ''
  },
  popup: {
    type: '',
    message: '',
    isOpen: false
  },
  setUser: (data) => set(() => ({ user: data })),
  setPolicy: (data) => set(() => ({ policy: data })),
  setNation: (data) => set(() => ({ nation: data })),
  setDescript: (data) => set(() => ({ descript: data })),
  setError: (data) => set(() => ({ error: data })),
  setPopup: (data) => set(() => ({ popup: data })),
  clearPopup: () => set(() => ({ popup: { type: '', message: '', isOpen: false } })),
});

const useStore = create(devtools(state));

export default useStore;