import { useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { client, setCSRFToken } from '../../lib/api/customer';
import useStore from '../../store/site/store';
import useCommonStore from '../../store/site/common';
import Loader from '../../components/common/Loader';

const LoginPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const langCd = searchParams.get('langcd') ?? '';
  const nationCd = searchParams.get('nationcd') ?? '';
  const route = searchParams.get('route') ?? '';
  const token = searchParams.get('token') ?? 'test';
  const user = useStore((state) => state.user);
  const setUser = useStore((state) => state.setUser);
  const setLangCd = useCommonStore((state) => state.setLangCd);
  const setNationCd = useCommonStore((state) => state.setNationCd);

  // 웹뷰 닫기
  const closeWebView = () => {
    try {
      const agent = navigator.userAgent.toLowerCase();
      if (agent.indexOf('android') > -1) {
        window.Android.viewClose();
      } else if (agent.indexOf("iphone") > -1 || agent.indexOf("ipad") > -1 || agent.indexOf("ipod") > -1 || agent.indexOf("apple") > -1) {
        window.webkit.messageHandlers.viewClose.postMessage("1");
      } else {
        // setPopup({ type: 'alert', message: 'No native APIs found.', isOpen: true });
      }
    } catch (e) {
      alert('네이티브 웹뷰 닫기 호출');
      navigate('/');
    }
  }

  /** CSRF + Access 토큰 받기 */
  const getToken = () => {
    try {
      // 임시
      if (user.userId.length > 0) {
        navigate(`/customer/${route}`);
      }
      // 임시
      
      // const { data } = await client.get('', token);
      // if ('failed') {
      //   return;
      // }
      // if ('Success') {
      //   setCSRFToken('123');
      //   navigate(`/customer/${id}?langcd=${langCd}`);
      // }      
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    // if (user.userId.length > 0) {
    //   closeWebView();
    // } else {
    //   getToken(token);
    // }
    setLangCd(langCd);
    setNationCd(nationCd);
  }, []);

  return (
    // <Loader />
    <div style={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center', padding: '3rem' }}>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <input type="text" value={user.userId} onChange={(e) => setUser({ userId: e.target.value })} placeholder="아이디" />
        <br/>
        <br/>
        <button type='button' onClick={getToken} style={{ fontSize: '2rem' }}>로그인</button>
      </div>
    </div>
  )
}

export default LoginPage;