import { useNavigate } from "react-router-dom";
import useCommonStore from "../../store/common/store";
import useStore from "../../store/signup/store";
import { useTranslation } from 'react-i18next';
import Parser from "html-react-parser";
import styled from 'styled-components';

const Alert = ({
    handleUserIdSearchByNmMPhone,
    handleLogin,
    handleUserRestore,
    handleFindAfterReset,
  }) => {
  const { t } = useTranslation();                                                                 // 다국어
  const navigate = useNavigate();                                                                 // 네비게이트
  const langCd = useCommonStore((state) => state.langCd);                                         // Get 언어
  const nationCd = useCommonStore((state) => state.nationCd);                                         // Get 언어
  const user = useStore((state) => state.user);                                                   // Get 유저
  const setUser = useStore((state) => state.setUser);                                             // Set 유저
  const descript = useStore((state) => state.descript);                                           // Get 안내문구
  const setDescript = useStore((state) => state.setDescript);                                     // Set 안내문구
  const popup = useStore((state) => state.popup);                                                 // Get Popup
  const setPopup = useStore((state) => state.setPopup);                                           // Set Popup
  const clearPopup = useStore((state) => state.clearPopup);                                       // Clear Alert
  const isAsia = (langCd === 'ko' || langCd === 'ja') ? true : false;                             // 한국어, 일본어 인지?

  // 휴면계정 복구
  const onUserRestore = () => {
    clearPopup();
    handleUserRestore();
  }

  // 시작하기
  const onLogin = () => {
    clearPopup();
    handleLogin();
  }

  // 아이디 찾기
  const onUserIdSearchByNmMPhone = () => {
    clearPopup();
    handleUserIdSearchByNmMPhone();
  }
  
  // 로그인 페이지로 이동
  const goLogin = () => {
    clearPopup();
    const agent = navigator.userAgent.toLowerCase();
    if (agent.indexOf('android') > -1) {
      window.Android.viewCloseGoLogin();
    } else if (agent.indexOf("iphone") > -1 || agent.indexOf("ipad") > -1 || agent.indexOf("ipod") > -1 || agent.indexOf("apple") > -1) {
      window.webkit.messageHandlers.viewCloseGoLogin.postMessage("1");
    } else {
      setPopup({ type: 'alert', message: 'No native APIs found.', isOpen: true });
    }
  }

  // 아이디 찾기
  const onFindID = () => {
    clearPopup();
    setUser({ ...user, nmFamily: '', nmFirst: '' });
    if (langCd === 'ko' || langCd === 'ja') {
      setDescript({ ...descript, nmFirst: t('휴대폰 번호에 맞는 회원 이름으로 아이디를 찾으세요.') });
    } else {
      setDescript({ ...descript, nmFamily: t('휴대폰 번호에 맞는 회원 이름으로 아이디를 찾으세요.') });
    }
    navigate(`/signup/auth/findid?langcd=${langCd}&nationcd=${nationCd}`);
  }
  
  // 지톡 회원 > 신규 일반/SNS 회원가입
  const onRegister = () => {
    clearPopup();
    setUser({
      ...user,
      email: '',
      birthday: {
        year: '',
        month: '',
        day: '',
      },
      jitalkID: '',
      isJitalkID: false,
      isAuth: true
    });
    navigate(`/signup/register?langcd=${langCd}&nationcd=${nationCd}`);
  }

  // 아이디 찾기 > 신규 회원가입
  const onAuthHome = () => {
    clearPopup();
    handleFindAfterReset();
    navigate(`/signup/auth/normal?langcd=${langCd}&nationcd=${nationCd}`);
  }

  // 지톡 아이디로 회원가입
  const onRegisterJitalkID = () => {
    clearPopup();
    setUser({ ...user, userID: user.jitalkID, joinTypeCd: "10" });
    navigate(`/signup/agreeinfo?langcd=${langCd}&nationcd=${nationCd}`);
  }

  // 가입폼으로 이동
  const goRegister = () => {
    clearPopup();
    if (user.jitalkID !== '') {
      setUser({ ...user, userID: user.jitalkID });
      navigate(`/signup/agreeinfo?langcd=${langCd}&nationcd=${nationCd}`);
    } else {
      navigate(`/signup/register?langcd=${langCd}&nationcd=${nationCd}`);
    }
  }

  return (
    <Container>
      {/* 메세지 */}
      {popup.type === 'alert' && 
        <div className="contents">
          <button type='button' className='close' onClick={() => clearPopup()}>닫기</button>
          <p>{popup.message}</p>
          <div className="btns">
            <button type='button' className='btn lg round gray' onClick={() => clearPopup()}>{t("확인")}</button>
          </div>
        </div>
      }
      {/* 인증완료 */}
      {popup.type === 'complete' && 
        <div className="contents">
          <p>{popup.message}</p>
          <div className="btns">
            <button type='button' className='btn lg round gray' onClick={goRegister}>{t("확인")}</button>
          </div>
        </div>
      }
      {/* 휴대폰 일치 */}
      {popup.type === 'alreadyPhoneNumber' && 
        <div className="contents">
          <button type='button' className='close' onClick={() => clearPopup()}>닫기</button>
          <p>{t("입력하신")} {user.mPhoneNum.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)}{Parser(t("로 이미 회원가입이 되어있어요. 다시 입력 하시거나, 아이디 찾기를 해주세요."))}</p>
          <div className="btns">
            <button type='button' className='btn lg round green' onClick={onFindID}>{t("아이디 찾기")}</button>
            <button type='button' className='btn lg round gray' onClick={() => clearPopup()}>{t("닫기")}</button>
          </div>
        </div>
      }
      {/* 이름 + 휴대폰 일치 */}
      {popup.type === 'alreadyPersonalInformation' && 
        <div className="contents">
          <button type='button' className='close' onClick={() => clearPopup()}>닫기</button>
          <p>{t("이미 회원가입 되어있는 개인정보에요. 아이디 찾기 메뉴에서 가입된 정보를 확인해 주세요.")}</p>
          <div className="btns">
            <button type='button' className='btn lg round gray' onClick={goLogin}>{t("로그인 페이지")}</button>
            <button type='button' className='btn lg round green' onClick={onUserIdSearchByNmMPhone}>{t("아이디 찾기")}</button>
          </div>
        </div>
      }
      {/* 지톡 회원 일치 */}
      {popup.type === 'alreadyJitalkID' && 
        <div className="contents">
          <button type='button' className='close' onClick={() => clearPopup()}>닫기</button>
          <p>{Parser(t("지톡에 회원가입된 회원정보에요. 동일 아이디로 가입하시겠어요?"))}</p>
          <div className="btns">
            <button type='button' className='btn lg round green' onClick={onRegisterJitalkID}>지톡아이디로 가입</button>
            <button type='button' className='btn lg round gray' onClick={onRegister}>신규 회원가입</button>
          </div>
        </div>
      }
      {/* 지톡 회원 아님 */}
      {popup.type === 'notJitalkMember' && 
        <div className="contents">
          <button type='button' className='close' onClick={() => clearPopup()}>닫기</button>
          <p>{Parser(t("지톡에 가입된 회원정보가 아니에요. 회원 정보를 다시 확인하시거나, 일반 회원으로 가입해 주세요."))}</p>
          <div className="btns">
            <button type='button' className='btn lg round green' onClick={() => clearPopup()}>회원정보 다시 확인</button>
            <button type='button' className='btn lg round gray' onClick={onRegister}>일반 회원으로 가입</button>
          </div>
        </div>
      }
      {/* 가입 완료 */}
      {popup.type === 'completeRegister' &&
        <div className="contents">
          <div className='welcome'>
            <img src={require("../../assets/images/signup/icon_welcome.gif")} alt="welcome" />
          </div>
          <h1>{!isAsia && <span>Welcome,</span>} {isAsia ? user.nmFamily + user.nmFirst : user.nmFirst + ' ' + user.nmFamily}{t("님, 환영해요!")}</h1>
          <p>{Parser(t("회원가입이 완료되었어요. 이제 얼스톡에서 지구시민들의 따뜻한 소식을 만나보세요"))}</p>
          {user.jitalkID !== '' &&
            <div className='if-jitalk'>
              <h2>아래의 지톡 이용 내역이<br />얼스톡으로 이전됩니다.</h2>
              <p>추천 코드, 선물가게 결제 및 선물 내역, 랜선명상여행 이용 내역, 유튜브 스타, 생명전자친구</p>
            </div>
          }
          <div className="btns">
            <button type='button' className='btn lg round green' onClick={onLogin}>{t("시작하기")}</button>
          </div>
        </div>
      }
      {/* 가입된 번호 아님 */}
      {popup.type === 'notMember' &&
        <div className="contents">
          <button type='button' className='close' onClick={() => clearPopup()}>닫기</button>
          <p>{Parser(t("입력하신 휴대폰 번호는 가입된 번호가 아니에요. 신규 회원가입하시겠어요?"))}</p>
          <div className="btns">
            <button type='button' className='btn lg round green' onClick={onAuthHome}>{t("회원가입")}</button>
          </div>
        </div>
      }
      {/* 이름 + 휴대폰 번호 불일치 */}
      {popup.type === 'inconsistency' &&
        <div className="contents">
          <button type='button' className='close' onClick={() => setPopup({ ...popup, isOpen: false })}>닫기</button>
          <p>{Parser(t("입력하신 이름이 계정 정보와 맞지 않아요. 다시 확인하고 입력해 주세요."))}</p>
          <div className="btns">
            <button type='button' className='btn lg round green' onClick={() => clearPopup()}>{t("확인")}</button>
          </div>
        </div>
      }
      {/* 휴면 계정 */}
      {popup.type === 'dormantAccount' &&
        <div className="contents">
          <button type='button' className='close' onClick={() => clearPopup()}>닫기</button>
          <p>{Parser(t("장기 미사용으로 휴면된 계정이에요. 휴면계정을 복구할까요?"))}</p>
          <div className="btns">
            <button type='button' className='close' onClick={() => clearPopup()}>닫기</button>
            <button type='button' className='btn lg round green' onClick={() => onUserRestore()}>{t("휴면계정 복구")}</button>
          </div>
        </div>
      }
      {/* 새로운 비밀번호 */}
      {popup.type === 'newPassword' &&
        <div className="contents">
          <p>{Parser(t("새로운 비밀번호가 설정되었습니다."))}</p>
          <div className="btns">
            <button type='button' className='btn lg round green' onClick={() => goLogin()}>{t("확인")}</button>
          </div>
        </div>
      }
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.5);
  z-index: 21;

  .contents {
    position: relative;
    width: 31rem;
    padding: 2.35rem 3rem 3.1rem 3rem;
    text-align: center;
    background-color: #fff;
    border-radius: 2.1rem;
    .close {
      position: absolute;
      top: -3.4rem;
      right: 0;
      width: 2.9rem;
      height: 2.9rem;
      font-size: 0;
      background-image: url("${require('../../assets/images/signup/icon_close_alert.png')}");
      background-repeat: no-repeat;
      background-size: 100%;
    }
    .welcome {
      text-align: center;
      img {
        width: 15rem;
      }
    }
    h1 {
      margin-bottom: 1.3rem;
      font-size: 1.9rem;
      font-weight: 700;
      color: #333;
    }
    p {
      font-size: 1.7rem;
    }
    .if-jitalk {
      margin-top: 2.6rem;
      padding-top: 2rem;
      border-top: 1px solid #ededed;
      h2 {
        font-size: 1.5rem;
        color: #00d2b4;
      }
      p {
        margin-top: 1.25rem;
        font-size: 1.4rem;
        color: #777;
      }
    }
    .btns {
      margin-top: 2.6rem;
      &.flex {
        display: flex;
        justify-content: space-between;
        padding: 0;
        button {
          width: calc(50% - 0.8rem);
        }
      }
      button:nth-of-type(2) {
        margin-top: 1rem;
      }
    }
    .input-wrap,
    .select-wrap {
      margin: 0 0 2.55rem 0;
    }
  }
`;

export default Alert;