import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import useStore from '../../../store/site/store';
import GoTop from '../layout/GoTop';
import Tab from "../layout/Tab";
import styled from 'styled-components';

const HelpList = ({ search, searchWord, setSearchWord, getList }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const timeRef = useRef(null);
  const wrapRef = useRef(null);
  const help = useStore((state) => state.help);
  const setHelp = useStore((state) => state.setHelp);
  const setLastHelpIdx = useStore((state) => state.setLastHelpIdx);
  const [showGoTop, setShowGoTop] = useState(false);
  const category = [
    {
      id: 1,
      cd: 'and',
      name: "Android",
    },
    {
      id: 2,
      cd: 'ios',
      name: "iOS",
    },
    {
      id: 3,
      cd: 'win',
      name: "Window PC",
    },
    {
      id: 4,
      cd: 'mac',
      name: "Mac",
    },
  ];

  /** 검색하기 */
  const onSearch = (e) => {
    e.preventDefault();
    setHelp([]);
    setLastHelpIdx("0");
    navigate(`/customer/help?search=${searchWord}`);
  }

  /** 검색된 단이 하이라이팅 */
  const highlightedText = (text) => {
    if (!search) {
      return text;
    }
    if (searchWord !== '' && text.includes(searchWord)) {
      const parts = text.split(new RegExp(`(${searchWord})`, 'gi'));
      return (
        <>
          {parts.map((part, index) =>
            part.toLowerCase() === searchWord.toLowerCase()
              ? <mark key={index}>{part}</mark>
              : part
          )}
        </>
      );
    }  
    return text;
  };

  const onScrollTop = () => {
    wrapRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleScroll = (e) => {
    if (wrapRef === null) {
      return;
    }
    if (wrapRef.current.scrollTop > 10) {
      setShowGoTop(true);
    } else {
      setShowGoTop(false);
    }
    if (Math.round(Math.round(wrapRef.current.scrollTop)) >= (wrapRef.current.clientHeight + window.outerHeight)) {
      if (!timeRef.current) {
        timeRef.current = setTimeout(function() {
          timeRef.current = null;
          getList();
        }, 500);
      }
    }
  }

  return (
    <Container className="content-wrap">
      <div className="top">
        <h1 className="page-title">도움말</h1>
        <div className="search">
          <form onSubmit={onSearch}>
            <input type="text" value={searchWord} onChange={(e) => setSearchWord(e.target.value)} placeholder={t('무엇을 도와드릴까요?')} />
            <button type='submit'>검색</button>
          </form>
        </div>
      </div>
      <Tab category={category} getList={getList} />
      <div className="list" ref={wrapRef} onScroll={handleScroll}>
        <ul>
          {(help.length > 0) && help.map((i) => (
            <li key={i.noticeIdx}>
              <Link to={`/customer/help/${i.noticeIdx}`}>
                <div className='subject'>
                  {(i.title.length > 50) ? highlightedText(i.title.substr(0, 50)) + '...' : highlightedText(i.title)}
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      {search &&
        <div className="help">
          {t('찾으시는 내용이 없으신가요?')} <Link to="/customer/contactus">[{t('고객센터에 문의하기')}]</Link>
        </div>
      }
      <GoTop showGoTop={showGoTop} onScrollTop={onScrollTop} />
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  padding-top: 6.9rem;
  .visible {
    display: block;
  }
  .hide {
    display: none;
  }
  .top {
    position: fixed;
    top: 5.1rem;
    left: 0;
    width: 100%;
    height: 7rem;
    padding: 0 1.9rem;
    background-color: #fff;
    border-bottom: 1px solid #EDEDED;
    z-index: 9;
    .search {
      position: relative;
      input[type='text'] {
        width: 100%;
        height: 4.8rem;
        padding: 0 2.5rem 0 1.5rem;
        font-size: 1.6rem;
        border: 1.5px solid #00d2b4;
        border-radius: 7px;
        &:focus {
          &::placeholder {
            color: white;
            opacity: 0;
          }
        }
        &::placeholder {
          color: #aaa;
        }
      }
      button {
        position: absolute;
        top: 50%;
        right: 1.7rem;
        width: 1.75rem;
        height: 1.75rem;
        font-size: 0;
        margin-top: -0.9rem;
        background-image: url("${require('../../../assets/images/customer/icon_search.png')}");
        background-repeat: no-repeat;
        background-size: 100%;
      }
    }
  }
  .list {
    border-top: 7px solid #f6f6f6;
    ul {
      li {
        padding: 0 1.9rem;
        border-bottom: 1px solid #ededed;
        a {
          display: block;
          padding: 1.8rem 0 2.1rem 0;
          font-size: 1.6rem;
          color: #222;
          line-height: 1.4;
          mark {
            font-weight: 500;
            color: #00D2B4;
            background-color: transparent;
          }
        }
      }
    }
  }
  .help {
    margin-top: 2.6rem;
    font-size: 1.4rem;
    color: #454545;
    text-align: center;
    a {
      font-weight: 500;
      text-decoration: underline;
    }
  }
  @media screen and (min-width: 800px) {
    padding-top: 0;
    .top {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      top: 0;
      height: auto;
      border-bottom: 0;
      margin-bottom: -0.7rem;
      padding-left: 0;
      padding-right: 0;
      .search {
        padding-bottom: 0;
        border-bottom: 0;
        input[type='text'] {
          width: 23.6rem;
          height: 4.3rem;
          border: 1px solid #e2e2e2;
          border-radius: 2.2rem;
        }
        button {
          width: 1.8rem;
          height: 1.8rem;
          margin-top: -0.8rem;
        }
      }
    }
    .list {
      max-height: 48rem;
      border-top: 0;
      overflow-y: auto;
      ul {
        li {
          &:first-of-type {
            border-top: 1px solid #222;
          }
          a {
            padding: 2.8rem 2rem 3rem 1.5rem;
            .subject {
              width: 100%;
              font-size: 1.8rem;
              line-height: 1;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }
    .help {
      font-size: 1.8rem;
    }
  }
`;

export default HelpList