import { Link } from "react-router-dom";

const Test = () => {
  return (
    <>
      <br/>
      <h1 style={{ fontSize: '20px' }}>고객센터</h1>
      <br/>
      <Link to="/customer/login?langcd=ko&route=notice" target='_blank'>공지사항(한)</Link>
      <br/>
      <Link to="/customer/login?langcd=en&route=notice" target='_blank'>공지사항(영)</Link>
      <br/>
      <Link to="/customer/login?langcd=ja&route=notice" target='_blank'>공지사항(일)</Link>
      <br/><br/>
      <Link to="/customer/login?langcd=ko&route=help" target='_blank'>도움말(한)</Link>
      <br/>
      <Link to="/customer/login?langcd=en&route=help" target='_blank'>도움말(영)</Link>
      <br/>
      <Link to="/customer/login?langcd=ja&route=help" target='_blank'>도움말(일)</Link>
      <br/><br/>
      <Link to="/customer/login?langcd=ko&nationcd=kr&route=contact" target='_blank'>문의하기(한)</Link>
      <br/>
      <Link to="/customer/login?langcd=en&nationcd=us&route=contact" target='_blank'>문의하기(영)</Link>
      <br/>
      <Link to="/customer/login?langcd=ja&nationcd=jp&route=contact" target='_blank'>문의하기(일)</Link>
      <br/>
      <br/>
      <Link to="/policy/service?langcd=ko" target='_blank'>이용약관 (한)</Link>
      <br/>
      <Link to="/policy/service?langcd=en" target='_blank'>이용약관 (영)</Link>
      <br/>
      <Link to="/policy/service?langcd=ja" target='_blank'>이용약관 (일)</Link>
      <br/>
      <br/>
      <Link to="/policy/privacy?langcd=ko" target='_blank'>개인정보처리방침 (한)</Link>
      <br/>
      <Link to="/policy/privacy?langcd=en" target='_blank'>개인정보처리방침 (영)</Link>
      <br/>
      <Link to="/policy/privacy?langcd=ja" target='_blank'>개인정보처리방침 (일)</Link>
      {/* <br/>
      <br/> */}
      {/* <Link to="/policy/operate?langcd=ko" target='_blank'>운영정책 (한)</Link>
      <br/>
      <Link to="/policy/operate?langcd=en" target='_blank'>운영정책 (영)</Link>
      <br/>
      <Link to="/policy/operate?langcd=ja" target='_blank'>운영정책 (일)</Link> */}
      <br/>
      <br/>
      <br/>
      <h1 style={{ fontSize: '20px' }}>화답하기</h1>
      <br/>
      <Link to={`/hwadap/login?langcd=ko`} target='_blank'>화답하기(한)</Link>
      <br/>
      <Link to={`/hwadap/login?langcd=en`} target='_blank'>화답하기(영)</Link>
      <br/>
      <Link to={`/hwadap/login?langcd=ja`} target='_blank'>화답하기(일)</Link>
      <br/>
      <br/>
      <br/>
      <h1 style={{ fontSize: '20px' }}>커뮤니티</h1>
      <br/>
      <Link to={`/cummunity/login?langcd=ko`} target='_blank'>커뮤니티(한)</Link>
      <br/>
      <Link to={`/cummunity/login?langcd=en`} target='_blank'>커뮤니티(영)</Link>
      <br/>
      <Link to={`/cummunity/login?langcd=ja`} target='_blank'>커뮤니티(일)</Link>
      <br/>
    </>
  )
}

export default Test