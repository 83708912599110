import { useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import useStore from '../../../store/hwadap/store';
import styled from "styled-components";

const Arrow = ({
    bottom,
    toastTimerRef,
    setShowToast,
    setToastMessage,
    isSearched,
    roomRef,
    wordID
  }) => {
  const { t } = useTranslation();
  const upRef = useRef();                                // 검색 위로 Ref
  const downRef = useRef();                              // 검색 아래로 Ref
  const topRef = useRef();                               // 맨 위로 Ref
  const bottomRef = useRef();                            // 맨 아래로 Ref
  const hwadap = useStore((state) => state.hwadap);      // Get 화답 리스트
  const group = useStore((state) => state.group);        // Get Group
  
  /** 스크롤시 맨위로/맨아래로 버튼 핸들링 */
  const handleScroll = () => {
    if (topRef.current !== null) {
      if (window.scrollY === 0) {
        topRef.current.classList.remove('active');
      } else {
        topRef.current.classList.add('active');
      }
      if (window.scrollY + window.outerHeight + 500 >= roomRef.current.clientHeight) {
        bottomRef.current.classList.remove('active');
      } else {
        bottomRef.current.classList.add('active');
      }
    }
  }

  /** 더이상 검색된 내용 없음 */
  const searchMoreEmpty = () => {
    setToastMessage(t('더 이상 검색된 내용이 없어요.'));
    setShowToast(true);
    toastTimerRef.current = setTimeout(() => {
      setShowToast(false);
    }, 2000);
  }

  /** 검색된 단어 스크롤 이동 */
  const onScrollWord = (direction) => {
    if (direction === 'up') {
      wordID.current++;
      if (wordID.current > document.querySelectorAll('.searched').length) {
        wordID.current = document.querySelectorAll('.searched').length;
        searchMoreEmpty();
        return;
      }
      if (wordID.current > 1) {
        document.getElementById('mark-' + (wordID.current - 1)).setAttribute('class', 'searched');
      }

      if (wordID.current >= document.querySelectorAll('.searched').length) {
        upRef.current.classList.remove('active');
      }
      if (wordID.current <= document.querySelectorAll('.searched').length) {
        downRef.current.classList.add('active');
      }
    } else if (direction === 'down') {
      wordID.current--;
      if (wordID.current < 1) {
        wordID.current = 1;
        searchMoreEmpty();
        return;
      }
      if (wordID.current < document.querySelectorAll('.searched').length) {
        document.getElementById('mark-' + (wordID.current + 1)).setAttribute('class', 'searched');
      }

      if (wordID.current <= document.querySelectorAll('.searched').length) {
        upRef.current.classList.add('active');
      }
      if (wordID.current <= 1) {
        downRef.current.classList.remove('active');
      }
    }
    document.getElementById('mark-' + wordID.current).setAttribute('class', 'searched active')
    document.getElementById('mark-' + wordID.current).scrollIntoView({ block: "center" });
  }

  /** 끝으로 이동 */
  const onScrollEnd = (direction) => {
    if (direction === 'top') {
      roomRef.current.scrollIntoView({ block: 'start' });
    } else if (direction === 'bottom') {
      roomRef.current.scrollIntoView({ block: 'end' });
    }
  }

  /** 검색 후 위/아래 버튼 클래스명 Init */
  useEffect(() => {
    if (isSearched) {
      if (wordID.current <= document.querySelectorAll('.searched').length) {
        upRef.current.classList.add('active');
        downRef.current.classList.remove('active');
      }
    }
  }, [group.messages, hwadap]);
  
  /** Init */
  useEffect(() => {    
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Container style={bottom && { bottom: bottom + 'rem'}}>
      <div className={!isSearched ? 'hide' : ''}>
        <button ref={upRef} type='button' className='up' onClick={() => onScrollWord('up')}>
          <span className="blind">위로</span>
        </button>
        <button ref={downRef} type='button' className='down' onClick={() => onScrollWord('down')}>
          <span className="blind">아래로</span>
        </button>
      </div>
      <div className={isSearched ? 'hide' : ''}>
        <button ref={topRef} type='button' className='top' onClick={() => onScrollEnd('top')} hidden>
          <span className="blind">맨 위로</span>
        </button>
        <button ref={bottomRef} type='button' className='bottom' onClick={() => onScrollEnd('bottom')}>
          <span className="blind">맨 아래로</span>
        </button>
      </div>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 9;
  > div {
    &.hide {
      position: absolute;
      width: 0;
      height: 0;
      visibility: hidden;
      opacity: 0;
    }
  }
  button {
    width: 5.5rem;
    height: 5.5rem;
    background-size: 100%;
    &.active {
      &.top {
        background-image: url("${require('../../../assets/images/hwadap/icon_go_top_active.png')}");
      }
      &.bottom {
        background-image: url("${require('../../../assets/images/hwadap/icon_go_bottom_active.png')}");
        visibility: visible;
        transition: 0.1s;
        opacity: 1;
      }
      &.up {
      background-image: url("${require('../../../assets/images/hwadap/icon_search_up_active.png')}");
    }
    &.down {
      background-image: url("${require('../../../assets/images/hwadap/icon_search_down_active.png')}");
    }
    }
    &.top {
      background-image: url("${require('../../../assets/images/hwadap/icon_go_top.png')}");
    }
    &.bottom {
      background-image: url("${require('../../../assets/images/hwadap/icon_go_bottom.png')}");
      visibility: hidden;
      opacity: 0;
    }
    &.up {
      background-image: url("${require('../../../assets/images/hwadap/icon_search_up.png')}");
    }
    &.down {
      background-image: url("${require('../../../assets/images/hwadap/icon_search_down.png')}");
    }
  }
`;

export default Arrow