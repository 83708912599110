import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useCommonStore from '../../../store/common/store';
import useStore from '../../../store/hwadap/store';
import MyHwadap from './MyHwadap';
import Content from './Content';
import styled, { keyframes } from "styled-components";
import moment from "moment";
import "moment/locale/ko";
import "moment/locale/en-au";
import "moment/locale/ja";

const Message = ({
    item,
    isSearched,
    onShowWrite,
    onScollToHwadoo,
    handleCopyHwadap,
    onDeleteHwadap,
    handleFileDownload
  }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();                                         // 네비게이트
  const langCd = useCommonStore((state) => state.langCd);                 // Get 언어
  const isPC = useCommonStore((state) => state.isPC);                     // Get IsPC
  const [messageLang, setMessageLang] = useState('ko');                   // 한영일 언어
  const hwadap = useStore((state) => state.hwadap);                       // Get 화답 리스트
  const setHwadap = useStore((state) => state.setHwadap);                 // Set 화답 리스트
  const group = useStore((state) => state.group);                         // Get 화답 리스트
  const setGroup = useStore((state) => state.setGroup);                   // Set 화답 리스트
  const setRoomScrollTop = useStore((state) => state.setRoomScrollTop);   // Set 스크롤 위치
  const [className, setClassName] = useState('');                         // 클래스명 (미화답/마감)
  const [activeUp, setActiveUp] = useState(true);                         // 위로
  const [activeDown, setActiveDown] = useState(true);                     // 아래로

  /** 그룹방으로 이동 */
  const onGoGrouproom = () => {
    setRoomScrollTop(window.scrollY);
    setGroup({...group, channelUrl: item.channelUrl, groupName: item.rcvGrpNm })
    navigate(`/hwadap/room/${item.sendIdx}?langcd=${langCd}`);
  }

  /** 언어 변경 */
  const onChangeMessageLang = (idx, lang) => {
    if (isSearched) {
      return;
    }
    const filterData = hwadap.map((i) => {
      if (i.sendIdx === idx) {
        return {...i, messageLang: lang};
      }
      return i;
    });
    setHwadap(filterData);
    setMessageLang(lang);
  }
  
  /** Init */
  useEffect(() => {
    if (isPC) {
      item?.myHdap?.length > 0
      ? setClassName('pc complete')
      : setClassName('pc')
    } else {
      if (item?.myHdap?.length > 0) setClassName('complete');
    }
    if (item.sendIdx === hwadap[0].sendIdx) setActiveUp(false);
    if (item.sendIdx === hwadap[hwadap.length - 1].sendIdx) setActiveDown(false);
  }, [hwadap]);

  useEffect(() => {
    langCd === 'ko'
    ? moment.locale("ko")
    : langCd === 'ja'
      ? moment.locale("ja")
      : moment.locale("en-au")
  }, []);

  return (
    <Container className={className} id={item.sendIdx}>
      {item.created &&
        <div className='date-bar'>
          <span>
            {langCd === "ko" || langCd === 'ja'
              ? moment(item.magamTimeZoneDtm).format('LL dddd')
              : moment(item.magamTimeZoneDtm).format('dddd, MMMM DD, YYYY')
            }
          </span>
        </div>
      }
      <div className="hwadoo">
        <div className="profile">
          <div className='avatar'>
            <img src={item.profileImg ? item.profileImg : require("../../../assets/images/hwadap/avatar_default.png")} alt="" />
          </div>
          <div className="name">{item.speakerNm}</div>
          <div className="date">
            {langCd === "ko" || langCd === 'ja'
              ? moment(item.magamTimeZoneDtm).format('A HH:mm')
              : moment(item.magamTimeZoneDtm).format('HH:mm A')
            }
          </div>
        </div>
        <div className='content-wrap'>
          <div className='content-box'>
            <div className="lang">
              <button
                type='button'
                className={messageLang === 'ko' ? 'active' : ''}
                onClick={() => onChangeMessageLang(item.sendIdx, 'ko')}>한
              </button>
              {(item.isRegisteredEngJa === "10" || item.isRegisteredEngJa === "11") &&
                <button
                  type='button'
                  className={messageLang === 'en' ? 'active' : ''}
                  onClick={() => onChangeMessageLang(item.sendIdx, 'en')}>En
                </button>
              }
              {(item.isRegisteredEngJa === "01" || item.isRegisteredEngJa === "11") &&
                <button
                  type='button'
                  className={messageLang === 'ja' ? 'active' : ''}
                  onClick={() => onChangeMessageLang(item.sendIdx, 'ja')}>日
                </button>
              }
            </div>
            <Content
              messageLang={messageLang}
              item={item}
              content={item.Hwadoo.msgContent}
              handleFileDownload={handleFileDownload}
            />
            <div className="state-btns">
              <button type='button' className='write' onClick={() => onShowWrite(item.sendIdx)}>
                <span><span className="blind">화답하기</span></span>
              </button>
              <button type='button' className='all' onClick={onGoGrouproom}>
                <span><span className="blind">전체화답</span><span className="count">({item.cntAnsUser})</span></span>
              </button>
            </div>
            <div className="move-btns">
              <button
                type='button'
                className={activeUp ? 'up active' : 'up'}
                disabled={activeUp ? false : true}
                onClick={() => onScollToHwadoo('up', item.sendIdx)}>
                  <span className="blind">위로</span>
              </button>
              <button
                type='button'
                className={activeDown ? 'down active' : 'down'}
                disabled={activeDown ? false : true}
                onClick={() => onScollToHwadoo('down', item.sendIdx)}>
                  <span className="blind">아래로</span>
              </button>
            </div>
            <div className="move-btns bottom">
              <button
                type='button'
                className={activeUp ? 'up active' : 'up'}
                disabled={activeUp ? false : true}
                onClick={() => onScollToHwadoo('up', item.sendIdx, true)}>
                  <span className="blind">위로</span>
              </button>
            </div>
          </div>
          <div className="info-box">
            <div className="group-name">{item.rcvGrpNm}</div>
            <div className="due-date">
              {t('화답 마감일')} : 
              {langCd === "ko" || langCd === 'ja'
                ? moment(item.magamTimeZoneDtm).format(' YYYY/ MM/ DD, a HH:mm')
                : moment(item.magamTimeZoneDtm).format(' MM/ DD/ YYYY, HH:mm A')
              }
            </div>
          </div>
        </div>
      </div>
      {item?.myHdap?.length > 0 &&
        item.myHdap.map((myHdap) => (
          <MyHwadap
            key={myHdap.idx}
            item={item}
            myHdap={myHdap}
            handleCopyHwadap={handleCopyHwadap}
            onDeleteHwadap={onDeleteHwadap}
          />
        ))
      }
    </Container>
  )
}

const searched = keyframes`
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
`;

const Container = styled.li`
  position: relative;
  padding-top: 1rem;
  padding-bottom: 3rem;
  user-select: none;
  &.pc {
    .date-bar {
      span {
        font-size: 1.4rem;
        line-height: 3.8rem;
        border-radius: 1.9rem;
      }
    }
    .hwadoo {
      .profile {
        .avatar {
          width: 5rem;
          height: 5rem;
          flex-shrink: 0;
        }
        .name, .date {
          padding-bottom: 2.2rem;
        }
      }
      .content-wrap {
        margin-left: 6.1rem;
        margin-top: -2rem;
        .info-box {
          .group-name {
            font-size: 1.4rem;
            line-height: 1;
          }
          .due-date {
            font-size: 1.4rem;
            line-height: 1;
          }
        }
      }
    }
  }
  &.complete {
    .hwadoo {
      .content-wrap {
        .content-box {
          background-color: #fffce3;
          border-color: #00d2b4;
          .lang {
            button.active {
              background-color: #fffce3;
              border-color: #00d2b4;
              &::before {
                background-color: #fffce3;
              }
            }
          }
        }
      }
    }
  }
  .searched {
    display: inline-block;
    &.active {
      animation: ${searched} 0.35s;
    }
  }
  .date-bar {
    position: relative;
    margin-top: 1rem;
    margin-bottom: 2.3rem;
    text-align: center;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #ededed;
      z-index: -1;
    }
    span {
      display: inline-block;
      padding: 0 1.5rem;
      font-size: 1.3rem;
      color: #999;
      line-height: 3.2rem;
      background-color: #fff;
      border: 1px solid #ededed;
      border-radius: 1.6rem;
    }
  }
  .hwadoo {
    .profile {
      display: flex;
      align-items: center;
      .avatar {
        width: 3.75rem;
        height: 3.75rem;
        margin-right: 1rem;
        border-radius: 50%;
        overflow: hidden;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .name {
        margin-right: 0.55rem;
        font-size: 1.5rem;
        font-weight: 500;
        color: #454545;
      }
      .date {
        font-size: 1.2rem;
        color: #999;
      }
    }
    .content-wrap {
      position: relative;
      max-width: 29.3rem;
      margin: 0 3.75rem 0 4.75rem;
      img, video, iframe {
        max-width: 100%;
      }
      .content-box {
        position: relative;
        padding: 1.2rem 1.8rem 7rem 1.8rem;
        border: 1px solid #ccc;
        border-radius: 0 1.5rem 1.5rem 1.5rem;
        .lang {
          position: absolute;
          top: 1.2rem;
          right: -3.1rem;
          display: flex;
          flex-direction: column;
          button {
            position: relative;
            width: 3rem;
            height: 3rem;
            margin-top: -1px;
            font-size: 1.4rem;
            font-weight: 500;
            color: #777;
            background-color: #f2f2f2;
            border: 1px solid #ccc;
            border-left: 0;
            border-radius: 0 0.7rem 0.7rem 0;
            z-index: 2;
            &.active {
              font-weight: bold;
              color: #31c5af;
              background-color: #fff;
              z-index: 3;
              &::before {
                content: "";
                position: absolute;
                top: 0;
                left: -1px;
                width: 1px;
                height: 100%;
                background-color: #fff;
              }
            }
          }
        }        
        .state-btns {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          display: flex;
          border-top: 1px solid #ccc;
          button {
            width: 50%;
            height: 4.5rem;
            > span {
              display: inline-block;
              width: 3rem;
              height: 3rem;
              line-height: 3rem;
              background-repeat: no-repeat;
              background-size: auto 100%;
            }
            &.write {
              > span {
                background-image: url("${require('../../../assets/images/hwadap/icon_write.png')}");
              }
            }
            &.all {
              border-left: 1px solid #ccc;
              > span {
                padding-left: 3.2rem;
                font-size: 1.5rem;
                font-weight: 500;
                color: #31c5af;
                background-image: url("${require('../../../assets/images/hwadap/icon_all.png')}");
              }
            }
          }
        }
        .move-btns {
          position: absolute;
          left: -3.15rem;
          top: 3rem;
          display: flex;
          flex-direction: column;
          &.bottom {
            top: auto;
            bottom: 1.2rem;
          }
          button {
            margin-top: 0.35rem;
            width: 2.5rem;
            height: 2.5rem;
            background-repeat: no-repeat;
            background-size: 100%;
            &.active {
              &.up {
                background-image: url("${require('../../../assets/images/hwadap/icon_hwadoo_up_active.png')}");
              }
              &.down {
                background-image: url("${require('../../../assets/images/hwadap/icon_hwadoo_down_active.png')}");
              }
            }
            &.up {
              background-image: url("${require('../../../assets/images/hwadap/icon_hwadoo_up.png')}");
            }
            &.down {
              background-image: url("${require('../../../assets/images/hwadap/icon_hwadoo_down.png')}");
            }
          }
        }
      }
      .info-box {
        .group-name {
          margin-top: 1rem;
          font-size: 1.5rem;
          line-height: 1.5rem;
          color: #454545;
        }
        .due-date {
          margin-top: 0.6rem;
          font-size: 1.5rem;
          line-height: 1.5rem;
          color: #ff5b18;
        }
      }
    }
  }
`;

export default Message