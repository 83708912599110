import { useState, useEffect } from 'react';
import axios from 'axios';
import Parser, { domToReact } from 'html-react-parser';
import useStore from '../../../store/hwadap/store';
import Attach from './Attach';
import Slide from './Slide';
import Media from './Media';
import styled from 'styled-components';

const Content = ({ messageLang, item, content, handleFileDownload }) => {
  const user = useStore((state) => state.user);
  const [slideList, setSlideList] = useState([]);
  const [slideNum, setSlideNum] = useState(0);
  const [showSlide, setShowSlide] = useState(false);
  const [mediaUrl, setMediaUrl] = useState('');
  const [mediaCaption, setMediaCaption] = useState('');
  const [showMedia, setShowMedia] = useState(false);

  /** 미디어 (콜러스) */
  const onMedia = async (mediaKey, cation) => {
    try {
      const { data } = await axios.post(`https://testApi.Earthtalk.app/file/kollus/geturl`, {
        "MediaKey": mediaKey,
        "userId": user.userId
      });

      const url = 'https://v.kr.kollus.com/s?jwt=' + data.jwt + '&custom_key=' + data.customKey + '&purge_chache=true&t=0';
      setMediaUrl(url);
      setShowMedia(true);
    } catch (e) {
      console.log(e);
    }
  };

  /** 이미지 슬라이드 */
  const onSlide = (e, file) => {
    if (slideList.length < 1) {
      return;
    }
    let num = '';
    if (file?.filePath) {
      num = slideList.findIndex((i) => i.defaulturl === (file.filePath + file.fileNm));
    } else {
      num = slideList.findIndex((i) => i.defaulturl === e.target.getAttribute('defaulturl'));
    }
    setSlideNum(num);
    setShowSlide(true);

  }

  /** 내용 Replace */
  const replace = (domNode) => {
    if (domNode.name === "img") {
      const imgName = domNode.attribs?.name ?? '';

      /*** 이미지 ***/
      if (imgName === '') {
        return (
          <img
            src={domNode.attribs.src}
            defaulturl={domNode.attribs.defaulturl}
            onClick={onSlide}
          />
        );
      }

      /*** 비디오 ***/
      if (imgName !== '') {
        return (
          <span className='fr-video' onClick={(() => onMedia(domNode.attribs["data-mediakey"]))}>
            <img src={domNode.attribs.src} />
            <button type='button' className='play'><span className='blind'>재생</span></button>
          </span>
        )
      }
    }

    if (domNode.name === "span") {
      /** 이미지 캡션 없을 경우 */
      if (domNode.attribs?.class === "fr-inner") {
        if (domNode.children.length < 1) {
          return (
            <></>
          );
        }
      }
      /*** 오디오 ***/
      if (domNode.attribs?.name === "kollusaudio") {
        return (
          <span className='fr-audio' onClick={(() => onMedia(domNode.attribs["data-mediakey"]))}>
            <button type='button' className='play'><span className='blind'>재생</span></button>
            <span className='name'>{domToReact(domNode.children, replace)}</span>
          </span>
        )
      }
    }

    if (domNode.name === "a") {
      /*** 파일 ***/
      if (domNode.attribs?.filepath) {
        return (
          <span className='fr-file'>
            <button
              type='button'
              className='download'
              onClick={() =>
                handleFileDownload(domNode.attribs.filepath, domNode.children[0].data)
              }>
                <span className='blind'>다운로드</span>
            </button>
            <span className='name'>{domToReact(domNode.children, replace)}</span>
          </span>
        )
      }

      /*** 링크 ***/
      if (domNode.attribs?.target === "_blank") {
        return (
          <span className='fr-link'>
            <a href={domNode.attribs.href} target="_blank">{domToReact(domNode.children, replace)}</a>
          </span>
        )
      }
    }
  };

  /** 슬라이드 이미지 셋팅 */
  const setImgList = (str) => {
    let dummy = document.createElement("div");
    dummy.innerHTML = str;
    let images = Array.from(dummy.querySelectorAll("img[src]"));
    let sources = images.map(i => {
      if (i.getAttribute('name') === "kollusvideo") {
        return false;
      }
      const defaulturl = i.getAttribute('defaulturl') ?? '';
      const filename = defaulturl.length ? defaulturl.replace(/^.*\//, '') : '';
      const newItem = {
        src: i.src,
        caption: i.alt,
        defaulturl: defaulturl,
        filename: filename,
      }
      return newItem;
    });
    sources = sources.filter((i) => i !== false);
    setSlideList(sources);
  }

  useEffect(() => {
    if (messageLang === 'ko') {
      setImgList(content.msgContent_ko);
    }
    if (messageLang === 'en') {
      setImgList(content.msgContent_en);
    }
    if (messageLang === 'ja') {
      setImgList(content.msgContent_ja);
    }
  }, [messageLang]);

  return (
    <Container>
      <div className='content'>
        {messageLang === 'ko' && Parser(content.msgContent_ko, { replace })}
        {messageLang === 'en' && Parser(content.msgContent_en, { replace })}
        {messageLang === 'ja' && Parser(content.msgContent_ja, { replace })}
      </div>
      {item.attach?.length > 0 &&
        <Attach
          messageLang={messageLang}
          attach={item.attach}
          onMedia={onMedia}
          handleFileDownload={handleFileDownload}
        />
      }
      {showSlide &&
        <Slide
          slideNum={slideNum}
          author={item.speakerNm}
          slideList={slideList}
          setShowSlide={setShowSlide}
          handleFileDownload={handleFileDownload}
        />
      }
      {showMedia &&
        <Media
          author={item.speakerNm}
          mediaUrl={mediaUrl}
          mediaCaption={mediaCaption}
          setShowMedia={setShowMedia}
        />
      }
    </Container>
  )
}

const Container = styled.div`
  word-break: break-all;
  .content {
    font-size : 1.6rem;
    color: #222;
    h1 {
      margin-bottom: 0.5rem;
      font-weight: 500;
    }
  }
  .searched {
    background-color: #222;
    color: white;
  }
  img {
    cursor: pointer;
  }
  .fr-inner {
    display: block;
    margin-top: 1rem;
    font-size: 1.4rem;
    text-align: center;
    color: #777;
  }
  a {
    font-size: 1.5rem;
    color: #317fc8;
    text-decoration: underline;
  }
  .fr-file, .fr-link {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1.4rem;
    margin-top: 1.35rem;
    background-color: #f8f8f8;
    border: 1px solid #dfdfdf;
    border-radius: 0.7rem;
    .download {
      width: 4rem;
      height: 4rem;
      margin-right: 1.1rem;
      background-image: url("${require('../../../assets/images/hwadap/icon_download_file.png')}");
      background-size: 100%;
      flex-shrink: 0;
    }
    .name {
      font-size: 1.6rem;
      color: #222;
      word-break: break-all;
    }
  }
  .fr-link {
    padding-left: 6.5rem;
    background-image: url("${require('../../../assets/images/hwadap/icon_attach_link.png')}");
    background-repeat: no-repeat;
    background-size: 3rem;
    background-position: 2rem center;
  }
  .fr-img-wrap {
    display: block;
    margin: 1rem 0;
    background-color: #fff;
    border-radius: 0.7rem;
    background-color: #f8f8f8;
    border: 1px solid #dfdfdf;
    font-size: 0;
    overflow: hidden;
    .fr-inner {
      display: block;
      padding: 0.7rem 1.5rem 1.3rem 1.5rem;
      font-size: 1.4rem;
      font-weight: 400;
      color: #999;
      text-align: center;
    }
  }
  .fr-video {
    position: relative;
    display: block;
    border-radius: 0.7rem;
    background-color: #f8f8f8;
    border: 1px solid #dfdfdf;
    overflow: hidden;
    img {
      display: block;
    }
    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8rem;
      height: 8rem;
      background-image: url("${require('../../../assets/images/hwadap/icon_video_play.png')}");
      background-size: 100%;
    }
  }
  .fr-audio {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1.4rem;
    margin-top: 1rem;
    background-color: #f8f8f8;
    border: 1px solid #dfdfdf;
    border-radius: 0.7rem;
    cursor: pointer;
    .play {
      width: 4rem;
      height: 4rem;
      margin-right: 1.1rem;
      background-image: url("${require('../../../assets/images/hwadap/icon_audio.png')}");
      background-size: 100%;
      flex-shrink: 0;
    }
    .name {
      font-size: 1.6rem;
      color: #222;
      word-break: break-all;
    }
  }
`;

export default Content