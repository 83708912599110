import { useTranslation } from 'react-i18next';
import useStore from "../../../store/signup/store";

const ID = ({ handleUserUsedUserIdCheck }) => {
  const { t } = useTranslation();                                    // 다국어
  const user = useStore((state) => state.user);                      // Get 유저
  const setUser = useStore((state) => state.setUser);                // Set 유저
  const error = useStore((state) => state.error);                    // Get 에러문구
  const setError = useStore((state) => state.setError);              // Set 에러문구
  const descript = useStore((state) => state.descript);              // Get 안내문구
  const setDescript = useStore((state) => state.setDescript);        // Set 안내문구

  // 아이디
  const onChangeId = (e) => {
    if (e.target.value.length > 49){
      e.target.value = e.value.slice(0, 49);
    }
    setError({ ...error, id: '' });
    setUser({ ...user, userID: e.target.value.toLowerCase() });
  }

  // 아이디 체크
  const onCheckId = (e) => {
    const value = e.target.value;
    const lowercase_pattern = /[a-z]/g;
    const hangul_pattern = /^[A-Za-z0-9+]*$/;

    if (value.length < 1) {
      setError({ ...error, id: t("필수 입력 사항이에요.") });
      return;
    }
    if (value.length < 5) {
      setError({ ...error, id: t("5자 이상 입력해 주세요.") });
      return;
    }
    if (value.length > 50) {
      setError({ ...error, id: t("50자 이하로 입력해 주세요.") });
      return;
    }
    if (lowercase_pattern.test(value) === false) {
      setError({ ...error, id: t("영문 소문자를 포함해서 입력해 주세요.") });
      return;
    }
    if (!hangul_pattern.test(value)) {
      setError({ ...error, id: t("영문 또는 영문 숫자로 입력해 주세요.") });
      return;
    }
    setError({ ...error, id: '' });
    handleUserUsedUserIdCheck(value);
  }

  return (
    <div className="input-wrap">
      {user.joinTypeCd !== '20'
        ?
          <>
            <input
              type="text"
              onChange={onChangeId}
              onBlur={onCheckId}
              value={user.userID}
              disabled={user.jitalkID !== ''}
              placeholder={t('아이디')}
            />
            <label>{t('아이디 입력')}</label>
            <p>
              {descript.id && <span>{descript.id}</span>}
              {error.id && <span className='error'>{error.id}</span>}
            </p>
          </>
        :
          <></>
      }
    </div>
  )
}

export default ID