import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import useCommonStore from '../../../store/common/store';
import styled from "styled-components";
import moment from "moment";
import "moment/locale/ko";
import "moment/locale/en-au";
import "moment/locale/ja";

const Message = ({ item }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();                                 // 네비게이트
  const langCd = useCommonStore((state) => state.langCd);         // Get 언어
  const isPC = useCommonStore((state) => state.isPC);             // Get isPC
  const [className, setClassName] = useState('');                 // 클래스명 (미화답/마감)
  const [Dday, setDday] = useState('');                           // D-Day
  const [hasImage, setHasImage] = useState(false);                // 첨부파일 리스트에 이미지 존재 여부
  const [hasVideo, setHasVideo] = useState(false);                // 첨부파일 리스트에 비디오 존재 여부
  const [hasAudio, setHasAudio] = useState(false);                // 첨부파일 리스트에 오디오 존재 여부
  const [hasFile, setHasFile] = useState(false);                  // 첨부파일 리스트에 파일 존재 여부
  const [hasLink, setHasLink] = useState(false);                  // 첨부파일 리스트에 링크 존재 여부

  /** 링크 이동 */
  const onGoHwadap = () => {
    navigate(`/hwadap/room?langcd=${langCd}#${item.sendIdx}`);
  }

  // 미화답/마감 클래스명 Init
  useEffect(() => {
    langCd === 'ko'
    ? moment.locale("ko")
    : langCd === 'ja'
      ? moment.locale("ja")
      : moment.locale("en-au")

    const today = new Date();
    const magamDay = new Date(item.magamTimeZoneDtm);
    if (today > magamDay) {
      setClassName('disabled');
    }
    const diff = magamDay - today;
    const diffDay = Math.floor(diff / (1000 * 60 * 60 * 24));
    setDday(diffDay);

    if (item?.myHdap?.length > 0) {
      setClassName('complete');
    }
    if (item?.attach?.length > 0) {
      item?.attach.map((i) => {
        if (i.mediaTypecd === 'IMG') {
          setHasImage(true);
        }
        if (i.mediaTypecd === 'MOV') {
          setHasVideo(true);
        }
        if (i.mediaTypecd === 'AUD') {
          setHasAudio(true);
        }
        if (i.mediaTypecd === 'FILE') {
          setHasFile(true);
        }
        if (i.mediaTypecd === 'LNK') {
          setHasLink(true);
        }
      });
    }
  }, []);

  return (
    <Container className={className} onClick={onGoHwadap}>
      <div className='info'>
        <div className="author">
          {item.speakerNm}
          {className === 'disabled'
            ?
              item.myHdap?.length > 0
              ?
                <>
                  <img src={require("../../../assets/images/hwadap/icon_hwadap_complete_lock.png")} alt="" />
                  <img src={require("../../../assets/images/hwadap/icon_hwadap_lock.png")} alt="" />
                </>
              :
                <>
                  <img src={require("../../../assets/images/hwadap/icon_hwadap_incomplte_lock.png")} alt="" />
                  <img src={require("../../../assets/images/hwadap/icon_hwadap_lock.png")} alt="" />
                </>
            :
              item.myHdap?.length > 0
              ?
                <img src={require("../../../assets/images/hwadap/icon_hwadap_complete.png")} alt="" />
              :
                <img src={require("../../../assets/images/hwadap/icon_hwadap_incomplte.png")} alt="" />
          }
        </div>
        <div className="date">
          {new Intl.DateTimeFormat(langCd, {
            month: 'long',
            day: 'numeric',
          }).format(new Date(item.sendTimeZoneDtm))}
        </div>
      </div>
      <div className='subject'>
        {isPC
          ? <span>
              {(item.Hwadoo.title.title_ko.length > 100) ? item.Hwadoo.title.title_ko.substr(0, 100) + '...' : item.Hwadoo.title.title_ko}
            </span>
          : <span>
              {(item.Hwadoo.title.title_ko.length > 40) ? item.Hwadoo.title.title_ko.substr(0, 40) + '...' : item.Hwadoo.title.title_ko}
            </span>
        }
        {hasImage && <img src={require("../../../assets/images/hwadap/icon_attach_image.png")} alt="" />}
        {hasVideo && <img src={require("../../../assets/images/hwadap/icon_attach_video.png")} alt="" />}
        {hasAudio && <img src={require("../../../assets/images/hwadap/icon_attach_audio.png")} alt="" />}
        {hasFile && <img src={require("../../../assets/images/hwadap/icon_attach_file.png")} alt="" />}
        {hasLink && <img src={require("../../../assets/images/hwadap/icon_attach_link.png")} alt="" />}
      </div>
      <div className='due-date'>
        <span>
          {t('화답 마감일')} : 
          {langCd === "ko" || langCd === 'ja'
            ? moment(item.magamTimeZoneDtm).format(' YYYY/ MM/ DD, a HH:mm')
            : moment(item.magamTimeZoneDtm).format(' MM/ DD/ YYYY, HH:mm A')
          }
        </span>
        <span className="d-day">D-{Dday}</span>
      </div>
    </Container>
  )
}

const Container = styled.div`
  padding: 1.7rem;
  margin-bottom: 1rem;
  border: 1px solid #dfdfdf;
  border-radius: 0.7rem;
  cursor: pointer;
  &.disabled {
    background-color: #f9f9f9;
    .info {
      .author,
      .date {
        color: #aaa;
      }
    }
    .subject,
    .due-date {
      color: #aaa;
      .d-day {
        display: none;
      }
    }
  }
  &.complete {
    background-color: #fffce3;
    border-color: #eeeac6;
  }
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .author {
      display: flex;
      align-items: center;
      font-size: 1.5rem;
      font-weight: 500;
      color: #222;
      img {
        height: 1.5rem;
        margin-left: 0.65rem;
      }
    }
    .date {
      font-size: 1.2rem;
      color: #84816a;
    }
  }
  .subject {
    margin-top: 0.7rem;
    font-size: 1.7rem;
    font-weight: 500;
    color: #222;
    img {
      height: 1.7rem;
      margin-left: 0.2rem;
      vertical-align: bottom;
    }
  }
  .due-date {
    margin-top: 0.7rem;
    font-size: 1.4rem;
    color: #ff5d18;
    .d-day {
      display: inline-block;
      margin-left: 0.6rem;
      padding: 0.15rem 0.35rem 0.25rem;
      font-size: 1.3rem;
      font-weight: 700;
      color: white;
      line-height: 1.3rem;
      background-color: #ff5d18;
      border-radius: 3px;
    }
  }
`;

export default Message