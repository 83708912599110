import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import useCommonStore from '../../../store/site/common';
import useStore from '../../../store/site/store';
import client from '../../../lib/api/customer';
import Header from '../../../components/site/layout/Header';
import ContactUs from '../../../components/site/contact/ContactUs';
import Footer from '../../../components/site/layout/Footer';

const ContactUsPage = () => {
  const { t } = useTranslation();
  const langCd = useCommonStore((state) => state.langCd);
  const contact = useStore((state) => state.contact);
  const setContact = useStore((state) => state.setContact);

  const getConatctList = async () => {
    try {
      const { data } = await client.post("/homepage/InquiryCategoryList", {
        langCd,
      });
      setContact(data.content);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getConatctList();
  }, []);

  return (
    <>
      <Helmet>
        {langCd === "ko"
          ? <title>Zero(지로) 문의하기</title>
          : <title>Zero - Contact Us</title>
        }
        <meta name="url" property="og:url" content="www.zerohsp.com/customer/contact" />
      </Helmet>
      <div className="site">
        <Header title={t('문의하기')} prev='close' />
        {contact.length > 0 && <ContactUs contact={contact} />}
        <Footer />
      </div>
    </>
  )
}

export default ContactUsPage