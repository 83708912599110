import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import client from '../../lib/api/signup';
import { useTranslation } from 'react-i18next';
import { useTimer } from 'react-timer-hook';
import useCommonStore from "../../store/common/store";
import useStore from "../../store/signup/store";
import AuthHome from '../../components/signup/AuthHome';
import PolicyAgree from '../../components/signup/PolicyAgree';
import Header from '../../components/signup/Header';
import Alert from '../../components/signup/Alert';
import Loader from '../../components/common/Loader';

const AuthHomePage = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();                                          // 다국어
  const { type } = useParams();                                            // auth 타입 (일반/아이디찾기)
  const navigate = useNavigate();                                          // 네비게이트
  const langCd = useCommonStore((state) => state.langCd);                  // Get 언어
  const nationCd = useCommonStore((state) => state.nationCd);              // Get nationcd
  const setLangCd = useCommonStore((state) => state.setLangCd);            // Set 언어
  const user = useStore((state) => state.user);                            // Get 유저
  const setUser = useStore((state) => state.setUser);                      // Set 유저
  const policy = useStore((state) => state.policy);                        // Get 약관
  const setPolicy = useStore((state) => state.setPolicy);                  // Set 약관
  const setNation = useStore((state) => state.setNation);                  // Set 국가 리스트
  const popup = useStore((state) => state.popup);                          // Get Popup
  const setPopup = useStore((state) => state.setPopup);                    // Set Popup
  const [isLoading, setIsLoading] = useState(false);                       // 로딩
  const [policyDetailContent, setPolicyDetailContent] = useState('');      // 약관 상세 내용
  const [openPolicyAgree, setOpenPolicyAgree] = useState(false);           // 약관 상세 토글
  const { seconds, minutes, restart } = useTimer({                         // 인증 시간
    expiryTimestamp: new Date(),
    autoStart: false,
    onExpire: () => {}
  });
  const [isSent, setIsSent] = useState(false);                             // 인증번호 발송 여부
  const authResendRef = useRef(0);                                         // 인증번호 재발송 시간
  const [authResendCount, setAuthResendCount] = useState(0);               // 인증번호 재발송 횟수
  const [showSendBtn, setShowSendBtn] = useState(false);                   // 인증번호 발송 버튼
  const [showSendMsg, setShowSendMsg] = useState(false);                   // 인증번호 발송 메세지
  const [findAfterReset, setFindAfterReset] = useState(false);             // 아아디 찾기 후 신규 회원가입
  const [showSubmitBtn, setShowSubmitBtn] = useState(false);               // 다음 버튼

  // 국가 리스트 가져오기
  const handleGetNationList = async () => {
    setIsLoading(true);
    try {
      const { data } = await client.get('/member/nationList');
      setIsLoading(false);
      setNation(data.content);
    } catch (e) {
      setIsLoading(false);
      setPopup({ type: 'alert', message: 'Server Error.', isOpen: true });
    }
  }
  
  // 약관 리스트 가져오기
  const handleGetUserPolicyList = async () => {
    if (type === 'findid') {
      return;
    }
    setIsLoading(true);
    try {
      const { data } = await client.post('/member/userPolicyList', { langCd: langCd });
      setIsLoading(false);
      const processPolicy = data.content.map((el) => {
        return {...el, isAgree: false}
      });
      setPolicy(processPolicy);
      setOpenPolicyAgree(true);
    } catch (e) {
      setIsLoading(false);
      setPopup({ type: 'alert', message: 'Server Error.', isOpen: true });
    }
  }
  
  // 약관 상세 불러오기
  const handleGetPolicyDetail = async (policyTypeCd) => {
    setIsLoading(true);
    try {
      let { data } = await client.post('/member/userPolicyView', { langCd, policyTypeCd });
      setIsLoading(false);
      data.content.policyContent = data.content.policyContent.replace(/\n/g, "<br/>");
      setPolicyDetailContent(data.content);
    } catch (e) {
      setIsLoading(false);
      setPopup({ type: 'alert', message: 'Server Error.', isOpen: true });
    }
  }

  // 문자 인증
  const handleCallSMS = async () => {
    setIsLoading(true);
    const smsData = {
      phoneNationNum: user.phoneNationNum,
      mPhoneNum: user.mPhoneNum,
    }
    try {
      const { data } = await client.post('/call_SMS', smsData);
      setIsLoading(false);

      if (data.state_Msg === 'twilio error') {
        if (data.content.code === 21211) {
          setPopup({ type: 'alert', message: `+(${smsData.phoneNationNum}) ${smsData.mPhoneNum} is not a valid phone number`, isOpen: true });
          return;
        }
      }
      if (data.content.status === 'failed') {
        if (data.content.Msg.type === 'Time') {
          const now = new Date().getTime();
          const sendDtm = new Date(data.content.Msg.sendDtm).getTime();
          console.log((sendDtm - now) / 1000)
          const time = 30 + Math.round((sendDtm - now) / 1000);

          if (langCd !== 'ko' && langCd !=='ja') {
            setPopup({ type: 'alert', message: 'Please wait ' + time + t("초 후 인증 번호 재발송이 가능합니다."), isOpen: true });
          } else {
            setPopup({ type: 'alert', message: time + t("초 후 인증 번호 재발송이 가능합니다."), isOpen: true });
          }
        } else if (data.content.Msg.type === 'Over') {
          setPopup({ type: 'alert', message: t('재발송 횟수가 5회가 넘었어요. 5분간 발송이 제한돼요.'), isOpen: true });
        } else {
          setPopup({ type: 'alert', message: 'failed.', isOpen: true });
        }
        return;
      }
      setIsSent(true);
      setShowSendMsg(true);
      setShowSendBtn(false);
    } catch (e) {
      setIsLoading(false);
      setPopup({ type: 'alert', message: 'Server Error.', isOpen: true });
    }
  }

  // 인증시간 연장
  const handleExtendTime = async () => {
    setIsLoading(true);
    try {
      const { data } = await client.post('/extend_SMSauth', {
        idx: user.authKey,
        phoneNationNum: user.phoneNationNum,
        mPhoneNum: user.mPhoneNum
      });
      setIsLoading(false);
      if (data.content.status === 'failed') {
        setPopup({ type: 'alert', message: 'Failed.', isOpen: true });
        return;
      }
      const time = new Date();
      time.setSeconds(time.getSeconds() + 300);
      restart(time);
    } catch (e) {
      setIsLoading(false);
      setPopup({ type: 'alert', message: 'Server Error.', isOpen: true });
    }
  }
  
  // 다음
  const handleConfirm_SMSauth = async (userData) => {
    setIsLoading(true);
    // 아이디 찾기 후 신규 회원가입 할 때
    if (findAfterReset) {
      navigate(`/signup/register?langcd=${langCd}&nationcd=${nationCd}`);
      return;
    }
    try {
      const { data } = await client.post('/confirm_SMSauth', userData);
      setIsLoading(false);

      // 코드 인증 실패
      if (data.state_Msg === 'SMSConfirm') {
        setPopup({ type: 'alert', message: t('입력된 번호가 바르지 않아요. 다시 확인하고 입력해주세요.'), isOpen: true });
        return;
      }
      
      if (data.state_Msg === 'Aleady') {
        // 일반 회원 (휴대폰 일치)
        if (data.content.Msg === 'HP') {
          setUser({ ...user, isAuth: true });
          setPopup({ type: 'alreadyPhoneNumber', message: '', isOpen: true });
          return;
        }
        // 일반 회원 (이름 + 휴대폰 일치)
        if (data.content.Msg === 'UserNmANDHP') {
          setUser({ ...user, isAuth: true });
          setPopup({ type: 'alreadyPersonalInformation', message: '', isOpen: true });
          return;
        }
      } else  if (data.state_Msg === 'JitalkJoin') {
        // 일반 회원 + 지톡 회원
        if (data.content.Msg === 'Use') {
          setUser({
            ...user,
            email: data.content.data.jitalkEmail,
            birthDt: data.content.data.jitalkBirthDt,
            jitalkID: data.content.data.jitalkid,
            isJitalkID: true,
            isAuth: true
          });
          if (type === 'jitalk') {
            // 지톡회원가입 > 지톡 회원인 경우
            setPopup({ type: 'complete', message: t('인증이 완료되었어요.'), isOpen: true });
          } else {
            // 일반회원가입 > 지톡 회원인 경우
            setPopup({ type: 'alreadyJitalkID', message: '', isOpen: true });
          }
          return;
        } else if (data.content.Msg === "Empty") {
          setUser({ ...user, isAuth: true });
          if (type === 'jitalk') {
            // 지톡회원가입 > 없는 회원인 경우
            setPopup({ type: 'notJitalkMember', isOpen: true });
          } else {
            // 일반회원가입 > 없는 회원인 경우
            setPopup({ type: 'complete', message: t('인증이 완료되었어요.'), isOpen: true });
          }
        }
      }
    } catch (e) {
      setIsLoading(false);
      setPopup({ type: 'alert', message: 'Server Error.', isOpen: true });
    }
  }

  // 아이디 찾기
  const handleUserIdSearchByNmMPhone = async () => {
    setIsLoading(true);
    try {
      const { data } = await client.post('/member/userIdSearchByNmMPhone', {
        nmFamily: user.nmFamily,
        nmFirst: user.nmFirst,
        phoneNationNum: user.phoneNationNum,
        mPhoneNum: user.mPhoneNum,
        langCd
      });
      setIsLoading(false);
      // 휴면 계정
      if (data.content.data.accountStateCd === "SLEEP") {
        setUser({
          ...user,
          userID: data.content.data.userID,
          joinDtm: data.content.data.joinDtm,
        });
        setPopup({ type: 'dormantAccount', message: '', isOpen: true });
        return;
      }
      // 이름 + 휴대폰 번호 일치
      if (data.content.result === '1') {
        setUser({
          ...user,
          userID: data.content.data.userID,
          joinTypeCd: data.content.data.joinTypeCd,
          openIDTypeCd: data.content.data.openIDTypeCd,
          joinDtm: data.content.data.joinDtm,
        });
        navigate(`/signup/findresult?langcd=${langCd}&nationcd=${nationCd}`);
        return;
      }
      // 이름 + 휴대폰 번호 불일치
      if (data.content.result === '2') {
        setPopup({ type: 'inconsistency', message: '', isOpen: true });
        return;
      }
      // 가입된 번호 아님
      if (data.content.result === '9') {
        setUser({ ...user, isAuth: true });
        setPopup({ type: 'notMember', message: '', isOpen: true });
        return;
      }
    } catch (e) {
      setIsLoading(false);
      setPopup({ type: 'alert', message: 'Server Error.', isOpen: true });
    }
  }

  // 휴면 계정 복구
  const handleUserRestore = async () => {
    setIsLoading(true);
    try {
      const { data } = await client.post('/member/userRestore', { userID : user.userID });
      setIsLoading(false);
      if (data.content.status === 'failed') {
        setPopup({ type: 'alert', message: data.content.Msg, isOpen: true });
        return;
      }
      if (data.content.status === 'Success') {
        setPopup({ type: 'alert', message: t('휴면이 복구되었어요.'), isOpen: true });
        navigate(`/signup/findresult?langcd=${langCd}&nationcd=${nationCd}`);
      }
    } catch (e) {
      setIsLoading(false);
      setPopup({ type: 'alert', message: 'Server Error.', isOpen: true });
    }
  }

  const getCookie = function(name) {
    const value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
      return value ? value[2] : null;
  };

  const deleteCookie = (name) => {
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/"
  }

  // 약관, 국가 리스트 가져오기
  useEffect(() => {
    if (langCd?.length > 0) {
      handleGetUserPolicyList();
      handleGetNationList();
    } else {
      const lang = new URL(encodeURI(window.location.href)).searchParams.get("langcd") ?? 'ko';
      i18n.changeLanguage(lang);
      setLangCd(lang);
    }
    const commonCookie = JSON.parse(getCookie("earthtalkCommon")) ?? '';
    if (commonCookie !== '') {
      deleteCookie('earthtalkCommon');
      deleteCookie('earthtalkUser');
      deleteCookie('earthtalkPolicy');
    }
  }, [langCd, findAfterReset]);

  // 인증시간 초기화
  const resetAuth = () => {
    setAuthResendCount(0);
    authResendRef.current = 0;
    setUser({ ...user, authCode: '' });
    setShowSubmitBtn(false);
  }

  // 아이디 찾기 후 신규 회원가입
  const handleFindAfterReset = () => {
    setIsSent(false);
    setShowSendBtn(false);
    setFindAfterReset(true);
  }

  return (
    <div className='signup'>
      {isLoading && <Loader />}
      <Header prev='close' />
      <AuthHome
        seconds={seconds}
        minutes={minutes}
        restart={restart}
        resetAuth={resetAuth}
        authResendRef={authResendRef}
        handleCallSMS={handleCallSMS}
        handleConfirm_SMSauth={handleConfirm_SMSauth}
        handleUserIdSearchByNmMPhone={handleUserIdSearchByNmMPhone}
        handleExtendTime={handleExtendTime}
        showSendMsg={showSendMsg}
        setShowSendMsg={setShowSendMsg}
        isSent={isSent}
        setIsSent={setIsSent}
        showSendBtn={showSendBtn}
        setShowSendBtn={setShowSendBtn}
        authResendCount={authResendCount}
        setAuthResendCount={setAuthResendCount}
        showSubmitBtn={showSubmitBtn}
        setShowSubmitBtn={setShowSubmitBtn}
        findAfterReset={findAfterReset}
      />
      {openPolicyAgree && policy.length > 0 &&
        <PolicyAgree
          policyDetailContent={policyDetailContent}
          setPolicyDetailContent={setPolicyDetailContent}
          setOpenPolicyAgree={setOpenPolicyAgree}
          handleGetPolicyDetail={handleGetPolicyDetail}
        />
      }
      {popup.isOpen &&
        <Alert
          handleFindAfterReset ={handleFindAfterReset}
          handleUserIdSearchByNmMPhone={handleUserIdSearchByNmMPhone}
          handleUserRestore={handleUserRestore}
        />
      }
    </div>
  )
}

export default AuthHomePage