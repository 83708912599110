import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useCommonStore from '../../../store/common/store';
import useStore from '../../../store/hwadap/store';
import Header from '../layout/Header';
import Arrow from '../layout/Arrow';
import Message from './Message';
import styled from "styled-components";
import moment from "moment";
import "moment/locale/ko";
import "moment/locale/en-au";
import "moment/locale/ja";

const GroupRoom = ({
    isLoading,
    toastTimerRef,
    setShowToast,
    setToastMessage,
    handleGetIncomplete,
  }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();                                   // 네비게이트
  const langCd = useCommonStore((state) => state.langCd);           // Get 언어
  const isPC = useCommonStore((state) => state.isPC);               // Get IsPC
  const user = useStore((state) => state.user);                     // Get 유저
  const hwadap = useStore((state) => state.hwadap);                 // Get 화답리스트
  const group = useStore((state) => state.group);                   // Get 그룹
  const setGroup = useStore((state) => state.setGroup);             // Set 그룹
  const setWriteId = useStore((state) => state.setWriteId);         // Set write ID
  const roomRef = useRef();                                         // 룸 DIV Ref
  const wordID = useRef(1);                                         // 검색 단어용 id
  const [searchedWord, setSearchedWord] = useState('');             // 검색된 단어
  const [isSearched, setIsSearched] = useState(false);              // 검색 여부
  const [showWriteBtn, setShowWriteBtn] = useState(false);          // 화답하기 버튼

  /** 화답하기 */
  const onGoHwadap = () => {
    setWriteId(group.sendIdx);
    navigate(-1);
  }

  /** 검색 Replace */
  const onReplace = (item, word = '') => {
    if (word.length > 0) {
      const parts = item.split(new RegExp(`(?![^<]*>)(${word})`, 'gi'));
      const replace = parts.map((part) => {
        if (part.toLowerCase() === word.toLowerCase()) {
          return `<mark className='searched'>${part}</mark>`
        } else {
          return part;
        }
      });
      return replace.join("");
    } else {
      return item.replace(/(<mark className='searched'>|<\/mark>)/g, "");
    }
  }

  /** 검색하기 */
  const onSearch = (word) => {
    const hdapLength = group.hdapList?.length ?? 0;
    if (hdapLength < 1) {
      return;
    }
    if (word === searchedWord) {
      return;
    }
    const initData = group.hdapList.map((i) => {
      return {
        ...i,
        msgContent: onReplace(i.msgContent)
      }
    });
    const processData = initData.map((i) => {
      return  {
        ...i,
        msgContent: onReplace(i.msgContent, word)
      }
    });
    let count = 0;
    processData.map((i) => {
      if (i.msgContent.includes("className='searched'")) {
        count++;
      }
    });
    if (count === 0) {
      setToastMessage(t('검색 결과가 없어요.'));
      setShowToast(true);
      toastTimerRef.current = setTimeout(() => {
        setShowToast(false);
      }, 2000);
    } else {
      setIsSearched(true);
    }
    setGroup({ ...group, hdapList: processData });
    setSearchedWord(word);
  };

  /** 검색 초기화 */
  const onSearchClear = () => {
    const hdapLength = group.hdapList?.length ?? 0;
    if (hdapLength < 1) {
      return;
    }
    setIsSearched(false);
    const processData = group.hdapList.map((i) => {
      return {
        ...i,
        msgContent: onReplace(i.msgContent)
      }
    });
    setGroup({ ...group, hdapList: processData });
    setSearchedWord('');
    wordID.current = 1;
  };

  useEffect(() => {
    /** 검색된 단어에 ID넣기 */
    let searched = document.querySelectorAll('.searched');
    for (let i = 0; i < searched.length; i++) {
      searched[i].setAttribute('id', 'mark-' + (searched.length - i));
    }
    /** 첫 검색 시 */
    if (isSearched && document.querySelectorAll('.searched').length) {
      document.getElementById('mark-1').scrollIntoView({ block: 'center' })
      document.getElementById('mark-1').setAttribute('class', 'searched active');
    }
  }, [group.hdapList]);

  /** 마운트 후 스크롤 + 화답하기 버튼 토글 */
  useEffect(() => {
    const today = new Date();
    const dday = new Date(group.magamTimeZoneDtm);
    if (!isLoading && roomRef.current !== null) {
      roomRef.current.scrollIntoView({ behavior: 'auto', block: 'end', inline: 'nearest' });
      const filterData = hwadap.filter((i) => i.sendIdx === +group.sendIdx);
      const myHdapLength = filterData[0]?.myHdap?.length ?? 0;
      if (myHdapLength < 1 && today < dday) {
        setShowWriteBtn(true);
      } else {
        setShowWriteBtn(false);
      }
    }
  }, [isLoading]);

  useEffect(() => {
    langCd === 'ko'
    ? moment.locale("ko")
    : langCd === 'ja'
      ? moment.locale("ja")
      : moment.locale("en-au")
  }, []);

  return (
    <Container ref={roomRef} className={isPC && 'pc'}>
      <Header
        title={group.groupName}
        onSearch={onSearch}
        onSearchClear={onSearchClear}
        isSearched={isSearched}
        setIsSearched={setIsSearched}
      />
      <div className="top-info">
        {(group.Dday < 0)
          ? <div className="d-day lock"><span className='blind'>화답 마감됨</span></div>
          : <div className="d-day">D-{group.Dday}</div>
        }
        <div className="content">
          <div className="title">
            {t('총')} <span>{group.rcvCnt}</span>{t('명 중')} <span>{group.ansCnt}</span>{t('명이 화답을 하였습니다.')}<br />({t('화답율')} <span>{group.ansRate}</span>%)
          </div>
          <div className="due-date">
            {t('화답 마감일')} : 
            {langCd === "ko" || langCd === 'ja'
              ? moment(group.magamTimeZoneDtm).format(' YYYY/ MM/ DD, a HH:mm')
              : moment(group.magamTimeZoneDtm).format(' MM/ DD/ YYYY, HH:mm A')
            }
          </div>
        </div>
        <div className="btns">
          {showWriteBtn &&
            <button className='write' onClick={onGoHwadap}><span className="blind">화답하기</span></button>
          }
          {group.ansCnt < group.rcvCnt &&
            <button className='incomplete' onClick={() => handleGetIncomplete(user.userId, group.sendIdx, group.channelUrl)}><span className="blind">미완료자</span></button>
          }
        </div>
      </div>
      <ul className="message-list">
        {group.hdapList && group.hdapList.map((message) => (
          <Message key={message.regDtm} message={message} />
        ))}
      </ul>
      <Arrow
        toastTimerRef={toastTimerRef}
        setShowToast={setShowToast}
        setToastMessage={setToastMessage}
        isSearched={isSearched}
        roomRef={roomRef}
        wordID={wordID}
      />
    </Container>
  )
}

const Container = styled.div`
  padding-top: 15.5rem;
  padding-bottom: 3rem;
  &.pc {
    .top-info {
      padding: 1.8rem;
      .d-day {
        width: 4.4rem;
        height: 4.4rem;
        margin-right: 1.2rem;
        font-size: 1.9rem;
      }
      .content {
        .title {
          font-size: 1.5rem;
          line-height: 2.2rem;
        }
        .due-date {
          margin-top: 0.2rem;
          font-weight: 400;
        }
      }
      .btns {
        button {
          &.write {
            background-image: url("${require('../../../assets/images/hwadap/btn_write_pc.png')}");
          }
          &.incomplete {
            background-image: url("${require('../../../assets/images/hwadap/btn_incomplete_pc.png')}");
          }
          &:nth-of-type(2) {
            margin-top: 0.7rem;
          }
        }
      }
    }
  }
  .top-info {
    position: fixed;
    top: 5.02rem;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1.4rem 2rem 1.6rem 2rem;
    border-top: 1px solid #ededed;
    background-color: #fff;
    box-shadow: 0 0.3rem 0.5rem -0.1rem rgba(0, 0, 0, 0.1);
    z-index: 4;
    .d-day {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.4rem;
      height: 3.4rem;
      margin-right: 0.75rem;
      font-size: 1.4rem;
      font-weight: 700;
      color: #fff;
      white-space: nowrap;
      background-color: #ff5d18;
      border-radius: 50%;
      flex-shrink: 0;
      &.lock {
        background-image: url("${require('../../../assets/images/hwadap/icon_hwadap_lock_group.png')}");
        background-color: transparent;
        background-size: 100%;
      }
    }
    .content {
      margin-right: auto;
      .title {
        font-size: 1.6rem;
        color: #222;
        line-height: 2.1rem;
        span {
          font-weight: 700;
          color: #ff5d18;
        }
      }
      .due-date {
        margin-top: 0.55rem;
        font-size: 1.4rem;
        font-weight: 500;
        color: #999;
      }
    }
    .btns {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: stretch;
      flex-shrink: 0;
      button {
        width: 5.2rem;
        height: 3.1rem;
        background-size: 100%;
        &.write {
          background-image: url("${require('../../../assets/images/hwadap/btn_write.png')}");
        }
        &.incomplete {
          background-image: url("${require('../../../assets/images/hwadap/btn_incomplete.png')}");
        }
        &:nth-of-type(2) {
          margin-top: 0.5rem;
        }
      }
    }
  }
  .message-list {
    padding: 0.5rem 0;
  }
`;

export default GroupRoom