import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Parser from 'html-react-parser';
import useCommonStore from "../../../store/site/common";
import useStore from "../../../store/site/store";
import TextareaAutosize from 'react-textarea-autosize';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import Alert from "../layout/Alert";
import styled from 'styled-components';

const ContactUsForm = ({
  category,
  fileList,
  totalFileSize,
  nationList,
  ipAddress,
  policy,
  handleFileUpload,
  handleFileDelete,
  handleInquiryInsert
}) => {
  const { t } = useTranslation();
  const isWeb = useCommonStore((state) => state.isWeb);                       // get isWeb
  const langCd = useCommonStore((state) => state.langCd);                     // get langCd
  const osTypeCd = useCommonStore((state) => state.osTypeCd);                 // get osTypeCd
  const nationCd = useCommonStore((state) => state.nationCd);                 // get nationCd
  const setNationCd = useCommonStore((state) => state.setNationCd);           // set nationCd
  const [userEnvInfo, setUserEnvInfo] = useState('');                         // os 정보
  const [content, setContent] = useState('');                                 // 문의 내용
  const [contentLength, setContentLength] = useState(0);                      // 문의 내용 길이
  const [receive, setReceive] = useState('sms');                              // 답변 받을 연락처
  const [phoneNationNum, setPhoneNationNum] = useState('');                   // 국제전화코드
  const [mPhoneNum, setMPhoneNum] = useState('');                             // 휴대폰 번호
  const [email, setEmail] = useState('');                                     // 이메일
  const [isAgreePersonalInfo, setIsAgreePersonalInfo] = useState(false);      // 개인정보이용동의
  const firstContact = useStore((state) => state.firstContact);               // 먼저 체크 목록
  const user = useStore((state) => state.user);
  const categoryName = useStore((state) => state.categoryName);
  const alert = useStore((state) => state.alert);
  const setAlert = useStore((state) => state.setAlert);

  /** 문의내용 */
  const onChangeContent = (e) => {
    if (e.target.value.length > 999) {
      return;
    }
    setContent(e.target.value);
    setContentLength(e.target.value.length);
  }

  /** 파일 추가 */
  const onFileAdd = (e) => {
    if (e.target.files[0].fileSize > 50000000 || totalFileSize > 50000000) {
      setAlert({
        ...alert,
        isOpen: true,
        message: t('이 파일은 첨부할 수 없습니다.'),
        btn: '닫기'
      });
      return;
    }
    handleFileUpload(e.target.files[0]);
  }

  /** 파일 삭제 */
  const onFileDelete = (id, filename) => {
    handleFileDelete(id, filename);
  }

  /** 국가전화코드 */
  const onChangeNationCd = (e) => {
    const match = nationList.filter((n) => (n.nationCd === e.target.value));
    setNationCd(e.target.value);
    setPhoneNationNum(match[0]?.phoneNationNum);
  }

  /** 전화번호 */
  const onChangeMPhoneNum = (e) => {
    setMPhoneNum(e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'));
  }

  /** 글자 자르기 */
  const getFileName = (oriFileNm) => {
    const fileLength = oriFileNm.length;
    const lastDot = oriFileNm.lastIndexOf('.');
    const fileExtension = oriFileNm.substring(lastDot + 1, fileLength);
    let fileName = oriFileNm.substring(0, lastDot);
    const length = window.innerWidth / 25;
    if (fileLength > length) fileName = fileName.substr(0, length) + '..';
    return fileName + '.' + fileExtension;
  }

  /** 유효성 체크 */
  const onValidate = () => {
    if (
      !isAgreePersonalInfo ||
      (receive === 'sms' && mPhoneNum === '') ||
      (receive === 'email' && email === '')
    ) {
      console.log('유효성 체크!!!')
      return false;
    } else {
      return true;
    }
  }

  /** Submit */
  const onSubmit = (e) => {
    e.preventDefault();
    const validate = onValidate();
    const attachList = fileList.map((i) => {
      const newObj = {};
      newObj.fileNm = i.fileNm;
      newObj.filePath = i.filePath;
      newObj.downPath = i.downPath;
      newObj.fileSize = i.fileSize;
      newObj.oriFileNm = i.oriFileNm;
      return newObj;
    });

    if (validate) {
      const data = {
        userId: user.userId,
        inquiryCategoryCd: category,
        inquiryContent: content,
        nationCd: nationCd,
        phoneNationNum: phoneNationNum,
        mPhoneNum: mPhoneNum,
        email: email,
        isAgreePersonalInfo: isAgreePersonalInfo,
        osTypeCd: osTypeCd,
        webAppGbnCd: isWeb ? 'Web' : 'App',
        userAgent: navigator.userAgent,
        userEnvInfo: userEnvInfo,
        ip: ipAddress,
        attach: attachList
      }
      handleInquiryInsert(data);
    }
  }

  useEffect(() => {
    if (nationList?.length > 0) {
      const match = nationList.filter((n) => (n.nationCd === nationCd.toUpperCase()));
      setPhoneNationNum(match[0]?.phoneNationNum);
    }
  }, [nationList]);

  return (
    <Container className='content-wrap'>
      <div className="help">
        <h1 className="title">
          {(langCd === 'ko' || langCd === 'ja')
            ?
              <>
                {categoryName}<span>{t('에 궁금한 내용을 남겨주세요')} :	&#41;</span>
              </>
            :
              <>
                <span>{t('에 궁금한 내용을 남겨주세요')}</span> {categoryName}
              </>
          }
        </h1>
        <div className="box">
          <p><span>{t('먼저 체크!')}</span> {categoryName} {t('관련 자주 묻는 질문')}</p>
          <ul>
            {firstContact.map((i) => (
              <li key={i.noticeIdx}>
                <Link to={`/customer/help/${i.noticeIdx}`}>{i.title}</Link>
              </li>
            ))}
          </ul>
          <div className="more">
            <Link to='/customer/help?from=contact'>더보기</Link>
          </div>
        </div>
      </div>
      <div className="form">
        <form onSubmit={onSubmit}>
          <div className="input-box">
            <h2 className="if-pc">문의 내용 입력</h2>
            <input
              type="text"
              value={userEnvInfo}
              onChange={(e) => setUserEnvInfo(e.target.value)}
            />
            <div className='descript'>
              (Ex : Galaxy S22 / Android 12)<br/>
              {t('*사용하고 계신 기기의 기종, OS, OS버전을 알고 계시면 기재해주세요.(선택)')}
            </div>
            <TextareaAutosize
              value={content}
              onChange={onChangeContent}
              placeholder={t('여기에 문의 내용을 적어주세요')}
              style={{ overflow: 'hidden' }}
            />
            <div className="length">{contentLength}/1000</div>
          </div>
          <div className="attach-box">
            <h3 className="if-pc">파일 첨부</h3>
            <div className="progress">
              <progress max="50000000" value={totalFileSize}></progress>
              <div className="length"><span>{(totalFileSize / 1000000).toFixed(0)}MB</span>/50MB</div>
            </div>
            <div className="file-list">
              <div className="add">
                <input type="file" id='file' capture="camera" onChange={onFileAdd} />
                <label htmlFor="file"><span className="blind">첨부</span></label>
                <div className="length"><span className={fileList.length ? 'active' : ''}>{fileList.length}</span>/5</div>
              </div>
              <Swiper slidesPerView='auto'>
                {fileList && fileList.map((i) => (
                  <SwiperSlide key={i.id}>
                    {i.src
                      ?
                        <div className="thumb">
                          <img src={i.src} />
                        </div>
                      : 
                        <div className="filename">{getFileName(i.fileNm)}</div>
                    }
                    <button type="button" className="delete" onClick={() => onFileDelete(i.id, i.fileNm)}>
                      <span className="blind">삭제</span>
                    </button>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="descript">{t('첨부 파일은 최대 5개, 50MB까지 등록 가능합니다.')}</div>
          </div>
          <div className="input-box">
            <h2>{t('답변 받으실 연락처')}</h2>
            <input
              type="radio"
              name="receive"
              id="receive_sms"
              value='sms'
              checked={receive === 'sms'}
              onChange={(e) => setReceive(e.target.value)}
            />
            <label htmlFor="receive_sms">{t('문자')}</label>
            <input
              type="radio"
              name="receive"
              id="receive_email"
              value='email'
              checked={receive === 'email'}
              onChange={(e) => setReceive(e.target.value)}
            />
            <label htmlFor="receive_email">{t('이메일')}</label>
            {receive === 'sms' &&
              <div className="wrap">
                <select onChange={onChangeNationCd} value={nationCd}>
                  {nationList.map((i) => (
                    <option key={i.nationCd} value={i.nationCd}>+{i.phoneNationNum}({i.nationNm})</option>
                  ))}
                </select>
                <input type="text" value={mPhoneNum} onChange={onChangeMPhoneNum} />
                {/* <div className="descript red">휴대폰 번호 '-'없이 숫자만 입력해주세요.</div> */}
              </div>
            }
            {receive === 'email' &&
              <div className="wrap">
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
            }
          </div>
          <div className="policy-box">
            <input type="checkbox" id="policy" onChange={(e) => setIsAgreePersonalInfo(e.target.checked)} />
            <label htmlFor="policy">{t('[필수] 개인정보 수집 및 이용 동의')}</label>
            <div className="box">{Parser(policy)}</div>
          </div>
          <div className="info-box">
            <ul>
              {Parser(t('고객센터 운영시간'))}
            </ul>
          </div>
          <div className="btn">
            <button className="submit" type='submit'>{t('문의 작성하기')}</button>
          </div>
        </form>
      </div>
      {alert.isOpen && <Alert />}
    </Container>
  )
}

const Container = styled.div`
  .if-pc {
    display: none;
  }
  .help {
    padding: 2.5rem;
    border-bottom: 1px solid #f3f3f3;
    .title {
      font-size: 1.9rem;
      color: #222;
      line-height: 1.3;
      span {
        display: none;
      }
    }
    .box {
      p {
        margin-top: 1.3rem;
        margin-bottom: 1.4rem;
        font-size: 1.4rem;
        color: #777;
        span {
          margin-right: 0.4rem;
          font-weight: bold;
          color: #31c5af;
          line-height: 2.4rem;
          letter-spacing: -0.84px;
        }
      }
      ul {
        li {
          margin-bottom: 1.7rem;
          a {
            font-size: 1.5rem;
            color: #454545;
            line-height: 1.7rem;
            letter-spacing: -0.9px;
            text-decoration: underline;
          }
        }
      }
      .more {
        a {
          display: inline-block;
          padding-right: 1.5rem;
          font-size: 1.4rem;
          color: #31c5af;
          line-height: 1.7rem;
          letter-spacing: -0.84px;
          background-image: url("${require('../../../assets/images/customer/arrow_more.png')}");
          background-size: 1.5rem;
          background-position: right center;
          background-repeat: no-repeat;
        }
      }
    }
  }
  .form {
    padding: 1.5rem 0 4rem 2.5rem;
    border-top: 7px solid #f6f6f6;
    .descript {
      margin-top: 0.7rem;
      font-size: 1.4rem;
      color: #777;
      letter-spacing: -0.84px;
      &.red {
        color: #FF3F3F;
      }
    }
    .input-box {
      position: relative;
      margin-bottom: 3.7rem;
      padding-right: 2.5rem;
      h2 {
        margin-bottom: 2rem;
        font-size: 1.9rem;
        font-weight: 700;
      }
      input[type="text"],
      input[type="email"],
      select,
      textarea {
        margin-top: 2.5rem;
        resize: none;
      }
      input[type="radio"] + label {
        margin-right: 4.5rem;
        font-size: 1.6rem;
      }
      .length {
        font-size: 1.3rem;
        color: #aaa;
        text-align: right;
      }
    }
    .attach-box {
      margin-bottom: 4.2rem;
      .progress {
        display: flex;
        align-items: center;
        padding-right: 2.5rem;
        progress {
          width: 100%;
          height: 0.6rem;
          border-radius: 0.3rem;
          overflow: hidden;
          -webkit-appearance: none;
          &::-webkit-progress-bar {
            background: #D9D9D9;
          }
          &::-webkit-progress-value {
            background: #31C5AF;
          }
        }
        .length {
          margin-left: 1.35rem;
          font-size: 1.3rem;
          color: #AAAAAA;
          flex-shrink: 0;
          span {
            color: #31C5AF;
          }
        }
      }
      .file-list {
        display: flex;
        margin-top: 1rem;
        .add {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          width: 7rem;
          height: 7rem;
          margin-top: 1rem;
          margin-right: 0.85rem;          
          background-color: #DEDEDE;
          flex-shrink: 0;
          input[type="file"] {
            position: absolute;
            visibility: hidden;
          }
          input[type="file"] + label {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            z-index: 2;
          }
          .length {
            min-width: 2.2rem;
            padding-top: 3rem;
            font-size: 1.3rem;
            color: #A5A5A5;
            background-image: url("${require('../../../assets/images/customer/icon_file_add.png')}");
            background-repeat: no-repeat;
            background-size: 2.2rem;
            background-position: center 0.5rem;
            .active {
              font-weight: 700;
              color: #FF3F3F;
            }
          }
        }
        .swiper {
          margin-left: 0;
          padding-top: 1rem;
          .swiper-wrapper {
            .swiper-slide {
              position: relative;
              width: 7rem;
              height: 7rem;
              margin-right: 0.85rem;
              .thumb {
                width: 7rem;
                height: 7rem;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
              .filename {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                width: 7rem;
                height: 7rem;
                padding: 1rem;
                word-break: break-all;
                font-size: 1.1rem;
                border: 1px solid #DBDBDB;
              }
              .delete {
                position: absolute;
                top: -0.8rem;
                right: -0.8rem;
                width: 1.8rem;
                height: 1.8rem;
                background-image: url("${require('../../../assets/images/customer/icon_file_delete.png')}");
                background-repeat: no-repeat;
                background-size: 100%;
              }
            }
          }
        }
      }
    }
    .policy-box {
      margin-top: 3.7rem;
      padding-right: 2.5rem;
      input[type="checkbox"] + label {
        font-size: 1.6rem;
        font-weight: 500;
      }
      .box {
        height: 10.5rem;
        margin-top: 1.6rem;
        padding: 1.2rem 1.8rem;
        font-size: 1.4rem;
        color: #777;
        background-color: #F9F9F9;
        overflow-y: auto;
      }
    }
    .info-box {
      margin-top: 1.9rem;
      padding-right: 2.5rem;
      ul {
        li {
          position: relative;
          margin-bottom: 0.7rem;
          padding-left: 0.85rem;
          font-size: 1.3rem;
          color: #777;
          letter-spacing: -0.78px;
          &::before {
            content: "";
            position: absolute;
            top: 0.85rem;
            left: 0;
            width: 3px;
            height: 3px;
            background-color: #777;
            border-radius: 50%;
          }
        }
      }
    }
    .btn {
      margin-top: 3rem;
      padding-right: 2.5rem;
      .submit {
        width: 100%;
        height: 4.75rem;
        font-size: 1.6rem;
        font-weight: bold;
        color: white;
        letter-spacing: -0.96px;
        background-color: #00d2b4;
        border-radius: 2.4rem;
      }
    }
  }
  @media screen and (min-width: 800px) {
    .help {
      .title {
        font-size: 3.6rem;
        span {
          display: inline-block;
          font-weight: 400;
        }
      }
      .box {
        margin-top: 3rem;
        padding: 3.6rem 4rem;
        background-color: #F9F9F9;
        border-radius: 1.4rem;
      }
    }
  }
`;

export default ContactUsForm