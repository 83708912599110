import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import useCommonStore from '../../../store/site/common';
import useStore from '../../../store/site/store';
import client from '../../../lib/api/customer';
import Header from '../../../components/site/layout/Header';
import NoticeDetail from '../../../components/site/notice/NoticeDetail'
import Footer from '../../../components/site/layout/Footer';

const NoticeDetailPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const langCd = useCommonStore((state) => state.langCd);
  const user = useStore((state) => state.user);
  const [noticeDetail, setNoticeDetail] = useState('');

  const getNoticeDetail = async () => {
    try {
      const { data } = await client.post("/homepage/NoticeView", {
        langCd,
        userId: user.userId,
        noticeIdx: id.toString(),
      });
      setNoticeDetail(data.content);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getNoticeDetail();
  }, []);

  return (
    <>
      <Helmet>
        {langCd === "ko"
          ? <title>Zero(지로) 공지사항</title>
          : <title>Zero - Notice</title>
        }
        <meta name="url" property="og:url" content={`www.zerohsp.com/customer/notice/${id}`} />
      </Helmet>
      <div className='site'>
        <Header title={t('공지사항')} prev='back' page="detail" />
        {noticeDetail && <NoticeDetail noticeDetail={noticeDetail} />}
        <Footer />
      </div>
    </>
  )
}

export default NoticeDetailPage