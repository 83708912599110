import { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
import { Zoom, Navigation, Pagination, Thumbs, Controller } from "swiper";
import styled from "styled-components";

const Slide = ({ author, slideNum, slideList, setShowSlide, handleFileDownload }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState();
  const wrapRef = useRef('');
  
  let toggle = true;
  const onToggle = () => {
    toggle = !toggle;
    setTimeout(() => {
      if (!toggle) {
        wrapRef.current.classList.add('hide');
      } else {
        wrapRef.current.classList.remove('hide');
      }
    }, 400);
  }

  const onFileDownload = (e, url, filename) => {
    e.stopPropagation();
    handleFileDownload(url, filename)
  }

  return (
    <Container ref={wrapRef}>
      <div className="header">
        <button type='button' className='close' onClick={() => setShowSlide(false)}>
          <span className="blind">닫기</span>
        </button>
        <div className="author">{author}</div>
      </div>
      <div className="thumb-wrap">
        <Swiper
          onSwiper={setThumbsSwiper}
          watchSlidesProgress
          spaceBetween={5}
          slidesPerView='auto'
          centeredSlides={true}
          slideToClickedSlide={true}
          pagination={{ type: "fraction" }}
          modules={[Navigation, Pagination]}
        >
          {slideList && slideList.map((item) => (
            <SwiperSlide key={item.src}>
              <div className="swiper-zoom-container">
                <img src={item.src} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="visual-wrap">
        <Swiper
          initialSlide={slideNum}
          controller={{ control: thumbsSwiper }}
          navigation={toggle ? true : false}
          thumbs={{ swiper: thumbsSwiper, slideThumbActiveClass: 'slide-thumb' }}
          modules={[Zoom, Navigation, Thumbs, Controller]}
          zoom={{ maxRatio: 2.5, minRatio: 1 }}
          onTouchStart={() => {}}
          onTouchEnd={() => {}}
          onClick={onToggle}
        >
          {slideList && slideList.map((item) => (
            <SwiperSlide key={item.src}>
              <div className="swiper-zoom-container">
                <img src={item.src} />
              </div>
              <div className="caption">{item.caption}</div>
              <div className="download">
                <button type='button' onClick={(e) => onFileDownload(e, item.defaulturl, item.filename)}><span className="blind">다운로드</span></button>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  user-select: none;
  -webkit-tap-highlight-color:transparent;
  z-index: 21;
  &.hide {
    .header,
    .thumb-wrap,
    .caption,
    .download,
    .swiper-button-prev,
    .swiper-button-next {
      visibility: hidden;
      opacity: 0;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
  .header {
    position: relative;
    display: flex;
    align-items: center;
    height: 5.02rem;
    padding: 0 1.55rem;
    background-color: #000;
    z-index: 9;
    .close {
      width: 2.9rem;
      height: 2.9rem;
      margin-right: 1.2rem;
      background-image: url("${require('../../../assets/images/hwadap/icon_close_image_slide.png')}");
      background-size: 100%;
    }
    .author {
      font-size: 1.9rem;
      font-weight: 700;
      color: white;
    }
  }
  .thumb-wrap {
    position: relative;
    height: 8.5rem;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2;
    .swiper {
      overflow: visible;
      .swiper-slide {
        position: relative;
        width: 5.5rem;
        height: 5.5rem;
        cursor: pointer;
        opacity: 0.4;
        &.swiper-slide-active {
          opacity: 1;
          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0.3rem solid #00d2b4;
          }
        }
      }
      .swiper-pagination {
        bottom: -2.3rem;
        font-size: 1.3rem;
        font-weight: 500;
        color: #31c5af;
      }
    }
  }
  .visual-wrap {
    transform: translateY(-8.5rem);
    .swiper {
      height: calc(100vh - 10.5rem);
      overflow: visible;
      .swiper-button-next,
      .swiper-button-prev {
        width: 4rem;
        height: 6rem;
        background-size: 100%;
        opacity: 1;
        &::after {
          display: none;
        }
      }
      .swiper-button-prev {
        background-image: url("${require('../../../assets/images/hwadap/arrow_slide_prev_active.png')}");
        &.swiper-button-disabled {
          background-image: url("${require('../../../assets/images/hwadap/arrow_slide_prev.png')}");
        }
      }
      .swiper-button-next {
        background-image: url("${require('../../../assets/images/hwadap/arrow_slide_next_active.png')}");
        &.swiper-button-disabled {
          background-image: url("${require('../../../assets/images/hwadap/arrow_slide_next.png')}");
        }
      }
      .swiper-button-disabled {
        display: none;
      }
      .caption {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 7rem;
        padding: 0 1.9rem;
        font-size: 1.6rem;
        font-weight: 500;
        color: white;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 9;
      }
    }
  }
  .download {
    position: absolute;
    bottom: 0;
    height: 5.7rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    transform: translateY(5.5rem);
    z-index: 10;
    button {
      width: 4.2rem;
      height: 4.2rem;
      background-image: url("${require('../../../assets/images/hwadap/icon_download.png')}");
      background-size: 100%;
      cursor: pointer;
    }
  }
`;

export default Slide