import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useCommonStore from '../../../store/common/store';
import styled from "styled-components";

const Header = ({
    page,
    prev,
    title,
    isSearched,
    setIsSearched,
    onSearch,
    onSearchClear,
    watchSearch,
    setWatchSearch
  }) => {
  const isPC = useCommonStore((state) => state.isPC);                     // Get IsPC
  const navigate = useNavigate();                                         // 네비게이트
  const headerRef = useRef(null);                                         // 헤더 Ref
  const searchRef = useRef(null);                                         // 검색 단어
  const [showSearchInput, setShowSearchInput] = useState(false);          // 검색창 토글

  /** 뒤로가기 */
  const onPrev = () => {
    if (prev === 'close') {
      alert('웹뷰 닫기 호출');
      window.close();
      return;
    }
    navigate(-1);
  }

  /** 검색창 닫기 */
  const closeSearch = () => {
    onSearchClear();
    setIsSearched(false);
    setShowSearchInput(false);
  }

  /** 검색창 열기 */
  const openSearch = () => {
    setShowSearchInput(true);
  }
  
  /** 검색어 초기화 */
  const onClear = () => {
    searchRef.current.value = '';
    searchRef.current.focus();
  }
  
  /** 검색하기 */
  const onSubmit = (e) => {
    e.preventDefault();
    if (searchRef.current.value.length > 0) {
      if (page === 'myroom') {
        setWatchSearch(watchSearch + 1);
      }
      onSearch(searchRef.current.value);
    }
  }

  /** 스크롤 시 그림자 효과 */
  const handleScrollHeader = () => {
    if (window.pageYOffset > 10) {
      headerRef.current.classList.add('active');
    } else {
      headerRef.current.classList.remove('active');
    }
  }

  /** 검색창 포커스 */
  useEffect(() => {
    if (showSearchInput) {
      searchRef.current.focus();
    }
  }, [showSearchInput]);

  /** Scroll Event */
  useEffect(() => {
    handleScrollHeader();
    window.addEventListener("scroll", handleScrollHeader);
    return () => {
      window.removeEventListener('scroll', handleScrollHeader);
    };
  }, []);

  return (
    <Container ref={headerRef} className={isPC && 'pc'}>
      <div className="top">
        {isPC && page === 'myroom'
          ?
            <></>
          :
          <div className="prev">
            <button type="button" onClick={onPrev}>
              <span className="blind">뒤로가기</span>
            </button>
          </div>
        }
        <h1 className="title">{title}</h1>
        <div className="search-btn">
          <button type='button' onClick={openSearch}>
            <span className="blind">검색</span>
          </button>
        </div>
        {showSearchInput &&
          <div className="search-wrap">
            <form onSubmit={onSubmit}>
              <button type="button" className="close" onClick={closeSearch}>
                <span className="blind">닫기</span>
              </button>
              <input type="text" ref={searchRef} />
              <button type="button" className="clear" onClick={onClear}>
                <span className="blind">클리어</span>
              </button>
              <button type="submit" className="submit">
                <span className="blind">검색</span>
              </button>
            </form>
          </div>
        }
      </div>
      {isSearched &&
        <button className='search-cancel' onClick={closeSearch}>
          <span className="blind">검색 취소</span>
        </button>
      }
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 9;
  &.active {
    box-shadow: 0 1px 5px rgba(0,0,0,0.1);
  }
  &.pc {
    .top {
      padding: 0 1.2rem;
      .title {
        font-size: 2.2rem;
        font-weight: 500;
      }
      .search-wrap {
        top: 5.02rem;
        height: 6.8rem;
        padding: 1.4rem 1.8rem;
        border-top: 1px solid #ededed;
        box-shadow: 0 5px 5px -5px rgba(0,0,0,0.18);
        form {
          height: 4rem;
          padding: 0 1.2rem;
          background-color: #f9f9f9;
          border: 1px solid #ededed;
          border-radius: 0.6rem;
          input[type='text'] {
            font-size: 1.5rem;
            font-weight: 400;
            background-color: transparent;
          }
          button {
            width: 2.1rem;
            height: 2rem;
            &.close {
              margin-right: 0.75rem;
              background-image: url("${require('../../../assets/images/hwadap/icon_close_search_pc.png')}");
              background-size: 2.1rem;
            }
            &.clear {
              margin-right: 0.7rem;
              background-size: 1.6rem;
            }
            &.submit {
              display: block;
              background-image: url("${require('../../../assets/images/hwadap/icon_search_submit.png')}");
            }
          }
        }
      }
      .search-btn {
        button {
          width: 3.6rem;
          height: 3.6rem;
          background-image: url("${require('../../../assets/images/hwadap/icon_search_pc.png')}");
          border-radius: 50%;
          &:hover {
            background-color: rgba(0, 0, 0, 0.1);;
          }
        }
      }
    }
  }
  .top {
    position: relative;
    height: 5.02rem;
    padding: 0 1.55rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .prev {
      button {
        width: 2.9rem;
        height: 2.9rem;
        background-image: url("${require('../../../assets/images/hwadap/arrow_prev.png')}");
        background-size: 100%;
      }
    }
    .title {
      margin-left: 1.2rem;
      margin-right: auto;
      font-size: 1.9rem;
      font-weight: 700;
      color: #222;
    }
    .search-wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0 1.3rem 0 1.55rem;
      background-color: #fff;
      form {
        display: flex;
        align-items: center;
        height: 100%;
        input[type='text'] {
          width: 100%;
          font-size: 1.9rem;
          font-weight: 500;
          color: #222;
          border-bottom: 0;
        }
        button {
          width: 2.9rem;
          height: 2.9rem;
          flex-shrink: 0;
          background-repeat: no-repeat;
          background-position: center;
          &.close {
            margin-right: 1.2rem;
            background-image: url("${require('../../../assets/images/hwadap/icon_close_search.png')}");
            background-size: 100%;
          }
          &.clear {
            background-image: url("${require('../../../assets/images/hwadap/icon_clear_search.png')}");
            background-size: 1.7rem;
          }
          &.submit {
            display: none;
          }
        }
      }
    }
    .search-btn {
      button {
        width: 2.9rem;
        height: 2.9rem;
        background-image: url("${require('../../../assets/images/hwadap/icon_search.png')}");
        background-size: 100%;
      }
    }
  }
  .search-cancel {
    position: fixed;
    bottom: 7rem;
    left: 50%;
    width: 4.2rem;
    height: 4.2rem;
    transform: translateX(-50%);
    background-image: url("${require('../../../assets/images/hwadap/icon_search_cancel.png')}");
    background-size: 100%;
  }
`;

export default Header