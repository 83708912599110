import { useState, useEffect, useRef } from "react";
import useCommonStore from '../../../store/common/store';
import TextareaAutosize from 'react-textarea-autosize';
import styled from "styled-components";

const Write = ({ writeWrapRef, onWrite }) => {
  const isPC = useCommonStore((state) => state.isPC);
  const writeRef = useRef();
  const [writeText, setWriteText] = useState('');
  const [showWriteBtn, setShowWriteBtn] = useState(false);

  /** 화답 작성하기 */
  const onSubmit = () => {
    onWrite(writeText);
    setWriteText('');
  }
  
  /** 전송 버튼 토글 */
  useEffect(() => {
    if (writeText.length < 1) {
      setShowWriteBtn(false);
      return;
    }
    setShowWriteBtn(true);
  }, [writeText]);

  /** 마운트 후 포커스 */
  useEffect(() => {
    writeRef.current.focus();
  }, []);
    
  return (
    <Container ref={writeWrapRef} className={isPC && 'pc'}>
      <div className="wrap">
        <form>
          <TextareaAutosize
            ref={writeRef}
            value={writeText}
            onChange={(e) => setWriteText(e.target.value)}
          />
          {showWriteBtn
            ?
            <button type='button' className='send active' onClick={onSubmit}>
              <span className="blind">전송하기</span>
            </button>
            :
            <button type='button' className='send'>
              <span className="blind">전송하기</span>
            </button>
          }
        </form>
      </div>
    </Container>
  )
}

const Container = styled.div`
  &.pc {
    .wrap {
      position: relative;
      box-shadow: none;
      border-radius: 0;
      overflow: visible;
      &::after {
        content: "";
        position: absolute;
        bottom: 5.7rem;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #e0e0e0;
      }
      textarea {
        min-height: 12.4rem;
        padding: 1.4rem 1.6rem 6rem 1.4rem;
        font-size: 1.5rem;
        border: 1px solid #e0e0e0;
        border-radius: 1.4rem;
        &:focus {
          box-shadow:  0 2px 1.4rem rgba(48, 136, 125, 0.5);
        }
      }
      .send {
        bottom: 1.3rem;
        width: 6.3rem;
        padding-left: 1.8rem;
        background-image: url("${require('../../../assets/images/hwadap/icon_send_pc.png')}");
        background-repeat: no-repeat;
        background-color: #ececec;
        background-position: 1rem center;
        background-size: auto;
        border-radius: 0.6rem;
        &::after {
          content: '전송';
          font-size: 1.5rem;
          font-weight: 700;
          color: #b1b1b1;
        }
        &.active {
          background-image: url("${require('../../../assets/images/hwadap/icon_send_pc_active.png')}");
          background-color: #1dc1ae;
          &::after {
            color: white;
          }
          &:hover {
            background-color: #279e8c;
          }
        }
      }
    }
  }
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 1.5rem 2rem 1.5rem;
  background-color: #fff;
  z-index: 10;
  .wrap {
    position: relative;
    box-shadow: 0 2px 1rem rgba(0, 0, 0, 0.15);
    border-radius: 3rem;
    overflow: hidden;
    textarea {
      min-height: 5rem;
      padding: 1.3rem 6rem 0 2rem;
      font-size: 1.6rem;
      color: #222;
      border: none;
      resize: none;
      overflow-y: hidden;
    }
    .send {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
      width: 3.5rem;
      height: 3.5rem;
      background-image: url("${require('../../../assets/images/hwadap/icon_send.png')}");
      background-size: 100%;
      flex-shrink: 0;
      &.active {
        background-image: url("${require('../../../assets/images/hwadap/icon_send_active.png')}");
      }
    }
  }
`;

export default Write;