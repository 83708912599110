import create from "zustand";
import { devtools, persist } from "zustand/middleware";

let state = (set) => ({
  /** 로그인 유저 정보 */
  user: {
    userId: '',
  },
  setUser: (data) => set(() => ({
    user: data
  })),

  /** 화두 + 나의 화답 리스트 */
  hwadap: [],
  setHwadap: (data) => set(() => ({
    hwadap: data
  })),

  /** 마지막 화두 번호 */
  lastSendIdx: '',
  setLastSendIdx: (data) => set(() => ({
    lastSendIdx: data
  })),

  /** 10건 더보기 토글 */
  showMoreBtn: false,
  setShowMoreBtn: (data) => set(() => ({
    showMoreBtn: data
  })),

  /** 10건 더보기 후 스크롤용 화두 번호 */
  beforeLastSendIdx: 0,
  setBeforeLastSendIdx: (data) => set(() => ({
    beforeLastSendIdx: data
  })),

  /** 첫 로딩 후 스크롤 맨 아래로 이동 */
  isScrolled: false,
  setIsScrolled: (data) => set(() => ({
    isScrolled: data
  })),
  
  /** 1:1 화답방 스크롤 위치 */
  roomScrollTop: -1,
  setRoomScrollTop: (data) => set(() => ({
    roomScrollTop: data
  })),

  /** 미화답만 보기 체크 여부 */
  isIncomplete: false,
  setIsIncomplete: (data) => set(() => ({
    isIncomplete: data
  })),

  /** 그룹방 화답 리스트 */
  group: {
    groupName: '',
    sendIdx: '',
    channelUrl: '',
    magamTimeZoneDtm: '',
    rcvCnt: '',
    ansCnt: '',
    ansRate: '',
    Dday: '',
    hdapList: [],
  },
  setGroup: (data) => set(() => ({
    group: data
  })),
  clearGroup: () => set(() => ({
    group: {
      groupName: '',
      sendIdx: '',
      channelUrl: '',
      magamTimeZoneDtm: '',
      rcvCnt: '',
      ansCnt: '',
      ansRate: '',
      Dday: '',
      hdapList: [],
    }
  })),

  /** 화답하기 ID */
  writeId: '',
  setWriteId: (data) => set(() => ({
    writeId: data
  })),

  /** 화답 미완료자 */
  incomplete: [],
  setIncomplete: (data) => set(() => ({
    incomplete: data
  })),

  /** 팝업 */
  popup: {
    type: '',
    message: '',
    isOpen: '',
  },
  setPopup: (data) => set(() => ({
    popup: data
  })),
  setClearPopup: () => set(() => ({
    popup: {
      type: '',
      message: '',
      isOpen: ''
    }
  })),
});

// state = persist(state, { name: 'earthTalkHwadap', getStorage: () => localStorage });

const useStore = create(devtools(state));

export default useStore;