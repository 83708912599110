import React from 'react'

const LoginPage = () => {
  // if user 있으면 웹뷰 닫기
  return (
    <div>
      로그인 후<br/>
      사용자 체크<br/>
      최초 사용자면 인트로로 이동<br/>
      아니면 피드 리스트로 이동<br/>
    </div>
  )
}

export default LoginPage