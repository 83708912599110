import { useState, useRef, useEffect } from 'react';
import { Link, useParams, useLocation } from "react-router-dom";
import useCommonStore from "../../store/common/store";
import useStore from "../../store/signup/store";
import { useTranslation } from 'react-i18next';
import Auth from './Auth';
import styled from 'styled-components';

const AuthHome = ({
    seconds,
    minutes,
    restart,
    authResendRef,
    handleCallSMS,
    handleConfirm_SMSauth,
    handleUserIdSearchByNmMPhone,
    handleExtendTime,
    showSendMsg,
    setShowSendMsg,
    showSendBtn,
    setShowSendBtn,
    isSent,
    setIsSent,
    authResendCount,
    setAuthResendCount,
    showSubmitBtn,
    setShowSubmitBtn,
    resetAuth,
    findAfterReset
  }) => {
  const { t } = useTranslation();                                      // 다국어
  const wrapRef = useRef();                                            // DIV Ref
  const { type } = useParams();                                        // auth 타입 (일반/아이디찾기)
  const langCd = useCommonStore((state) => state.langCd);              // Get 언어
  const nationCd = useCommonStore((state) => state.nationCd);          // Get nationCd
  const user = useStore((state) => state.user);                        // Get 유저
  const setUser = useStore((state) => state.setUser);                  // Set 유저
  const error = useStore((state) => state.error);                      // Get 에러
  const setError = useStore((state) => state.setError);                // Set 에러
  const descript = useStore((state) => state.descript);                // Get 안내문구
  const setDescript = useStore((state) => state.setDescript);          // Set 안내문구
  const popup = useStore((state) => state.popup);                      // Get Popup
  const setPopup = useStore((state) => state.setPopup);                // Set Popup
  const [hideSendBtn, setHideSendBtn] = useState(false);               // 인증번호 발송 버튼 토글


  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const [test, setTest] = useState();

  // 성
  const onChangeLastname = (e) => {
    if (e.target.value.length > 29){
      e.target.value = e.value.slice(0, 29);
    }
    setError({ ...error, nmFamily: '' });
    setUser({ ...user, nmFamily: e.target.value.replace(/[0-9]/g, '') });
  }

  // 성 체크
  const onCheckLastname = (e) => {
    if (e.target.value === '') {
      setError({ ...error, nmFamily: t("필수 입력 사항이에요.") });
      return;
    }
  }

  // 이름
  const onChangeFirstname = (e) => {
    if (e.target.value.length > 29){
      e.target.value = e.value.slice(0, 29);
    }
    setError({ ...error, nmFirst: '' });
    setUser({ ...user, nmFirst: e.target.value.replace(/[0-9]/g, '') });
  }  
  
  // 이름 체크
  const onCheckFirstname = (e) => {
    if (e.target.value === '') {
      setError({ ...error, nmFirst: t("필수 입력 사항이에요.") });
      return;
    }
  }

  // 다음
  const onRegister = () => {
    if (error.nmFamily !== '' || error.nmFirst !== '') {
      return;
    }
    if (minutes < 1 && seconds < 1) {
      setPopup({ type: 'alert', message: t('인증 번호 입력 유효시간이 지났어요. 새 인증번호 발송을 요청해 주세요.'), isOpen: true });
      resetAuth();
      setIsSent(false);
      setShowSendBtn(true);
      return;
    }
    if (user.nmFamily === '') {
      setError({ ...error, nmFamily: t("필수 입력 사항이에요.") });
      return;
    }
    if (user.nmFirst === '') {
      setError({ ...error, nmFirst: t("필수 입력 사항이에요.") });
      return;
    }
    const userData = {
      nmFamily: user.nmFamily,
      nmFirst: user.nmFirst,
      phoneNationNum: user.phoneNationNum,
      mPhoneNum: user.mPhoneNum,
      authCode: user.authCode,
      langCd: user.langCd
    }
    if (type === 'findid') {
      handleUserIdSearchByNmMPhone(userData);
    } else {
      handleConfirm_SMSauth(userData);
    }
  }

  // 인증번호 발송 버튼 핸들링
  useEffect(() => {
    if (type !== 'findid') {
      if (user.mPhoneNum.length < 10 || user.nmFamily === '' || user.nmFirst === '' || error.nmFirst !== '' || error.nmFirst !== '') {
        setShowSendBtn(false);
        return;
      }
      setShowSendBtn(true);
    }
  }, [user.nmFamily, user.nmFirst, user.mPhoneNum]);

  // 핸드폰 번호 변경시 초기화
  useEffect(() => {
    resetAuth();
    setIsSent(false);
    setUser({ ...user, authCode: '', isAuth: false });
    if (user.mPhoneNum.length < 10 || user.nmFamily === '' || user.nmFirst === '') {
      setShowSendBtn(false);
      return;
    }
    setShowSendBtn(true);
  }, [user.mPhoneNum]);

  // 아이디 찾기 눌렀을 때 인증 패스
  useEffect(() => {
    if (type === 'findid') {
      setIsSent(false);
      setShowSendBtn(false);
      setHideSendBtn(true);
    }
  }, [type]);

  // 다음 버튼 핸들링
  useEffect(() => {
    if (type === 'findid') {
      if (user.mPhoneNum.length > 9 && user.nmFamily !== '' && user.nmFirst !== '' && user.authCode.length > 3) {
        setShowSubmitBtn(true);
      } else {
        setShowSubmitBtn(false);
      }
      return;
    }
    if (user.nmFamily === '' || user.nmFirst === '' || user.mPhoneNum === '' || user.authCode.length < 4) {
      setShowSubmitBtn(false);
      return;
    }
    setShowSubmitBtn(true);
  }, [user.nmFamily, user.nmFirst, user.mPhoneNum, user.authCode]);
  
  // 안내 문구 핸들링
  useEffect(() => {
    if (user.mPhoneNum === '') {
      setDescript({ ...descript, nmFirst: '', nmFamily: '', mPhoneNum: t("휴대폰 번호 없이 숫자만 입력해 주세요.") });
    }
  }, [langCd]);

  // 고객센터 띄우기
  const goCustomer = () => {
    window.open('/customer/contactus');
  }

  // 스크롤 맨 아래로
  const scrollToBottom = () => {
    setTimeout(() => {
      wrapRef.current.scrollIntoView({ behavior: 'auto', block: 'end', inline: 'nearest' });
    }, 100);
  }

  return (
    <Container className='container' ref={wrapRef}>
      <div className="input-wrap">
        <input
          type="text"
          onBlur={onCheckLastname}
          onChange={onChangeLastname}
          value={user.nmFamily}
          placeholder={t("성")}
          tabIndex={langCd === 'ko' || langCd ==='ja' ? 1 : 2}
          disabled={findAfterReset}
        />
        <label>{t('성 입력')}</label>
        <p>
          {error.nmFamily && <span className='error'>{error.nmFamily}</span>}
        </p>
      </div>
      <div className={langCd === 'ko' || langCd ==='ja' ? 'input-wrap' : 'order-1 input-wrap'}>
        <input
          type="text"
          onBlur={onCheckFirstname}
          onChange={onChangeFirstname}
          value={user.nmFirst}
          placeholder={t("이름")}
          tabIndex={langCd === 'ko' || langCd ==='ja' ? 2 : 1}
          disabled={findAfterReset}
        />
        <label>{t('이름 입력')}</label>
        <p>
          {error.nmFirst && <span className='error'>{error.nmFirst}</span>}
        </p>
      </div>
      <Auth
        showSendBtn={showSendBtn}
        setShowSendBtn={setShowSendBtn}
        seconds={seconds}
        minutes={minutes}
        restart={restart}
        authResendRef={authResendRef}
        isSent={isSent}
        setIsSent={setIsSent}
        handleCallSMS={handleCallSMS}
        handleExtendTime={handleExtendTime}
        setShowSubmitBtn={setShowSubmitBtn}
        showSendMsg={showSendMsg}
        setShowSendMsg={setShowSendMsg}
        authResendCount={authResendCount}
        setAuthResendCount={setAuthResendCount}
        hideSendBtn={hideSendBtn}
        findAfterReset={findAfterReset}
        scrollToBottom={scrollToBottom}
      />
      {isSent &&
        <div className="customer">
          {popup.type === 'inconsistency'
            ?
              t("계정에 문제가 있으세요?")
            :
              t("인증번호를 못 받으셨나요?")
          }
          <button type='button' onClick={goCustomer}>{t("고객문의")}</button>
        </div>
      }
      <div className='btn-wrap show fixed'>
        {showSubmitBtn 
          ? <button type='button' className='btn lg green' onClick={onRegister}>{t("다음")}</button>
          : <button type='button' className='btn lg light-gray'>{t("다음")}</button>
        }
      </div>
    </Container>
  )
}

const Container = styled.div`
  .hide {
    position: absolute;
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
  }
  .show {
    position: relative;
    width: auto;
    height: auto;
    visibility: visible;
    opacity: 1;
  }
  .input-wrap {
    &.order-1 {
      order: -1;
    }
  }
  .btn-wrap {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .customer {
    padding-top: 10rem;
    padding-bottom: 7rem;
    text-align: center;
    color: #454545;
    button {
      margin-left: 0.5rem;
      font-weight: 500;
      text-decoration: underline;
    }
  }
`;

export default AuthHome