import styled from "styled-components";

const Toast = ({ page = '', type, message, onGetMore }) => {
  return (
    <Container className={page}>
      {type === 'more'
        ?
          <div className="box more" onClick={onGetMore}><span>{message}</span></div>
        :
          <div className="box">{message}</div>
      }
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  bottom: 2.4rem;
  left: 0;
  width: 100%;
  padding: 0 1.8rem;
  user-select: none;
  text-align: center;
  z-index: 29;
  &.all {
    width: auto;
    left: 50%;
    transform: translateX(-50%);
  }
  &.myroom {
    bottom: 7rem;
  }
  .box {
    display: block;
    padding: 0.6rem 1.5rem 0.7rem 1.5rem;
    font-size: 1.3rem;
    color: white;
    background-color: rgba(0,0,0,0.7);
    border-radius: 0.5rem;
    &.more {
      display: inline-block;
      border-radius: 3.5rem;
      cursor: pointer;
      span {
        padding-left: 1.5rem;
        background-image: url("${require('../../../assets/images/hwadap/icon_more.png')}");
        background-repeat: no-repeat;
        background-position: 0 center;
        background-size: 1.2rem;
      }
    }
  }
`;

export default Toast