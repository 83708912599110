import React from 'react';
import styled from "styled-components";

const Popup = ({children, hasClose, onClose}) => {
  return (
    <Container>
      <div className="inner-wrap">
        {hasClose &&
          <button type="button" className="close" onClick={onClose}>
            <span className="blind">닫기</span>
          </button>
        }
        {children}
      </div>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
  z-index: 21;
  .inner-wrap {
    position: relative;
    width: 82%;
    max-width: 31rem;
    padding: 3.5rem 3rem;
    background-color: #fff;
    border-radius: 2.1rem;
    .close {
      position: absolute;
      top: -3.4rem;
      right: 0;
      width: 2.9rem;
      height: 2.9rem;
      background-image: url("${require('../../../assets/images/community/icon_popup_close.png')}");
      background-size: 100%;
    }
  }
`;

export default Popup