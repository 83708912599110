import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import useCommonStore from '../../../store/site/common';
import client from '../../../lib/api/customer';
import Header from '../../../components/site/layout/Header';
import Policy from '../../../components/site/policy/Policy';
import Footer from '../../../components/site/layout/Footer';

const PolicyPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const langCd = useCommonStore((state) => state.langCd);
  const [policy, setPolicy] = useState([]);
  const [title, setTitle] = useState('');

  /** 약관 가져오기 */
  const getList = async (lang, policyTypeCd) => {
    try {
      const { data } = await client.post("/homepage/PolicyList", {
        langCd: lang,
        policyTypeCd,
      });
      setPolicy(data.content);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    let policyTypeCd = '';
    if (id === 'service') {
      policyTypeCd = "10";
      setTitle(t('이용약관'));
    } else if (id === 'privacy') {
      policyTypeCd = "20";
      setTitle(t('개인정보 처리방침'));
    }
    getList(langCd, policyTypeCd);
  }, [id, langCd]);

  return (
    <div className='site'>
      <Header title={t('약관 및 정책')} prev='close' />
      {(policy.length > 0) && <Policy policy={policy} title={title} />}
      <Footer />
    </div>
  )
}

export default PolicyPage