import { useEffect, useState } from "react";
import Parser from 'html-react-parser';
import Tab from "../layout/Tab";
import styled from 'styled-components';

const Policy = ({ policy, title }) => {
  const [content, setContent] = useState('');
  const category = [
    {
      id: 1,
      cd: "10",
      name: "이용약관",
    },
    {
      id: 2,
      cd: "20",
      name: "개인정보 처리방침",
    },
  ];

  /** 약관 날짜 변경 */
  const onChangePolicy = (e) => {
    const filterPolicy = policy.filter((i) => i.policyidx === parseInt(e.target.value));
    setContent(filterPolicy[0].policyContent);
  }

  useEffect(() => {
    setContent(policy[0].policyContent);
  }, [policy]);

  return (
    <Container>
      <h1 className="page-title">약관 및 정책</h1>
      <div className="box">
        <h1 className='title'>{title}</h1>
        <Tab category={category} />
        <div className="content">
          {Parser(content)}
        </div>
        <div className="select">
          <select onChange={onChangePolicy}>
            {policy.map((i) => (
              <option key={i.policyidx} value={i.policyidx}>
                {new Intl.DateTimeFormat("ko", { dateStyle: 'medium' }).format(new Date(i.startDt))}
              </option>
            ))}
          </select>
        </div>
      </div>
    </Container>
  )
}

const Container = styled.div`
  .box {
    height: calc(100vh - 5.2rem);
    padding: 2.8rem 1.9rem 5.8rem 1.9rem;
    background-color: #f9f9f9;
    overflow-y: auto;
    .title {
      font-size: 1.9rem;
      font-weight: 700;
    }
    .content {
      margin-top: 2.2rem;
      font-size: 1.4rem;
      color: #777;
    }
    .select {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 5.8rem;
      padding-right: 2rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background-color: #fff;
      select {
        width: 15rem;
        padding-left: 1rem;
        font-size: 1.4rem;
        color: #454545;
        border: 1px solid #E0E0E0;
        border-radius: 4px;
        background-position: right 0.5rem center;
      }
    }
  }
  @media screen and (min-width: 800px) {
    position: relative;
    max-width: 85rem;
    margin: 0 auto;
    padding-bottom: 6.5rem;
    margin-bottom: 5rem;
    .box {
      height: auto;
      padding: 0;
      background-color: white;
      .title {
        display: none;
      }
      .content {
        height: 56rem;
        padding: 2rem;
        border: 1px solid #DFDFDF;
        border-radius: 5px 5px 0 0;
        overflow-y: auto;
      }
      .select {
        position: absolute;
        bottom: 0;
        right: 0;
        width: auto;
        height: 6.6rem;
        border: 1px solid #DFDFDF;
        border-radius: 0 0 5px 5px;
      }
    }
  }
`;

export default Policy