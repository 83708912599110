import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Footer = () => {
  return (
    <Container>
      <div className="fnb">
        <ul>
          <li>
            <Link to='/policy/service'>이용약관</Link>
          </li>
          <li>
            <Link to='/policy/privacy' className="f-bold">개인정보 처리방침</Link>
          </li>
          {/* <li>
            <Link to=''>운영정책</Link>
          </li> */}
        </ul>
      </div>
      <div className="info">
        <p>(주)브레인월드코리아</p>
        <p>서울특별시 강남구 봉은사로 317 아모제논현빌딩 7층</p>
        <p>
          <span>TEL : 02-2016-3227</span>
          <span>FAX : 02-2016-3209</span>
          <span>대표이사 : 이갑성</span>
        </p>
        <p>
          <span>사업자등록번호 : 211-87-95938</span>
          <span>통신판매번호 : 강남-15481</span>
        </p>
        <p className="copyright">ⓒ Brainworld Korea All rights reserved.</p>
      </div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3rem 0 5rem 0;
  background-color: #f0f0f0;
  .fnb {
    ul {
      display: flex;
      align-items: center;
      li {
        position: relative;
        padding: 0 2rem;
        &:last-of-type::after {
          display: none;
        }
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: -1px;
          width: 1px;
          height: 1.7rem;
          margin-top: -0.85rem;
          background-color: #d9d9d9;
          z-index: 2;
        }
        a {
          font-size: 1.6rem;
          &.f-bold {
            font-weight: 700;
          }
        }
      }
    }
  }
  .info {
    margin-top: 2rem;
    p {
      margin-top: 0.2rem;
      font-size: 1.5rem;
      color: #666;
      text-align: center;
      &.copyright {
        margin-top: 0.7rem;
        color: #858585;
      }
      span {
        margin: 0 0.5rem;
      }
    }
  }
  @media screen and (min-width: 800px) {
  }
`;

export default Footer