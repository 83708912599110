import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Parser from 'html-react-parser';
import GoTop from '../layout/GoTop';
import styled from 'styled-components';

const HelpDetail = ({ helpDetail }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container className="content-wrap">
      <h1 className="page-title">{t('도움말')}</h1>
      <div className="board">
        <div className="info">
          <div className="subject">
            {helpDetail.title}
          </div>
        </div>
        <div className="content">
          {Parser(helpDetail.content)}
        </div>
      </div>
      <div className="btn">
        <button type='button' onClick={() => navigate(-1)}>목록</button>
      </div>
      <GoTop />
    </Container>
  )
}

const Container = styled.div`
  .board {
    border-top: 7px solid #f6f6f6;
    .info {
      padding: 1.8rem 1.9rem 2rem 1.9rem;
      border-bottom: 1px solid #ededed;
      .subject {
        font-size: 1.6rem;
        color: #222;
        line-height: 1.7rem;
      }
      .date {
        margin-top: 0.8rem;
        font-size: 1.4rem;
        color: #aaa;
        line-height: 1.7rem;
      }
    }
    .content {
      padding: 2.5rem;
      font-size: 1.5rem;
      letter-spacing: -0.6px;
    }
  }
  .btn {
    display: none;
  }
  @media screen and (min-width: 800px) {
    .board {
      margin-top: 2.8rem;
      border-top: 1px solid #222;
      border-bottom: 1px solid #ededed;
      .info {
        display: flex;
        align-items: center;
        padding: 2.8rem 2rem 3rem 1.5rem;
        .subject {
          width: 100%;
          padding-right: 3.5rem;
          font-size: 1.8rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .date {
          flex-shrink: 0;
        }
      }
      .content {
        height: 50rem;
        font-size: 1.8rem;
        overflow-y: auto;
      }
    }
    .btn {
      display: block;
      margin-top: 2rem;
      button {
        width: 10.4rem;
        font-size: 1.8rem;
        font-weight: 500;
        color: #1dc1ae;
        line-height: 3.7rem;
        border: 1px solid #00d2b4;
        border-radius: 1.9rem;
      }
    }
  }
`;

export default HelpDetail