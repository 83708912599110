import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import useCommonStore from '../../../store/site/common';
import useStore from '../../../store/site/store';
import client from '../../../lib/api/customer';
import Header from '../../../components/site/layout/Header';
import NoticeList from '../../../components/site/notice/NoticeList';
import Footer from '../../../components/site/layout/Footer';

const NoticeListPage = () => {
  const { t } = useTranslation();
  const langCd = useCommonStore((state) => state.langCd);
  const osTypeCd = useCommonStore((state) => state.osTypeCd);
  const user = useStore((state) => state.user);
  const notice = useStore((state) => state.notice);
  const setNotice = useStore((state) => state.setNotice);
  const lastNoticeIdx = useStore((state) => state.lastNoticeIdx);
  const setLastNoticeIdx = useStore((state) => state.setLastNoticeIdx);

  const getList = async (os='', initLastIdx) => {
    try {
      const { data } = await client.post("/homepage/NoticeList", {
        langCd,
        userId: user.userId,
        osTypeCd: os !== '' ? os : osTypeCd,
        lastNoticeIdx: initLastIdx ? "0" : lastNoticeIdx
      });
      if (data.content.length < 1) {
        return;
      }
      if (lastNoticeIdx !== "0" && os === '') {
        setNotice([...notice, ...data.content]);
      } else {
        setNotice(data.content);
      }
      setLastNoticeIdx(data.content[data.content.length - 1].noticeIdx);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (notice.length < 1) {
      getList();
    }
  }, [langCd]);

  return (
    <>
      <Helmet>
        {langCd === "ko"
          ? <title>Zero(지로) 공지사항</title>
          : <title>Zero - Notice</title>
        }
        <meta name="url" property="og:url" content="www.zerohsp.com/customer/notice" />
      </Helmet>
      <div className="site">
        <Header title={t('공지사항')} prev='close' />
        <NoticeList getList={getList} />
        <Footer />
      </div>
    </>
  )
}

export default NoticeListPage