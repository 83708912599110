import { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useStore from '../../../store/hwadap/store';
import Header from '../layout/Header';
import Message from './Message';
import Arrow from '../layout/Arrow';
import Navigation from './Navigation';
import styled from "styled-components";
import Write from './Write';

const Room = ({
    isLoading,
    toastTimerRef,
    setShowToast,
    setToastMessage,
    showWriteInput,
    setShowWriteInput,
    handleWrite,
    handleGetHwadapList,
    handleCopyHwadap,
    onDeleteHwadap,
    handleFileDownload
  }) => {
  const { t } = useTranslation();                                       // 다국어
  const location = useLocation()                                        // Location
  const navigate = useNavigate();                                       // navigate
  const lastSendIdx = useStore((state) => state.lastSendIdx);           // Get lastSendIdx
  const isScrolled = useStore((state) => state.isScrolled);             // Get isScrolled
  const setIsScrolled = useStore((state) => state.setIsScrolled);       // Set isScrolled
  const hwadap = useStore((state) => state.hwadap);                     // Get 화답 리스트
  const setHwadap = useStore((state) => state.setHwadap);               // Set 화답 리스트
  const showMoreBtn = useStore((state) => state.showMoreBtn);           // Get 10건 더보기
  const setShowMoreBtn = useStore((state) => state.setShowMoreBtn);     // Set 10건 더보기
  const roomScrollTop = useStore((state) => state.roomScrollTop);       // Get 스크롤 위치
  const writeId = useStore((state) => state.writeId);                   // Get 화답 작성 ID
  const setWriteId = useStore((state) => state.setWriteId);             // Set 화답 작성 ID
  const [beforeLastSendIdx, setBeforeLastSendIdx] = useState(0);        // 10건 더보기 전 lastSendIdx
  const wordID = useRef(1);                                             // 검색 단어용 id
  const [searchedWord, setSearchedWord] = useState('');                 // 검색된 단어
  const [isSearched, setIsSearched] = useState(false);                  // 검색 여부
  const [watchSearch, setWatchSearch] = useState(0);                    // 검색 Call 여부
  const roomRef = useRef();                                             // 전체 DIV Ref
  const writeWrapRef = useRef();                                        // 화답 작성 영역 Ref
  const [hwadooID, setHwadooID] = useState('');                         // 화답 작성하기 ID Ref
  const [channelUrl, setChannelUrl] = useState('');                     // 화답 channelUrl
  const setPopup = useStore((state) => state.setPopup);                 // Set Popup

  /** 10건 더보기 */
  const onGetHwadapListMore = () => {
    if (isSearched) {
      onSearchClear();
    }
    setBeforeLastSendIdx(lastSendIdx);
    handleGetHwadapList();
  }

  /** 화두 스크롤 이동 */
  const onScollToHwadoo = (direction, id, isFirst = false) => {
    hwadap.map((item, index) => {
      if (item.sendIdx === id) {
        let current = '';
        if (direction === 'up') {
          if (isFirst) {
            current = document.getElementById(`${hwadap[index].sendIdx}`);
          } else {
            current = document.getElementById(`${hwadap[index - 1].sendIdx}`);
          }
        } else if (direction === 'down') {
          current = document.getElementById(`${hwadap[index + 1].sendIdx}`);
        }
        window.scrollTo({
          behavior: 'smooth',
          top: current.offsetTop - 60
        });
      }
      return false;
    });
  }

  /** 화답하기 클릭 */
  const onShowWrite = (id) => {
    const currentHwadap = hwadap.filter((item) => (item.sendIdx === parseInt(id)));
    const today = new Date();
    const magamDay = new Date(currentHwadap[0].magamTimeZoneDtm);
    if (today > magamDay) {
      setPopup({ type: 'alert', message: t('화답 기간이 마감되었어요.'), isOpen: true });
      return;
    }
    setChannelUrl(currentHwadap[0].channelUrl);
    setHwadooID(id);
    setWriteId('');
    setShowWriteInput(true);
  }

  /** 화답 작성하기 */
  const onWrite = (text) => {
    handleWrite(channelUrl, hwadooID, text);
  }

  /** 화답 작성창 닫기 */
  const onCloseWirteInput = (e) => {
    if (showWriteInput && (!writeWrapRef.current || !writeWrapRef.current.contains(e.target))) {
      setHwadooID('');
      setShowWriteInput(false);
      setWriteId('');
    }
  }

  /** 검색 Replace */
  const onReplace = (item, word = '') => {
    if (word.length > 0) {
      const parts = item.split(new RegExp(`(?![^<]*>)(${word})`, 'gi'));
      const replace = parts.map((part) => {
        if (part.toLowerCase() === word.toLowerCase()) {
          return `<mark className='searched'>${part}</mark>`;
        } else {
          return part;
        }
      });
      return replace.join("");
    } else {
      return item.replace(/(<mark className='searched'>|<\/mark>)/g, "");
    }
  }

  /** 검색 초기화 공통 */
  const initSearch = () => {
    setIsSearched(false);
    const list = [...hwadap];
    const initData = list.map((i) => {
      if (i.messageLang === 'ko') {
        i.Hwadoo.title.title_ko = onReplace(i.Hwadoo.title.title_ko);
        i.Hwadoo.msgContent.msgContent_ko = onReplace(i.Hwadoo.msgContent.msgContent_ko);
      } else if (i.messageLang === 'en') {
        i.Hwadoo.title.title_en = onReplace(i.Hwadoo.title.title_en);
        i.Hwadoo.msgContent.msgContent_en = onReplace(i.Hwadoo.msgContent.msgContent_en);
      } else if (i.messageLang === 'ja') {
        i.Hwadoo.title.title_ja = onReplace(i.Hwadoo.title.title_ja);
        i.Hwadoo.msgContent.msgContent_ja = onReplace(i.Hwadoo.msgContent.msgContent_ja);
      }
      if (i.myHdap?.length > 0) {
        i.myHdap = i.myHdap.map((j) => {
          j.msgContent = onReplace(j.msgContent);
          return j;
        });
      }
      return i;
    });
    return initData;
  }

  /** 검색하기 */
  const onSearch = (word) => {
    if (word === searchedWord) {
      return;
    }
    const initData = initSearch();
    const filterData = initData.map((i) => {
      if (i.messageLang === 'ko') {
        i.Hwadoo.title.title_ko = onReplace(i.Hwadoo.title.title_ko, word)
        i.Hwadoo.msgContent.msgContent_ko = onReplace(i.Hwadoo.msgContent.msgContent_ko, word);
      } else if (i.messageLang === 'en') {
        i.Hwadoo.title.title_en = onReplace(i.Hwadoo.title.title_en, word);
        i.Hwadoo.msgContent.msgContent_en = onReplace(i.Hwadoo.msgContent.msgContent_en, word);
      } else if (i.messageLang === 'ja') {
        i.Hwadoo.title.title_ja = onReplace(i.Hwadoo.title.title_ja, word);
        i.Hwadoo.msgContent.msgContent_ja = onReplace(i.Hwadoo.msgContent.msgContent_ja, word);
      }
      if (i.myHdap?.length > 0) {
        i.myHdap = i.myHdap.map((j) => {
          j.msgContent = onReplace(j.msgContent, word);
          return j;
        });
      }
      return i;
    });
    setIsSearched(true);
    setHwadap(filterData);
    setSearchedWord(word);
  };

  /** 검색 초기화 */
  const onSearchClear = () => {
    const initData = initSearch();
    setHwadap(initData);
    setSearchedWord('');
    wordID.current = 1;
  };

  /** 검색 시 */
  useEffect(() => {
    let searched = document.querySelectorAll('.searched');
    if (isSearched && searched.length > 0) {
      for (let i = 0; i < searched.length; i++) {
        searched[i].setAttribute('id', 'mark-' + (searched.length - i));
      }
      document.getElementById('mark-1').scrollIntoView({ block: 'center' })
      document.getElementById('mark-1').setAttribute('class', 'searched active');
    }
    if (isSearched && searched.length < 1) {
      setToastMessage(t('검색 결과가 없어요.'));
      setShowToast(true);
      wordID.current = 0;
      setIsSearched(false);
      toastTimerRef.current = setTimeout(() => {
        setShowToast(false);
      }, 2000);
    }
  }, [hwadap, watchSearch]);
  
  /** 10건 더보기 후 스크롤 이동 */
  useEffect(() => {
    if (!isScrolled) {
      return;
    }
    if (beforeLastSendIdx === 0) {
      setBeforeLastSendIdx(lastSendIdx);
    }
    let top = 0;
    const moreScrollTimer = setInterval(() => {
      const refreshTop = document.getElementById(beforeLastSendIdx)?.offsetTop;
      if (top !== refreshTop) {
        top = refreshTop;
        window.scrollTo({ top: top - 70 });
      } else {
        clearInterval(moreScrollTimer);
      }
    }, 100);
  }, [lastSendIdx]);

  /** 마운트 후 스크롤 */
  useEffect(() => {
    if (!isLoading && hwadap.length > 0) {
      /** 해시 이동했을 경우 */
      if (location.hash) {
        let top = 0;
        const topScrollTimer = setInterval(() => {
          const refreshTop = document.getElementById(location.hash.replace('#', ''))?.offsetTop;
          if (top !== refreshTop) {
            top = refreshTop;
            window.scrollTo({ top: top - 70 });
          } else {
            clearInterval(topScrollTimer);
            navigate('/hwadap/room');
          }
        }, 100);
        return;
      }
      /** 처음 접속이거나 전체 목록에서 더보기 했을 경우 */
      if (!isScrolled || roomScrollTop === -1) {
        setIsScrolled(true);
        if (hwadap.length > 9) {
          setShowMoreBtn(true);
        }
        let height = 0;
        const firstScrollTimer = setInterval(() => {
          if (height !== roomRef.current?.clientHeight) {
            height = roomRef.current?.clientHeight;
            window.scrollTo({ top: height });
          } else {
            clearInterval(firstScrollTimer);
          }
        }, 100);
        return;
      } else if (roomScrollTop !== -1) {
        /** 뒤로가기로 왔을 경우 */
        const prevScrollTimer = setInterval(() => {
          if (window.scrollY !== roomScrollTop) {
            window.scrollTo({ top: roomScrollTop });
          } else {
            clearInterval(prevScrollTimer);
          }
        }, 100);
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (writeId !== '') {
      onShowWrite(writeId);
    }
  }, []);

  return (
    <Container ref={roomRef} onClick={onCloseWirteInput}>
      <Header
        prev='close'
        title='Ilchi Lee'
        page='myroom'
        onSearch={onSearch}
        onSearchClear={onSearchClear}
        isSearched={isSearched}
        setIsSearched={setIsSearched}
        watchSearch={watchSearch}
        setWatchSearch={setWatchSearch}
      />
      {hwadap &&
        <>
          {showMoreBtn &&
            <div className="more">
              <button type='button' onClick={onGetHwadapListMore}>{t('10건 더보기')}</button>
            </div>
          }
          <ul className='list'>
            {hwadap.map((item) => (
              <Message
                key={item.sendIdx}
                item={item}
                isSearched={isSearched}
                onShowWrite={onShowWrite}
                onScollToHwadoo={onScollToHwadoo}
                onDeleteHwadap={onDeleteHwadap}
                handleCopyHwadap={handleCopyHwadap}
                handleFileDownload={handleFileDownload}
              />
            ))}
          </ul>
        </>
      }
      {showWriteInput &&
        <Write
          writeWrapRef={writeWrapRef}
          onWrite={onWrite}
        />
      }
      <Arrow
        bottom={showWriteInput ? 8 : 6}
        toastTimerRef={toastTimerRef}
        setShowToast={setShowToast}
        setToastMessage={setToastMessage}
        isSearched={isSearched}
        roomRef={roomRef}
        wordID={wordID}
      />
      <Navigation />
    </Container>
  );
}

const Container = styled.div`
  padding-top: 5.02rem;
  padding-bottom: 3.5rem;
  .more {
    padding: 1rem 1.5rem 0 1.5rem;
    button {
      width: 100%;
      height: 3.2rem;
      font-size: 1.3rem;
      font-weight: 700;
      color: #31c5af;
      background-color: #effaf7;
      border: 1px solid #00d2b4;
      border-radius: 0.5rem;
    }
  }
  .list {
    padding: 1.6rem;
  }
`;

export default Room