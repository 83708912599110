import Header from '../../components/signup/Header';
import AgreeInfo from '../../components/signup/AgreeInfo'

const AgreeInfoPage = () => {  
  return (
    <div className='signup'>
      <Header prev='prev' />
      <AgreeInfo />
    </div>
  )
}

export default AgreeInfoPage