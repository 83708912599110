import { useState } from 'react';
import client from '../../lib/api/hwadap';
import useStore from '../../store/hwadap/store';
import AllList from '../../components/hwadap/all/List';
import Loader from '../../components/common/Loader';

const AllListPage = () => {
  const [isLoading, setIsLoading] = useState(false);                        // 로딩
  const user = useStore((state) => state.user);                             // Get 유저
  const hwadap = useStore((state) => state.hwadap);                         // Set 화답 리스트
  const setHwadap = useStore((state) => state.setHwadap);                   // Set 화답 리스트
  const setShowMoreBtn = useStore((state) => state.setShowMoreBtn);         // Set 10건 더보기
  const lastSendIdx = useStore((state) => state.lastSendIdx);               // Get lastSendIdx
  const setLastSendIdx = useStore((state) => state.setLastSendIdx);         // Set lastSendIdx
  const setRoomScrollTop = useStore((state) => state.setRoomScrollTop);     // Set roomScrollTop
  const [hwadapList, setHwadapList] = useState(hwadap);                     // 렌더링용 화답 리스트

  /** 화답 리스트 가져오기 */
  const handleGetHwadapList = async () => {
    try {
      const { data } = await client.post('/hwadap/hdMsgArea', {
        userid: user.userId,
        lastSendIdx: lastSendIdx
      });
      let currentDate = '';
      const filterData = data.content.map((item) => {
        let newItem = item.jsonString;
        newItem.messageLang = 'ko';
        newItem.Hwadoo.msgContent.msgContent_ko = '<h1>' + newItem.Hwadoo.title.title_ko + '</h1>' + newItem.Hwadoo.msgContent.msgContent_ko.replaceAll('contenteditable="false"', '');
        newItem.Hwadoo.msgContent.msgContent_en = '<h1>' + newItem.Hwadoo.title.title_en + '</h1>' + newItem.Hwadoo.msgContent.msgContent_en.replaceAll('contenteditable="false"', '');
        newItem.Hwadoo.msgContent.msgContent_ja = '<h1>' + newItem.Hwadoo.title.title_ja + '</h1>' + newItem.Hwadoo.msgContent.msgContent_ja.replaceAll('contenteditable="false"', '');

        const date = new Intl.DateTimeFormat("ko-KR", {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }).format(new Date(newItem.sendTimeZoneDtm));
        
        if (currentDate === date) {
          newItem.created = '';
        } else {
          currentDate = date;
          newItem.created = newItem.sendTimeZoneDtm;
        }
        return newItem;
      });
      if (data.content.length > 0) {
        setLastSendIdx(filterData[0].sendIdx);
      }
      if (data.content.length < 10) {
        setShowMoreBtn(false);
      }
      if (hwadap.length > 0) {
        setHwadap([...filterData, ...hwadap]);
        const list = [...hwadap];
        setHwadapList([...list.reverse(), ...filterData.reverse()]);
      } else {
        setHwadap(filterData);
      }
      setIsLoading(false);
      setRoomScrollTop(-1);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  }

  return (
    <div className="hwadap">
      {isLoading && <Loader />}
      <AllList hwadapList={hwadapList} setHwadapList={setHwadapList} handleGetHwadapList={handleGetHwadapList} />
    </div>
  )
}

export default AllListPage