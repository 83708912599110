import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useStore from "../../../store/signup/store";
import styled from 'styled-components';

const Birthday = ({
    guardianAgree,
    setGuardianAgree,
    handleCertification
  }) => {
  const { t } = useTranslation();                                                                // 다국어
  const user = useStore((state) => state.user);                                                  // Get 유저
  const setUser = useStore((state) => state.setUser);                                            // Set 유저
  const error = useStore((state) => state.error);                                                // Get 에러문구
  const setError = useStore((state) => state.setError);                                          // Set 에러문구
  const descript = useStore((state) => state.descript);                                          // Get 안내문구
  const setDescript = useStore((state) => state.setDescript);                                    // Set 안내문구
  const birthdayYearRef = useRef('');                                                            // 생년 Ref
  const birthdayMonthRef = useRef('');                                                           // 생월 Ref
  const birthdayDayRef = useRef('');                                                             // 생일 Ref
  const isKorea = user.phoneNationNum === '82' ? true : false;                                   // 한국인지?
  const isAsia = (user.phoneNationNum === '82' || user.phoneNationNum === '81') ? true : false;  // 한국+일본인지?

  // 생년
  const onChangeBirthdayYear = (e) => {
    if (e.target.value.length > 4){
      e.target.value = e.value.slice(0, 4);
    }
    setUser({ ...user, birthday: { ...user.birthday, year: e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1') } })
  }

  // 생월
  const onChangeBirthdayMonth = (e) => {
    if (e.target.value.length > 2){
      e.target.value = e.value.slice(0, 2);
    }
    setUser({ ...user, birthday: { ...user.birthday, month: e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1') } })
  }

  // 생일
  const onChangeBirthdayDay = (e) => {
    if (parseInt(user.birthday.month) === 2) {
      if (e.target.value > 30) {
        return;
      }
    }
    if (parseInt(user.birthday.month) === 11) {
      if (e.target.value > 30) {
        return;
      }
    }
    if (e.target.value.length > 2){
      e.target.value = e.value.slice(0, 2);
    }
    setUser({ ...user, birthday: { ...user.birthday, day: e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1') } })
  }

  // 생년월일 핸들링
  useEffect(() => {
    setError({ ...error, birthday: '' });
    if (user.birthday.month > 12) {
      setError({ ...error, birthday: t('생년월일 형식에 맞게 입력해주세요.') });
      return;
    }
    if (user.birthday.day > 31) {
      setError({ ...error, birthday: t('생년월일 형식에 맞게 입력해주세요.') });
      return;
    }
    // 포커스 자동 넘기기
    if (isAsia) {
      if (user.birthday.year.length > 3 && user.birthday.month.length === 0) {
        birthdayMonthRef.current.focus();
      }
      if (user.birthday.year.length > 3 && user.birthday.month.length > 1 && user.birthday.day.length === 0) {
        birthdayDayRef.current.focus();
      }
    } else {
      if (user.birthday.month.length > 1 && user.birthday.day.length === 0) {
        birthdayDayRef.current.focus();
      }
      if (user.birthday.month.length > 1 && user.birthday.day.length > 1 &&  user.birthday.year.length === 0) {
        birthdayYearRef.current.focus();
      }
    }
    if (user.birthday.year !== '' && user.birthday.month !== '' && user.birthday.day !== '') {
      checkAge(user.birthday.year, user.birthday.month, user.birthday.day);
    }
  }, [user.birthday]);

  // 나이 체크
  const checkAge = (year, month, day) => {
    const today = new Date();
    const birthDate = new Date(year, month, day);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    
    if (new Date(year, month-1, day).getTime() > today.getTime()) {
      setError({ ...error, birthday: t("생년월일이 잘못 입력되었습니다. 다시 확인해 주세요.") });
      return;
    }
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (isKorea) {
      if (age < 14) {
        setError({ ...error, birthday: t("14세 미만 회원가입은 보호자의 가입동의 휴대폰 본인 인증이 필요해요.") });
        setUser({ ...user, showAuth: true });
        return true;
      } else {
        setError({ ...error, birthday: '' });
        setUser({ ...user, showAuth: false });
        return false;
      }
    } else {
      if (age < 16) {
        setError({ ...error, birthday: t("16세 이상만 회원가입이 가능해요.생년월일을 다시 확인해 주세요.") });
        return true;
      } else {
        setError({ ...error, birthday: '' });
        return false;
      }
    }
  }

  // 키패드 닫기
  const onHideKeypad = (key) => {
    if (key.keyCode == 13) {
      key.preventDefault();
      birthdayYearRef.current.blur();
      birthdayMonthRef.current.blur();
      birthdayDayRef.current.blur();
    }
  }

  // 보호자 가입 동의
  const onChangeGuardianAgree = (e) => {
    if (user.guardiansNm.length > 0) {
      setError({ ...error, birthday: '' });
    }
    setGuardianAgree(e.target.checked);
  }

  // 보호자 본인 인증
  const onCertification = () => {
    handleCertification();
  }

  // 지톡 아이디로 회원가입일 경우 이메일, 생년월일 자동 기입
  useEffect(() => {
    if (user.birthDt !== '') {
      const date = new Date(user.birthDt.substring(0, 4), user.birthDt.substring(4, 6), user.birthDt.substring(6, 8));
      const year = date.getFullYear();
      const month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
      const day = date.getDate();
      setUser({ ...user, birthday: { ...user.birthday, year, month, day } })
    }
  }, []);

  return (
    <>
      <SelectWrap className="select-wrap">
        <div className="wrap">
          <div className={isAsia ? 'order-1' : 'order-3'}>
            <input
              type="number"
              pattern="[0-9]*"
              ref={birthdayYearRef}
              onChange={onChangeBirthdayYear}
              onKeyDown={onHideKeypad}
              value={user.birthday.year}
              placeholder={t("년")}
              tabIndex={isAsia ? 2 : 4}
              disabled={user.guardiansNm !== ''}
            />
            <label>{t('년')}</label>
          </div>
          <div className={isAsia ? 'order-2' : 'order-1'}>
            <input
              type="number"
              pattern="[0-9]*"
              ref={birthdayMonthRef}
              onChange={onChangeBirthdayMonth}
              onKeyDown={onHideKeypad}
              value={user.birthday.month}
              placeholder={t("월")}
              tabIndex={isAsia ? 3 : 2}
              disabled={user.guardiansNm !== ''}
            />
            <label>{t('월')}</label>
          </div>
          <div className={isAsia ? 'order-3' : 'order-2'}>
            <input
              type="number"
              pattern="[0-9]*"
              ref={birthdayDayRef}
              onChange={onChangeBirthdayDay}
              onKeyDown={onHideKeypad}
              value={user.birthday.day}
              placeholder={t("일")}
              tabIndex={isAsia ? 4 : 3}
              disabled={user.guardiansNm !== ''}
            />
            <label>{t('일')}</label>
          </div>
        </div>
        <p>
          {descript.birthday && <span>{descript.birthday}</span>}
          {error.birthday && <span className='error'>{error.birthday}</span>}
        </p>
      </SelectWrap>
      {user.showAuth &&
        <AuthWrap className="auth-wrap">
          <div className="btn-box">
            <button type='button' className='btn md gray round' onClick={onCertification}>보호자 가입동의 휴대폰 본인 인증</button>
          </div>
          {user.guardiansNm &&
            <div className="form-box">
              <input
                type="text"
                value={user.guardiansNm}
                readOnly
              />
            </div>
          }
          {user.guardiansMphoneNum &&
            <div className="form-box">
              <input
                type="text"
                value={user.guardiansMphoneNum}
                readOnly
              />
            </div>
          }
          {user.guardiansNm &&
            <div className="check-box">
              <input type="checkbox" value={guardianAgree} onChange={onChangeGuardianAgree} id='guardianAgree' />
              <label htmlFor="guardianAgree">{t("해당 회원의 보호자(부모님, 법정 대리인)로 가입에 동의합니다.")}</label>
            </div>
          }
        </AuthWrap>
      }
    </>
  )
}

const SelectWrap = styled.div`
  .wrap {
    display: flex;
    div {
      position: relative;
      &.order-1 {
        order: 1;
      }
      &.order-2 {
        order: 2;
        margin: 0 1.35rem;
      }
      &.order-3 {
        order: 3;
      }
    }
  }
`;

const AuthWrap = styled.div`
  .btn-box {
    margin-bottom: 1.5rem;
  }
  .form-box {
    display: flex;
    margin-bottom: 1rem;
    input[type='text'] {
      padding-left: 1rem;
      border-bottom: 0;
    }
  }
  .check-box {
    label {
      font-size: 1.35rem;
    }
  }
`;

export default Birthday