import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import axios from "axios";
import client from '../../../lib/api/customer';
import uuid from 'react-uuid';
import useCommonStore from '../../../store/site/common';
import useStore from '../../../store/site/store';
import Header from '../../../components/site/layout/Header';
import ContactUsForm from '../../../components/site/contact/ContactUsForm';
import Footer from '../../../components/site/layout/Footer';
import Alert from "../../../components/site/layout/Alert";

const ContactUsFormPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const categoryNum = searchParams.get('category') ?? '';
  const langCd = useCommonStore((state) => state.langCd);
  const osTypeCd = useCommonStore((state) => state.osTypeCd);
  const isWeb = useCommonStore((state) => state.isWeb);
  const setFirstContact = useStore((state) => state.setFirstContact);
  const alert = useStore((state) => state.alert);
  const [fileList, setFileList] = useState([]);
  const [category, setCategory] = useState('');
  const [policy, setPolicy] = useState('');
  const [nationList, setNationList] = useState([]);
  const [ipAddress, setIpAddress] = useState('');
  const [totalFileSize, setTotalFileSize] = useState(0);

  /** 먼저 체크 리스트 */
  const getCheckList = async (category) => {
    try {
      const { data } = await client.post("/homepage/NoticeHelpList", {
        langCd,
        inquiryCategoryCd: category
      });
      setFirstContact(data.content);
    } catch (e) {
      console.log(e);
    }
  }

  /** 국게전화코드 리스트 */
  const getNationList = async () => {
    try {
      const { data } = await client.get('/member/nationList');
      setNationList(data.content);
    } catch (e) {
      console.log(e);
    }
  }

  /** 약관 */
  const getPolicy = async () => {
    try {
      const { data } = await client.post('/member/userPolicyView', {
        langCd,
        policyTypeCd: "30"
      });
      setPolicy(data.content.policyContent);
    } catch (e) {
      console.log(e);
    }
  }

  /** IP주소 */
  const getIpAddress = async () => {
    try {
      const { data } = await axios.get('https://api64.ipify.org?format=json');
      setIpAddress(data.ip);
    } catch (e) {
      console.log(e);
    }
  }

  /** 파일 첨부 */
  const handleFileUpload = async (file) => {
    try {
      if (file) {
        let formData = new FormData();
        formData.append("file1", file);
        const config = {
          header: { 'content-type' : 'multipart/form-data' }
        }
        const { data } = await client.post('/file/upload', formData, config );

        console.log(data);

        let isImage = false;
        let extension = data.content.fileNm.slice(data.content.fileNm.indexOf(".") + 1).toLowerCase(); 
        if (extension === "jpg" || extension === "png" || extension === "jpeg" || extension === "gif" || extension === "bmp"){ 
          isImage = true;
        }
        const item = {
          id: uuid(),
          fileNm: data.content.fileNm,
          filePath: data.content.filePath,
          downPath: data.content.downPath,
          fileSize: data.content.fileSize,
          oriFileNm: data.content.oriFileNm,
          size: file.size,
          src: isImage ? data.content.filePath + data.content.fileNm : '',
        };
        setFileList((prev) => [...prev, item]);
        setTotalFileSize((prev) => prev + file.size);
      }
    } catch (e) {
      console.log(e);
    }
  }

  /** 파일 삭제 */
  const handleFileDelete = async (id, filename) => {
    setFileList((prev) => prev.filter((i) => i.id !== id));
    setTotalFileSize((prev) => prev - fileList.filter((i) => i.id === id)[0].size);
    try {
      await client.post('/file/unlink', { filename });
    } catch (e) {
      console.log(e);
    }
  }

  /** 문의하기 */
  const handleInquiryInsert = async (contactData) => {
    try {
      const stringData = JSON.stringify(contactData);
      const { data } = await client.post('/homepage/InquiryInsert', { jsonString: stringData });
      
      console.log(data);

      navigate('/');

      // 성공
      // setAlert({
      //   type: 'contact_complete',
      //   isOpen: true,
      //   message: t('문의하신 내용이 접수되었어요.'),
      //   btn: t('확인')
      // });

      // 5분 에러
      // setAlert({
      //   isOpen: true,
      //   message: t('문의하신 내용이 접수되었어요.'),
      //   btn: t('닫기')
      // })
    } catch (e) {
      console.log(e);
    }
  }
  
  /** Init */
  useEffect(() => {
    getCheckList(categoryNum);
    setCategory(categoryNum);
    getNationList();
    getPolicy();
    if (isWeb && (osTypeCd === 'win' || osTypeCd === 'mac')) {
      getIpAddress();
    }
  }, []);

  return (
    <>
      <Helmet>
        {langCd === "ko"
          ? <title>Zero(지로) 문의하기</title>
          : <title>Zero - Contact Us</title>
        }
        <meta name="url" property="og:url" content={`www.zerohsp.com/customer/form?category=${categoryNum}`} />
      </Helmet>
      <div className="site">
        <Header title={t('문의하기')} prev='back' page="detail" />
        <ContactUsForm
          category={category}
          nationList={nationList}
          fileList={fileList}
          totalFileSize={totalFileSize}
          setTotalFileSize={setTotalFileSize}
          ipAddress={ipAddress}
          policy={policy}
          handleFileUpload={handleFileUpload}
          handleFileDelete={handleFileDelete}
          handleInquiryInsert={handleInquiryInsert}
        />
        <Footer />
        {alert.isOpen && <Alert />}
      </div>
    </>
  )
}

export default ContactUsFormPage