import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import useCommonStore from '../../../store/site/common';
import useStore from '../../../store/site/store';
import moment from "moment";
import GoTop from '../layout/GoTop';
import Tab from "../layout/Tab";
import styled from 'styled-components';
import "moment/locale/ko";
import "moment/locale/en-au";
import "moment/locale/ja";

const NoticeList = ({ getList }) => {
  const timeRef = useRef(null);
  const wrapRef = useRef(null);
  const langCd = useCommonStore((state) => state.langCd);
  const notice = useStore((state) => state.notice);
  const [showGoTop, setShowGoTop] = useState(false);
  const category = [
    {
      id: 1,
      cd: 'and',
      name: "Android",
    },
    {
      id: 2,
      cd: 'ios',
      name: "iOS",
    },
    {
      id: 3,
      cd: 'win',
      name: "Window PC",
    },
    {
      id: 4,
      cd: 'mac',
      name: "Mac",
    },
  ];

  const onScrollTop = () => {
    wrapRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleScroll = (e) => {
    if (wrapRef === null) {
      return;
    }
    if (wrapRef.current.scrollTop > 10) {
      setShowGoTop(true);
    } else {
      setShowGoTop(false);
    }
    if (Math.round(Math.round(wrapRef.current.scrollTop)) >= (wrapRef.current.clientHeight + window.outerHeight)) {
      if (!timeRef.current) {
        timeRef.current = setTimeout(function() {
          timeRef.current = null;
          getList();
        }, 500);
      }
    }
  }

  useEffect(() => {
    langCd === 'ko'
    ? moment.locale("ko")
    : langCd === 'ja'
      ? moment.locale("ja")
      : moment.locale("en-au")
    handleScroll();
  }, []);

  return (
    <Container className="content-wrap">
      <h1 className="page-title">공지사항</h1>
      <Tab category={category} getList={getList} />
      <div className="list" ref={wrapRef} onScroll={handleScroll}>
        <ul>
          {(notice.length > 0) && notice.map((i) => (
            <li key={i.noticeIdx} className={i.isTop ? 'fixed' : ''}>
              <Link to={`/customer/notice/${i.noticeIdx}`}>
                <div className='title'>
                  {(i.title.length > 50) ? i.title.substr(0, 50) + '...' : i.title}
                  {i.isNew === "1" &&
                    <img src={require("../../../assets/images/customer/icon_new.png")} className='icon-new' alt="" />
                  }
                </div>
                <div className="date">
                  {moment(i.regDtm).format(' YYYY. MM. DD')}
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <GoTop showGoTop={showGoTop} onScrollTop={onScrollTop} />
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  .visible {
    display: block;
  }
  .hide {
    display: none;
  }
  .list {
    max-height: calc(100vh - 5.2rem);
    border-top: 7px solid #F2F2F2;
    overflow-y: auto;
    ul {
      display: flex;
      flex-direction: column;
      li {
        order: 1;
        &.fixed {
          order: 0;
          a {
            background-color: #f0fffd;
          }
        }
        a {
          display: block;
          padding: 1.8rem 1.9rem 2rem 1.9rem;
          background-color: #fff;
          border-bottom: 1px solid #ededed;
          .title {
            font-size: 1.6rem;
            color: #222;
          }
          .date {
            margin-top: 0.8rem;
            font-size: 1.4rem;
            line-height: 1.7rem;
            color: #aaa;
          }
        }
      }
    }
  }
  @media screen and (min-width: 800px) {
    .list {
      max-height: 48rem;
      border-top: 1px solid #222;
      overflow-y: auto;
      ul {
        li {
          a {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 2.8rem 2rem 3rem 1.5rem;
            .title {
              width: 100%;
              padding-right: 3.5rem;
              font-size: 1.8rem;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            .date {
              flex-shrink: 0;
            }
          }
        }
      }
    }
  }
`;

export default NoticeList