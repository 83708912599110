import axios from "axios";

const { REACT_APP_API_URI } = process.env;

let csrfToken = '';

const client = axios.create({
  baseURL: REACT_APP_API_URI,
  // headers: {
  //   '_csrf-token': csrfToken
  // }
  // withCredentials: true,
});

client.interceptors.request.use(
  (config) => {
    // 보내기 전에 할 일
    return config;
  },
  (error) => {
    // 요청 실패 시
    return Promise.reject(error);
  }
  );

client.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // if (error.response.status === 401) {
    //   await axios
    //     .get("/refresh-token", {
    //       withCredentials: true,
    //     })
    //     .catch((err) => {
    //       return Promise.reject(err);
    //     });
    //   console.log(error.config);
    //   return axios(error.config);
    // } else {
    //   return Promise.reject(error);
    // }
  }
);

export const setCSRFToken = (token) => {
  csrfToken = token;
}

export default client;
