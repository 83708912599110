import Header from '../../components/signup/Header';
import FindResult from '../../components/signup/FindResult';

const FindResultPage = () => {
  return (
    <div className='signup'>
      <Header prev='prev' />
      <FindResult />
    </div>
  )
}

export default FindResultPage