import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useStore from "../../store/signup/store";
import Loader from '../../components/common/Loader';
import { useState } from 'react';

const AuthenticationCallbackaPage = () => {
  const navigate = useNavigate();
  const setUser = useStore((state) => state.setUser);
  const setPolicy = useStore((state) => state.setPolicy);
  const Name = new URL(unescape(window.location.href)).searchParams.get("Name") ?? '';
  const cellNo = new URL(unescape(window.location.href)).searchParams.get("cellNo") ?? '';

  const getCookie = (name) => {
    const value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
      return value ? value[2] : null;
  };

  const deleteCookie = (name) => {
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/"
  }

  const [u, setU] = useState();
  const [test, setTest] = useState('test');

  useEffect(() => {
    const commonCookie = JSON.parse(getCookie("earthtalkCommon"));
    const nationCd = commonCookie.nationCd ?? '';
    setUser({ ...JSON.parse(getCookie("earthtalkUser")), guardiansNm: Name, guardiansMphoneNum: cellNo });
    setPolicy(JSON.parse(getCookie("earthtalkPolicy")));
    setU(getCookie("earthtalkUser"));

    deleteCookie('earthtalkCommon');
    deleteCookie('earthtalkUser');
    deleteCookie('earthtalkPolicy');
    navigate(`/signup/register?langcd=ko&nationcd=${nationCd}&auth=true`);
  }, []);

  return (
    <>
      <Loader />
    {/* 오류 확인중입니다.
    {u}
    <br />
    {test} */}
    </>
  )
}

export default AuthenticationCallbackaPage