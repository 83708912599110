import styled from "styled-components";

const Media = ({ author, mediaUrl, mediaCaption, setShowMedia }) => {
  return (
    <Container>
      <div className="header">
        <button type='button' className='close' onClick={() => setShowMedia(false)}>
          <span className="blind">닫기</span>
        </button>
        <div className="author">{author}</div>
      </div>
      <div className="video-wrap">
        <iframe allowFullScreen={true} src={mediaUrl}></iframe>
      </div>
      <div className="caption">{mediaCaption}</div>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #000;
  z-index: 21;
  .header {
    position: relative;
    display: flex;
    align-items: center;
    height: 5.02rem;
    padding: 0 1.55rem;
    background-color: #000;
    z-index: 9;
    .close {
      width: 2.9rem;
      height: 2.9rem;
      margin-right: 1.2rem;
      background-image: url("${require('../../../assets/images/hwadap/icon_close_image_slide.png')}");
      background-size: 100%;
    }
    .author {
      font-size: 1.9rem;
      font-weight: 700;
      color: white;
    }
  }
  .video-wrap {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .caption {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 7rem;
    padding: 0 1.9rem;
    font-size: 1.6rem;
    font-weight: 500;
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9;
  }
`;

export default Media