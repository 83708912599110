import { useNavigate } from "react-router-dom";
import useCommonStore from '../../../store/site/common';
import useStore from "../../../store/site/store";
import Parser from 'html-react-parser';
import styled from 'styled-components';

const Alert = () => {
  const navigate = useNavigate();
  const isWeb = useCommonStore((state) => state.isWeb);
  const alert = useStore((state) => state.alert);
  const clearAlert = useStore((state) => state.clearAlert);

  const closeContact = () => {
    if (isWeb) {
      navigate('/');
    } else {
      alert('웹뷰 닫기');
      window.close();
    }    
  }
  
  return (
    <Container>
      <div className="contents">
        <p>{Parser(alert.message)}</p>
        <div className="btns">
          {alert.type === 'contact_complete'
            ? <button type='button' onClick={closeContact}>{alert.btn}</button>
            : <button type='button' onClick={() => clearAlert()}>{alert.btn}</button>
          }
        </div>
      </div>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.5);
  z-index: 21;
  .contents {
    position: relative;
    width: 31rem;
    max-width: 90%;
    padding: 3rem;
    text-align: center;
    background-color: #fff;
    border-radius: 2.1rem;
    p {
      font-size: 1.7rem;
    }
    .btns {
      margin-top: 2.6rem;
      button {
        width: 100%;
        height: 4.75rem;
        font-size: 1.6rem;
        font-weight: 700;
        color: white;
        background-color: #00D2B4;
        border-radius: 2.5rem;
      }
    }
  }
`;

export default Alert;