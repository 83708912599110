import { useEffect, useState } from 'react';
import Parser from 'html-react-parser';
import useCommonStore from '../../../store/common/store';
import useStore from '../../../store/hwadap/store';
import styled, { keyframes } from 'styled-components';
import moment from "moment";
import "moment/locale/ko";
import "moment/locale/en-au";
import "moment/locale/ja";

const Message = ({ message }) => {
  const langCd = useCommonStore((state) => state.langCd);
  const isPC = useCommonStore((state) => state.isPC);
  const user = useStore((state) => state.user);
  const [date, setDate] = useState('');

  useEffect(() => {
    langCd === 'ko'
    ? moment.locale("ko")
    : langCd === 'ja'
      ? moment.locale("ja")
      : moment.locale("en-au")

    const today = new Date();
    const regDtm = new Date(message.regDtm);
    const todayFull = today.getFullYear() + today.getMonth() + today.getDate();
    const regDtmFull = regDtm.getFullYear() + regDtm.getMonth() + regDtm.getDate();
    if (todayFull === regDtmFull) {
      setDate(
        langCd === "ko" || langCd === 'ja'
          ? moment(regDtm).format(' a HH:mm')
          : moment(regDtm).format(' HH:mm A')
      );
    } else {
      setDate(
        langCd === "ko" || langCd === 'ja'
          ? moment(regDtm).format(' YYYY/ MM/ DD, a HH:mm')
          : moment(regDtm).format(' MM/ DD/ YYYY, HH:mm A')
      );
    }    
  }, []);

  return (
    <Container className={
        isPC
        ? message.userId === user.userId ? 'pc me' : 'pc other'
        : message.userId === user.userId ? 'me' : 'other'
      }>
      {message.created &&
        <div className='date-bar'>
          <span>
            {langCd === "ko" || langCd === 'ja'
              ? moment(message.created).format('LL dddd')
              : moment(message.created).format('dddd, MMMM DD, YYYY')
            }
          </span>
        </div>
      }
      <div className="profile">
        <div className="avatar">
          <img
            src={
              (message.profileImgPath && message.profileImg)
                ? message.profileImgPath + message.profileImg
                : message.profileImgPath
                  ? message.profileImgPath
                  : require("../../../assets/images/hwadap/avatar_default.png")
            }
            async
            alt=""
          />
        </div>
        {message.userId !== user.userId && 
          <div className="name">{message.nmFull}</div>
        }
        <div className="date">{date}</div>
      </div>
      <div className='content'>
        <div className="box">
          {Parser(message.msgContent.replaceAll("\n", "<br/>"))}
        </div>
      </div>
    </Container>
  )
}

const searched = keyframes`
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
`;

const Container = styled.div`
  padding: 1rem 1.5rem;
  word-break: break-all;
  &.pc {
    .date-bar {
      span {
        font-size: 1.4rem;
        line-height: 3.8rem;
        border-radius: 1.9rem;
      }
    }
    &.me {
      .profile {
        .name {
          display: block;
          margin-right: 0;
          margin-left: 0.6rem;
          order: 2;
        }
      }
    }
    &.other {
      .profile {
        .avatar {
          width: 5rem;
          height: 5rem;
        }
        .name, .date {
          padding-bottom: 0.5rem;
        }
      }
      .content {
        margin-left: 6rem;
        transform: translateY(-1.2rem);
      }
    }
  }
  .date-bar {
    position: relative;
    margin-top: 1rem;
    margin-bottom: 2.3rem;
    text-align: center;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #ededed;
      z-index: -1;
    }
    span {
      display: inline-block;
      padding: 0 1.5rem;
      font-size: 1.3rem;
      color: #999;
      line-height: 3.2rem;
      background-color: #fff;
      border: 1px solid #ededed;
      border-radius: 1.6rem;
    }
  }
  .searched {
    display: inline-block;
    &.active {
      animation: ${searched} 0.35s;
    }
  }
  &.me {
    .profile {
      justify-content: flex-end;
      .avatar, .name {
        display: none;
      }
    }
    .content {
      justify-content: flex-end;
      transform: translateY(0.8rem);
      .box {
        color: white;
        background-color: #30887d;
        border-radius: 1.5rem 0 1.5rem 1.5rem;
        .searched {
          background-color: #fff;
          color: #30887d;
        }
      }
    }
  }
  &.other {
    .content {
      margin-left: 4.85rem;
      transform: translateY(-0.3rem);
      .box {
        color: #222;
        background-color: #f2f2f2;
        border-radius: 0 1.5rem 1.5rem 1.5rem;
        .searched {
          background-color: #222;
          color: white;
        }
      }
    }
  }
  .profile {
    display: flex;
    align-items: center;
    .avatar {
      margin-right: 1rem;
      width: 3.65rem;
      height: 3.65rem;
      transform: translateY(0.8rem);
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .name {
      margin-right: 0.65rem;
      font-size: 1.5rem;
      font-weight: 500;
      color: #454545;
      line-height: 1;
    }
    .date {
      font-size: 1.2rem;
      font-weight: 500;
      color: #999999;
      line-height: 1;
    }
  }
  .content {
    display: flex;
    .box {
      max-width: 25.8rem;
      padding: 1.1rem 1.8rem 1.4rem 1.8rem;
      font-size: 1.6rem;
      &::selection {
        background-color: rgba(0, 0, 0, 0.25);
      }
    }
  }
`;

export default Message