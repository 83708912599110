import { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import useStore from "../../store/signup/store";

const Password = ({ scrollToBottom }) => {
  const { t } = useTranslation();                                              // 다국어
  const user = useStore((state) => state.user);                                // Get 유저
  const setUser = useStore((state) => state.setUser);                          // Set 유저
  const error = useStore((state) => state.error);                              // Get 에러문구
  const setError = useStore((state) => state.setError);                        // Set 에러문구
  const descript = useStore((state) => state.descript);                        // Get 안내문구
  const setDescript = useStore((state) => state.setDescript);                  // Set 안내문구
  const [showPassword, setShowPassword] = useState(false);                     // 비밀번호 보기 토글
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);       // 비밀번호 확인 보기 토글
  const passwordRef = useRef('');                                              // 비밀번호 Ref
  const confirmPasswordRef = useRef('');                                       // 비밀번호 확인 Ref

  // 비밀번호
  const onChangePassword = (e) => {
    if (e.target.value.length > 19){
      e.target.value = e.value.slice(0, 19);
    }
    setUser({ ...user, password: e.target.value });
    setError({ ...error, password: '', confirmPassword: '' });
  }

  // 비밀번호 체크
  const onCheckPassword = (e) => {
    const value = e.target.value;
    const blank_pattern_all = /^\s+|\s+$/g;
    const blank_pattern = /[\s]/g;    
    const lowercase_pattern = /[a-z]/g;
    const number_pattern = /[0-9]/g;
    const consecutive_pattern =  /(.)\1\1\1/;

    if (value.length < 1) {
      setError({ ...error, password: t("필수 입력 사항이에요.") });
      return;
    }
    if (value.length < 8) {
      setError({ ...error, password: t("8자 이상 입력해 주세요.") });
      return;
    }
    if (value.length > 20) {
      setError({ ...error, password: t("20자 이하로 입력해 주세요.") });
      return;
    }
    if (lowercase_pattern.test(value) === false) {
      setError({ ...error, password: t("영문 소문자를 포함해서 입력해 주세요.") });
      return;
    }
    if (number_pattern.test(value) === false) {
      setError({ ...error, password: t("숫자를 포함해서 입력해 주세요.") });
      return;
    }
    if (value.replace(blank_pattern_all, '' ) === "" || blank_pattern.test(value) === true) {
      setError({ ...error, password: t("공백은 사용할 수 없어요.") });
      return;
    }
    if (consecutive_pattern.test(value) === true) {
      setError({ ...error, password: t("3자리 이상 반복적인 패스워드는 사용 할 수 없습니다.") });
      return;
    }
    setError({ ...error, password: '' });
    if (user.confirmPassword.length < 1) {
      setDescript({ ...descript, confirmPassword: t("비밀번호를 한번 더 입력해 주세요.") });
    }
  }

  // 비밀번호 보이기/숨기기
  const onChangeShowPassword = () => {
    if (showPassword) {
      passwordRef.current.type = 'password'
    } else {
      passwordRef.current.type = 'text'
    }
    setShowPassword(!showPassword);
  }

  // 비밀번호 확인
  const onChangeConfirmPassword = (e) => {
    if (e.target.value.length > 19){
      e.target.value = e.value.slice(0, 19);
    }
    setUser({ ...user, confirmPassword: e.target.value });
    if (user.password !== e.target.value) {
      setError({ ...error, confirmPassword: t("비밀번호가 일치하지 않아요.") });
    } else {
      setError({ ...error, confirmPassword: '' });
    }
  }

  // 비밀번호 확인 보이기/숨기기
  const onChangeShowConfirmPassword = () => {
    if (showConfirmPassword) {
      confirmPasswordRef.current.type = 'password'
    } else {
      confirmPasswordRef.current.type = 'text'
    }
    setShowConfirmPassword(!showConfirmPassword);
  }

  // 키패드 닫기
  const onHideKeypad = (key) => {
    if (key.keyCode == 13) {
      key.preventDefault();
      confirmPasswordRef.current.blur();
    }
  }

  return (
    <>
      <div className="input-wrap">
        <input
          type="password"
          ref={passwordRef}
          onFocus={scrollToBottom}
          onChange={onChangePassword}
          onBlur={onCheckPassword}
          value={user.password}
          placeholder={t('비밀번호')}
        />
        <label>{t('비밀번호 입력')}</label>
        <button type='button' onClick={onChangeShowPassword} className={showPassword ? 'eye hide' : 'eye show'}>비밀번호 보기/숨기기</button>
        <p>
          {descript.password && <span>{descript.password}</span>}
          {error.password && <span className='error'>{error.password}</span>}
        </p>
      </div>
      <div className="input-wrap">
        <input
          type="password"
          ref={confirmPasswordRef}
          onFocus={scrollToBottom}
          onChange={onChangeConfirmPassword}
          onKeyDown={onHideKeypad}
          value={user.confirmPassword}
          placeholder={t('비밀번호 확인')}
        />
        <label>{t('비밀번호 확인 입력')}</label>
        <button type='button' onClick={onChangeShowConfirmPassword} className={showConfirmPassword ? 'eye hide' : 'eye show'}>비밀번호 확인 보기/숨기기</button>
        <p>
          {error.confirmPassword && <span className='error'>{error.confirmPassword}</span>}
        </p>
      </div>
    </>
  )
}

export default Password