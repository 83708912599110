import { useRef } from "react";
import { useTranslation } from 'react-i18next';
import useCommonStore from '../../../store/common/store';
import useStore from '../../../store/hwadap/store';
import styled from "styled-components"

const Incomplete = ({ handleAddFriend, showIncomplete, setShowIncomplete }) => {
  const { t } = useTranslation();
  const isPC = useCommonStore((state) => state.isPC);
  const user = useStore((state) => state.user);
  const incomplete = useStore((state) => state.incomplete);
  const IncompleteRef = useRef();

  /** 미완료자 리스트 창 닫기 */
  const onClosePopoup = (e) => {
    if (showIncomplete && (!IncompleteRef.current || !IncompleteRef.current.contains(e.target))) {
      setShowIncomplete(false);
    }
  }

  return (
    <Container onClick={onClosePopoup} className={isPC && 'pc'}>
      <div className="inner-wrap" ref={IncompleteRef}>
        <h1>{t('화답 미완료자')}</h1>
        <button type="button" className="close" onClick={() => setShowIncomplete(false)}>
          <span className="blind">닫기</span>
        </button>
        <ul className="list">
          {incomplete.map((i) => (
            <li key={i.userId} className={i.userId === user.userId ? 'order-1' : ''}>
              <div className={(i.isFriend || i.userId === user.userId) ? "avatar" : "avatar unkown" }>
                <img
                  src={
                    (i.profileImgPath && i.profileImg)
                      ? i.profileImgPath + i.profileImg
                      : i.profileImgPath
                        ? i.profileImgPath
                        : require("../../../assets/images/hwadap/avatar_default.png")
                  }
                  async
                  alt=""
                />
              </div>
              <div className={i.userId === user.userId ? "name me" : "name"}>
                <span>{i.nmFull}</span>
              </div>
              {(!i.isFriend && i.userId !== user.userId) &&
                <button type="button" className="add-friend" onClick={() => handleAddFriend(i.userId)}>
                  <span className="blind">친구추가</span>
                </button>
              }
            </li>
          ))}
        </ul>
      </div>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 19;
  &.pc {
    align-items: center;
    justify-content: center;
    .inner-wrap {
      width: 40rem;
      height: 53rem;
      padding-top: 1.5rem;
      padding-left: 0;
      padding-right: 0;
      border-radius: 1.4rem;
      h1 {
        padding: 1.8rem;
        font-size: 2.2rem;
        font-weight: 500;
      }
      .close {
        display: block;
        position: absolute;
        top: 1.2rem;
        right: 1.2rem;
        width: 2rem;
        height: 2rem;
        background-image: url("${require('../../../assets/images/hwadap/icon_incomplete_close.png')}");
        border-radius: 50%;
        &:hover {
          background-color: rgba(0, 0, 0, 0.1);;
        }
      }
      .list {
        margin-right: 0.5rem;
        padding: 1rem 1.8rem;
        height: 45rem;
        li {
          padding: 1rem 0;
          &:hover {
            background-color: rgba(0, 0, 0, 0.03);
          }
          .avatar {
            width: 5rem;
            height: 5rem;
          }
          .name {
            font-size: 1.5rem;
          }
          .add-friend {
            width: 4.6rem;
            height: 2.8rem;
          }
        }
      }
    }
  }
  .inner-wrap {
    position: relative;
    width: 30rem;
    padding: 2.75rem 0;
    background-color: #fff;
    h1 {
      margin: 0 2rem;
      padding-bottom: 1.8rem;
      font-size: 1.9rem;
      line-height: 1;
      color: #222;
      border-bottom: 1px solid #ededed;
    }
    .close {
      display: none;
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 1rem 2rem;
      max-height: calc(100vh - 7rem);
      overflow-y: auto;
      li {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0.75rem 0;
        order: 2;
        &.order-1 {
          order: 1;
        }
        .avatar {
          width: 4rem;
          height: 4rem;
          margin-right: 1rem;
          border-radius: 50%;
          flex-shrink: 0;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          &.unkown {
            position: relative;
            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-image: url("${require('../../../assets/images/hwadap/icon_unkown.png')}");
              background-color: rgba(0, 0, 0, 0.4);
              background-repeat: no-repeat;
              background-size: 1.45rem;
              background-position: center;
            }
          }
        }
        .name {
          position: relative;
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 1;
          color: #222;
          &.me span {
            padding-right: 1.9rem;
            &::after {
              content: "me";
              position: absolute;
              top: 0;
              right: 0;
              width: 1.6rem;
              height: 1.6rem;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 0.8rem;
              font-weight: 700;
              color: white;
              background-color: #5e6979;
              border-radius: 50%;
            }
          }
        }
        .add-friend {
          margin-left: auto;
          width: 4.2rem;
          height: 2.7rem;
          background-color: #f3f3f3;
          background-image: url("${require('../../../assets/images/hwadap/icon_add_friend.png')}");
          background-repeat: no-repeat;
          background-size: 2.7rem;
          background-position: center;
          border-radius: 0.5rem;
          flex-shrink: 0;
        }
      }
    }
  }
`;

export default Incomplete