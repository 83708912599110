import { useState, useEffect, useRef } from 'react'
import { useParams } from "react-router-dom";
import client from '../../lib/api/hwadap';
import { useTranslation } from 'react-i18next';
import useStore from '../../store/hwadap/store';
import GroupRoom from '../../components/hwadap/group/GroupRoom';
import Incomplete from '../../components/hwadap/group/Incomplete';
import Toast from '../../components/hwadap/layout/Toast';
import Loader from '../../components/common/Loader';

const GroupRoomPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);                 // 로딩
  const group = useStore((state) => state.group);                   // Get 그룹
  const setGroup = useStore((state) => state.setGroup);             // Set 그룹
  const incomplete = useStore((state) => state.incomplete);         // Get 화답 미완료자
  const setIncomplete = useStore((state) => state.setIncomplete);   // Set 화답 미완료자
  const [showIncomplete, setShowIncomplete] = useState(false);      // 화답 미완료자 보기 토글
  const [showToast, setShowToast] = useState(false);                // 토스트 토글
  const [toastMessage, setToastMessage] = useState('');             // 토스트 메세지
  const toastTimerRef = useRef(null);                               // 토스트 메세지 타이머

  /** 친구 추가하기 */
  const handleAddFriend = (id) => {
    try {
      // 네이티브 친구 추가 함수 호출
      const filterComplete = incomplete.map((i) => {
        if (id === i.userId) {
          return { ...i, isFriend: true }
        }
        return i;
      });
      setIncomplete(filterComplete);
      setToastMessage(t('친구 목록에 추가되었어요.'));
      setShowToast(true);
      toastTimerRef.current = setTimeout(() => {
        setShowToast(false);
      }, 2000);
    } catch (e) {
      console.log(e);
    }
  }

  /** 화답 미완료자 가져오기 */
  const handleGetIncomplete = async (userId, sendIdx, channelUrl) => {
    console.log(userId, sendIdx, channelUrl)
    try {
      const { data } = await client.post('/hwadap/hdNoAns', { userId, sendIdx, channelUrl });
      setIncomplete(data.content);
      setShowIncomplete(true);
      console.log(data)
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  }

  /** 메세지 리스트 가져오기 */
  const handleGetMessageList = async () => {
    setIsLoading(true);
    try {
      const { data } = await client.post('/hwadap/hdapStatusList', { sendIdx: id, channelUrl: group.channelUrl });
      let currentDate = '';
      const filterData = data.content.hdapList.map((i) => {
        const date = new Intl.DateTimeFormat("ko-KR", {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }).format(new Date(i.regDtm));

        console.log('date', date);
        console.log('current', currentDate);
        if (currentDate === date) {
          i.created = '';
        } else {
          currentDate = date;
          i.created = i.regDtm;
        }
        return i;
      });
      setGroup({
        ...group,
        sendIdx: id,
        magamTimeZoneDtm: data.content.magamTimeZoneDtm,
        rcvCnt: data.content.rcvCnt,
        ansCnt: data.content.ansCnt,
        ansRate: data.content.ansRate,
        Dday: data.content.Dday,
        hdapList: filterData
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  }

  /** Init + Clear */
  useEffect(() => {
    handleGetMessageList();
    return () => {
      clearTimeout(toastTimerRef.current);
    }
  }, []);

  return (
    <div className='hwadap'>
      {isLoading
      ?
        <Loader />
      :
        <GroupRoom
          isLoading={isLoading}
          toastTimerRef={toastTimerRef}
          setShowToast={setShowToast}
          setToastMessage={setToastMessage}
          handleGetIncomplete={handleGetIncomplete}
        />
      }
      {showIncomplete &&
        <Incomplete
          showIncomplete={showIncomplete}
          setShowIncomplete={setShowIncomplete}
          handleAddFriend={handleAddFriend}
        />
      }
      <div className={showToast ? 'toast active' : 'toast'}>
        <Toast page='myroom' message={toastMessage} />
      </div>
    </div>
  )
}

export default GroupRoomPage