import { useNavigate } from "react-router-dom";
import useStore from "../../store/signup/store";
import useCommonStore from "../../store/common/store";
import styled from 'styled-components';

const Header = ({ prev, title, onClosePopup }) => {
  const navigate = useNavigate();
  const langCd = useCommonStore((state) => state.langCd);
  const nationCd = useCommonStore((state) => state.nationCd);
  const setPopup = useStore((state) => state.setPopup);

  const handlePrev = () => {
    if (prev === 'close') {
      const agent = navigator.userAgent.toLowerCase();
      if (agent.indexOf('android') > -1) {
        window.Android.viewClose();
      } else if (agent.indexOf("iphone") > -1 || agent.indexOf("ipad") > -1 || agent.indexOf("ipod") > -1 || agent.indexOf("apple") > -1) {
        window.webkit.messageHandlers.viewClose.postMessage("1");
      } else {
        setPopup({ type: 'alert', message: 'No native APIs found.', isOpen: true });
      }
    } else if (prev === 'prev') {
      navigate(-1);
    } else if (prev === 'home') {
      navigate(`/signup/auth/normal?langcd=${langCd}&nationcd=${nationCd}`);
    } else if (prev === 'closePopup') {
      onClosePopup();
    }
  }

  return (
    <Container>
      <button type='button' onClick={handlePrev}>
        <img src={require('../../assets/images/signup/icon_prev.png')} alt="Go backwards" />
      </button>
      <h1>{title}</h1>
    </Container>
  )
}

const Container = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 5.2rem;
  padding: 0 1.55rem;
  button {
    margin-right: auto;
    img {
      display: block;
      height: 2.9rem;
    }
  }
  h1 {
    margin-right: auto;
    padding: 1rem 2.9rem 1rem 0;
    font-size: 1.7rem;
    font-weight: bold;
    color: #222;
    text-align: center;
  }
`;

export default Header