import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import useCommonStore from '../../../store/site/common';
import useStore from '../../../store/site/store';
import client from '../../../lib/api/customer';
import Header from '../../../components/site/layout/Header';
import HelpList from '../../../components/site/help/HelpList';
import Footer from '../../../components/site/layout/Footer';

const HelpListPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const search = searchParams.get('search') ?? '';
  const from = searchParams.get('from') ?? '';
  const langCd = useCommonStore((state) => state.langCd);
  const osTypeCd = useCommonStore((state) => state.osTypeCd);
  const help = useStore((state) => state.help);
  const setHelp = useStore((state) => state.setHelp);
  const lastHelpIdx = useStore((state) => state.lastHelpIdx);
  const setLastHelpIdx = useStore((state) => state.setLastHelpIdx);
  const wrapRef = useRef(null);
  const [searchWord, setSearchWord] = useState('');

  const getList = async (os='', initLastIdx) => {
    try {
      const { data } = await client.post("/homepage/HelpList", {
        langCd,
        srchKey: search,
        osTypeCd: os !== '' ? os : osTypeCd,
        lastNoticeIdx: initLastIdx ? "0" : lastHelpIdx
      });
      if (data.content.length < 1) {
        return;
      }
      if (lastHelpIdx !== "0" && os === '') {
        setHelp([...help, ...data.content]);
      } else {
        setHelp(data.content);
      }
      setLastHelpIdx(data.content[data.content.length - 1].noticeIdx);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (!search) {
      setSearchWord('');
    }
    if (help.length < 1) {
      getList();
    }
  }, [search]);

  return (
    <>
      <Helmet>
        {langCd === "ko"
          ? <title>Zero(지로) 도움말</title>
          : <title>Zero - Help</title>
        }
        <meta name="url" property="og:url" content="www.zerohsp.com/customer/help" />
      </Helmet>
      <div className="site" ref={wrapRef}>
        <Header title={t('도움말')} prev={search ? 'helplist' : from ? 'back' : 'close'} />
        <HelpList search={search} searchWord={searchWord} setSearchWord={setSearchWord} getList={getList} />
        <Footer />
      </div>
    </>
  )
}

export default HelpListPage