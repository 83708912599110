import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useCommonStore from '../../../store/common/store';
import Parser from 'html-react-parser';
import styled from "styled-components";
import moment from "moment";
import "moment/locale/ko";
import "moment/locale/en-au";
import "moment/locale/ja";

const MyHwadap = ({ item, myHdap, handleCopyHwadap, onDeleteHwadap }) => {
  const { t } = useTranslation();
  const langCd = useCommonStore((state) => state.langCd);

  useEffect(() => {
    langCd === 'ko'
    ? moment.locale("ko")
    : langCd === 'ja'
      ? moment.locale("ja")
      : moment.locale("en-au")
  }, []);

  return (
    <Container>
      <div className="info">
        <button type='button' className='delete' onClick={() => onDeleteHwadap(item.sendIdx, myHdap.idx)}>{t('삭제')}</button>
        <div className="date">
          {langCd === "ko" || langCd === 'ja'
              ? moment(item.magamTimeZoneDtm).format('MM/ DD, A HH:mm')
              : moment(item.magamTimeZoneDtm).format('MM/ DD, HH:mm A')
            }
        </div>
      </div>
      <div className="message">
        <button type='button' className='copy' onClick={() => handleCopyHwadap(myHdap.idx)}>
          <span className="blind">복사</span>
        </button>
        <div className="message-box" id={`message-${myHdap.idx}`}>{Parser(myHdap.msgContent.replaceAll("\n", "<br/>"))}</div>
      </div>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 2.3rem;
  text-align: right;
  .info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .delete {
      margin-right: 0.6rem;
      padding-left: 1.35rem;
      font-size: 1.2rem;
      font-weight: 500;
      color: #999;
      flex-shrink: 0;
      background-image: url("${require('../../../assets/images/hwadap/icon_delete.png')}");
      background-repeat: no-repeat;
      background-position: 0 center;
      background-size: 1.3rem;
    }
    .date {
      font-size: 1.2rem;
      font-weight: 500;
      color: #999;
    }
  }
  .message {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    .searched {
      background-color: #fff;
      color: #30887d;
    }
    .copy {
      width: 2rem;
      height: 2rem;
      background-image: url("${require('../../../assets/images/hwadap/icon_copy.png')}");
      background-position: 0 center;
      background-size: 100%;
      flex-shrink: 0;
    }
    .message-box {
      display: inline-block;
      margin-top: 0.5rem;
      max-width: 29.3rem;
      padding: 1.2rem 1.8rem;
      font-size: 1.6rem;
      font-weight: 500;
      color: #fff;
      text-align: left;
      background-color: #30887d;
      border-radius: 1.5rem 0 1.5rem 1.5rem;
    }
  }
`;

export default MyHwadap