import React, { useState, useEffect } from 'react'
import useCommonStore from "../../store/common/store";
import useStore from "../../store/signup/store";
import { useTranslation } from 'react-i18next';
import PolicyDetail from './PolicyDetail';
import styled from 'styled-components';

const PolicyAgree = ({ policyDetailContent, setPolicyDetailContent, setOpenPolicyAgree, handleGetPolicyDetail }) => {
  const { t } = useTranslation();                                        // 다국어
  const langCd = useCommonStore((state) => state.langCd);                // Get 언어
  const user = useStore((state) => state.user);                          // Get 유저
  const setUser = useStore((state) => state.setUser);                    // Set 유저
  const policy = useStore((state) => state.policy);                      // Get 약관
  const setPolicy = useStore((state) => state.setPolicy);                // Set 약관
  const [showNextBtn, setShowNextBtn] = useState(false);                 // 다음 버튼
  const [openPolicyDetail, setOpenPolicyDetail] = useState(false);       // 약관 상세 토글
  const [checkedList, setCheckedLists] = useState([]);                   // 동의한 리스트
  const [checkAll, setCheckAll] = useState(false);                       // 모두 동의

  // 전체 체크 클릭
  const onCheckedAll = (checked) => {
    if (checked) {
      const checkedListArray = [];

      policy.forEach((i) => checkedListArray.push(i.policyTypeCd));

      checkedListArray.push('SMS');
      checkedListArray.push('Email');

      const newPolicy = policy.map((p) => {
        p.isAgree = true;
        return p;
      });
      setPolicy(newPolicy);

      setCheckedLists(checkedListArray);
    } else {
      const newPolicy = policy.map((p) => {
        p.isAgree = false;
        return p;
      });
      setPolicy(newPolicy);

      setCheckedLists([]);
    }
  };

  // 개별 체크 클릭
  const onCheckedElement = (checked, policyTypeCd) => {
    if (checked) {
      if (policyTypeCd === '60') {
        setCheckedLists([...checkedList, '60', 'SMS', 'Email']);
      } else if (policyTypeCd === 'SMS') {
        if (!checkedList.includes('Email')) {
          setCheckedLists([...checkedList, '60', 'SMS']);
        } else {
          setCheckedLists([...checkedList, policyTypeCd]);
        }
      } else if (policyTypeCd === 'Email') {
        if (!checkedList.includes('SMS')) {
          setCheckedLists([...checkedList, '60', 'Email']);
        } else {
          setCheckedLists([...checkedList, policyTypeCd]);
        }
      } else {
        setCheckedLists([...checkedList, policyTypeCd]);
      }

      if (policyTypeCd === '60' || policyTypeCd === 'SMS' || policyTypeCd === 'Email') {
        const newPolicy = policy.map((p) => {
          if (p.policyTypeCd === '60') {
            return {...p, isAgree: true}
          }
          return p;
        });
        setPolicy(newPolicy);
      } else {
        const newPolicy = policy.map((p) => {
          if (p.policyTypeCd === policyTypeCd) {
            return {...p, isAgree: true}
          }
          return p;
        });
        setPolicy(newPolicy);
      }
    } else {
      if (policyTypeCd === '60') {
        setCheckedLists(checkedList.filter((el) => el !== '60' && el !== 'SMS' && el !== 'Email'));
      } else if (policyTypeCd === 'SMS') {
        if (!checkedList.includes('Email')) {
          setCheckedLists(checkedList.filter((el) => el !== '60' && el !== 'SMS' && el !== 'Email'));
        } else {
          setCheckedLists(checkedList.filter((el) => el !== policyTypeCd));
        }
      } else if (policyTypeCd === 'Email') {
        if (!checkedList.includes('SMS')) {
          setCheckedLists(checkedList.filter((el) => el !== '60' && el !== 'SMS' && el !== 'Email'));
        } else {
          setCheckedLists(checkedList.filter((el) => el !== policyTypeCd));
        }
      } else {
        setCheckedLists(checkedList.filter((el) => el !== policyTypeCd));
      }

      if (policyTypeCd === '60' || policyTypeCd === 'SMS' || policyTypeCd === 'Email') {
        const newPolicy = policy.map((p) => {
          if (p.policyTypeCd === '60') {
            return {...p, isAgree: false}
          }
          return p;
        });
        setPolicy(newPolicy);
      } else {
        const newPolicy = policy.map((p) => {
          if (p.policyTypeCd === policyTypeCd) {
            return {...p, isAgree: false}
          }
          return p;
        });
        setPolicy(newPolicy);
      }
    }
  }

  // 내용 전체보기
  const openDetail = (policyTypeCd) => {
    handleGetPolicyDetail(policyTypeCd);
    setOpenPolicyDetail(true);
  }

  // 다음 버튼
  const onNext = () => {
    setOpenPolicyAgree(false);
    setUser({ ...user, isAgreeMarketingSmsMsg: checkedList.includes('SMS'), isAgreeMarketingEmail: checkedList.includes('Email') });
  }

  // 모두 동의하기 체크박스 핸들링
  useEffect(() => {
    const essendCount = policy.filter((p) => p.essenOrSelectGbnCd === '10');
    const checkCount = policy.filter((p) => p.essenOrSelectGbnCd === '10' && p.isAgree === true);
    if (essendCount.length === checkCount.length) {
      setShowNextBtn(true);
    } else {
      setShowNextBtn(false);
    }

    const arr = policy.map((p) => p.policyTypeCd);
    if (arr.every(r=> checkedList.includes(r))) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  }, [checkedList]);

  return (
    <Container>
      <div className="content">
        <div className="check-wrap all">
          <input
            type="checkbox"
            id='checkAll'
            onChange={(e) => onCheckedAll(e.target.checked)}
            checked={checkAll}
          />
          <label htmlFor="checkAll" className='no-line'><strong>{t("모두 동의합니다.")}</strong></label>
        </div>
        {policy.map((i) => (
          <React.Fragment  key={i.policyTypeCd}>
            <div className="check-wrap bg">
              <input
                type="checkbox"
                id={'policy-' + i.policyTypeCd}
                onChange={(e) => onCheckedElement(e.target.checked, i.policyTypeCd)}
                checked={checkedList.includes(i.policyTypeCd) ? true : false}
              />
              <label htmlFor={'policy-' + i.policyTypeCd}>&#40;{i.essenOrSelectGbnCdNm}&#41; {i.policyTypeNm}</label>
              <button type='button' className='detail-btn' onClick={() => openDetail(i.policyTypeCd)}>약관 상세 보기</button>
            </div>
            {i.policyTypeCd === '60' &&
            <div className="check-wrap bg padding">
              <hr />
              {langCd !== 'ko' &&
                <h3>{t("마케팅 정보 수신 동의")}</h3>
              }
              <input
                type="checkbox"
                id='SMS'
                onChange={(e) => onCheckedElement(e.target.checked, 'SMS')}
                checked={checkedList.includes('SMS') ? true : false}
              />
              <label htmlFor='SMS'>{t("문자")}</label>
              <input
                type="checkbox"
                id='Email'
                onChange={(e) => onCheckedElement(e.target.checked, 'Email')}
                checked={checkedList.includes('Email') ? true : false}
              />
              <label htmlFor='Email'>{t("이메일")}</label>
            </div>
            }
          </React.Fragment>
        ))}

        <div className="btn-wrap">
          {showNextBtn
            ? <button type='button' className='btn lg green' onClick={onNext}>{t("다음")}</button>
            : <button type='button' className='btn lg gray'>{t("다음")}</button>
          }
        </div>
      </div>
      {openPolicyDetail && policyDetailContent &&
        <PolicyDetail
          policyDetailContent={policyDetailContent}
          setPolicyDetailContent={setPolicyDetailContent}
          setOpenPolicyDetail={setOpenPolicyDetail}
          onCheckedElement={onCheckedElement}
        />
      }
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  .content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2.65rem 2.55rem 7.1rem 2.45rem;
    background-color: #fff;
    border-radius: 2.1rem 2.1rem 0 0;
    .check-wrap {
      position: relative;
      margin-bottom: 1rem;
      &.all {
        margin-bottom: 2.65rem;
      }
      &.bg {
        padding: 1.3rem 0 1.3rem 1.5rem;
        background-color: #f9f9f9;
        border-radius: 7px;
      }
      &.padding {
        padding: 0 2.35rem 1.75rem 4.65rem;
        margin-top: -1rem;
        hr {
          margin: 0 0 1.45rem 0;
          border-top: 1px solid #ededed;
        }
        h3 {
          font-size: 1.4rem;
          color: #222;
        }
        label {
          margin-top: 0.65rem;
          padding-left: 3rem;
          margin-right: 2.5rem;
          text-decoration: none;
        }
      }
      label {
        color: #666;
        text-decoration: underline;
      }
      .detail-btn {
        position: absolute;
        right: 0;
        top: 0;
        width: calc(100% - 6rem);
        height: 100%;
        font-size: 0;
      }
    }
    .btn-wrap {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }
`;

export default PolicyAgree