import { useTranslation } from 'react-i18next';
import useStore from '../../../store/hwadap/store';
import styled from 'styled-components';

const Popup = ({ handleDeleteHwadap }) => {
  const { t } = useTranslation();
  const popup = useStore((state) => state.popup);
  const setClearPopup = useStore((state) => state.setClearPopup);

  return (
    <Container>
      {popup.type === 'alert' &&
        <div className="contents">
          <button type='button' className='close' onClick={() => setClearPopup()}>
            <span className="blind">닫기</span>
          </button>
          <p>{popup.message}</p>
          <div className="btns">
            <button type='button' className='btn gray full' onClick={() => setClearPopup()}>{t('닫기')}</button>
          </div>
        </div>
      }
      {popup.type === 'deleteHwadap' &&
        <div className="contents">
          <button type='button' className='close' onClick={() => setClearPopup()}>
            <span className="blind">닫기</span>
          </button>
          <p>{t('화답을 삭제하시겠어요?')}</p>
          <div className="btns">
            <button type='button' className='btn gray' onClick={() => setClearPopup()}>{t('취소')}</button>
            <button type='button' className='btn mint' onClick={() => handleDeleteHwadap()}>{t('확인')}</button>
          </div>
        </div>
      }
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 9;
  .contents {
    position: relative;
    width: 82.66%;
    padding: 3rem;
    max-width: 31rem;
    background-color: white;
    border-radius: 2.1rem;
    .close {
      position: absolute;
      top: -3.4rem;
      right: 0;
      width: 2.9rem;
      height: 2.9rem;
      background-image: url("${require('../../../assets/images/hwadap/icon_close_popup.png')}");
      background-size: 100%;
    }
    p {
      font-size: 1.7rem;
      text-align: center;
    }
    .btns {
      margin-top: 1.5rem;
      display: flex;
      justify-content: space-between;
      button {
        width: calc(50% - 0.5rem);
        height: 4.8rem;
        max-width: 25.5rem;
        font-size: 1.6rem;
        font-weight: 700;
        border-radius: 2.4rem;
        &.full {
          width: 100%;
          max-width: 20rem;
          margin: 0 auto;
        }
        &.mint {
          color: white;
          background-color: #00d2b4;
        }
        &.gray {
          color: white;
          background-color: #888;
        }
      }
    }
  }
`;

export default Popup