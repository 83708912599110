import React from 'react';
import styled from 'styled-components';

const Home = () => {
  return (
    <Container>
      <section className="section section-1">
        <div className="wrap if-pc">
          <img src={require("../../assets/images/site/con_home_section_1.png")} alt="" />
        </div>
      </section>
      <section className="section section-2">
        <div className="wrap if-pc">
          <p>전세계 친구를 만날 수 있는</p>
          <h1>실시간 메신저</h1>
          <img src={require("../../assets/images/site/con_home_section_2.png")} alt="" />
        </div>
      </section>
      <section className="section section-3">
        <div className="wrap if-pc">
          <p>원터치로 편리한 대화</p>
          <h1>보이스 채팅</h1>
          <img src={require("../../assets/images/site/con_home_section_3.png")} alt="" />
        </div>
      </section>
      <section className="section section-4">
        <div className="wrap if-pc">
          <p>생생하게 주고 받는</p>
          <h1>영상·음성 통화</h1>
          <img src={require("../../assets/images/site/con_home_section_4.png")} alt="" />
        </div>
      </section>
      <section className="section section-5">
        <div className="wrap if-pc">
          <p>공생 라이프로 하나되는</p>
          <h1>글로벌 커뮤니티, Z타운</h1>
          <img src={require("../../assets/images/site/con_home_section_5.png")} alt="" />
        </div>
      </section>
      <section className="section section-6">
        <div className="wrap if-pc">
          <p>마음과 정성이 통한다</p>
          <h1>선물가게</h1>
          <img src={require("../../assets/images/site/con_home_section_6.png")} alt="" />
        </div>
      </section>
      <section className="section section-7">
        <div className="wrap if-pc">
          <p>밝아지고 넓어지는</p>
          <h1>공생 오디오</h1>
          <img src={require("../../assets/images/site/con_home_section_7.png")} alt="" />
        </div>
      </section>
      <section className="section section-8">
        <div className="wrap if-pc">
          <p>마음과 에너지를 더해주는</p>
          <h1>이모티콘</h1>
          <img src={require("../../assets/images/site/con_home_section_8.png")} alt="" />
        </div>
      </section>
    </Container>
  )
};

const Container = styled.div`
  .section {
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center 0;
    height: 100vh;
    &.section-1 {
      margin-top: -5rem;
      background-image: url("${require('../../assets/images/site/bg_home_section_1_m.png')}");
    }
    &.section-2 {
      background-image: url("${require('../../assets/images/site/bg_home_section_2_m.png')}");
    }
    &.section-3 {
      background-image: url("${require('../../assets/images/site/bg_home_section_3_m.png')}");
    }
    &.section-4 {
      background-image: url("${require('../../assets/images/site/bg_home_section_4_m.png')}");
    }
    &.section-5 {
      background-image: url("${require('../../assets/images/site/bg_home_section_5_m.png')}");
    }
    &.section-6 {
      background-image: url("${require('../../assets/images/site/bg_home_section_6_m.png')}");
    }
    &.section-7 {
      background-image: url("${require('../../assets/images/site/bg_home_section_7_m.png')}");
    }
    &.section-8 {
      background-image: url("${require('../../assets/images/site/bg_home_section_8_m.png')}");
    }
    .wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      h1, p {
        font-family: "NEXON Lv1 Gothic", sans-serif;
        color: white;
        text-align: center;
      }
      p {
        font-size: 3.6rem;
        font-weight: 300;
      }
      h1 {
        margin-top: 2rem;
        font-size: 5.8rem;
        font-weight: 700;
      }
      img {
        display: block;
        margin-top: -2rem;
      }
    }
  }
  .if-pc {
    display: none !important;
  }
  .if-m {
    display: flex !important;
  }
  @media screen and (min-width: 1280px) {
    .section {
      &.section-1 {
        margin-top: -10rem;
        background-image: none;
        .wrap {
          justify-content: flex-end;
          padding-left: 12rem;
        }
      }
      &.section-2 {
        background-image: url("${require('../../assets/images/site/bg_home_section_2.png')}");
      }
      &.section-3 {
        background-image: url("${require('../../assets/images/site/bg_home_section_3.png')}");
      }
      &.section-4 {
        background-image: url("${require('../../assets/images/site/bg_home_section_4.png')}");
      }
      &.section-5 {
        background-image: url("${require('../../assets/images/site/bg_home_section_5.png')}");
      }
      &.section-6 {
        background-image: url("${require('../../assets/images/site/bg_home_section_6.png')}");
      }
      &.section-7 {
        background-image: url("${require('../../assets/images/site/bg_home_section_7.png')}");
      }
      &.section-8 {
        background-image: url("${require('../../assets/images/site/bg_home_section_8.png')}");
      }
    }
    .if-pc {
      display: flex !important;
    }
    .if-m {
      display: none !important;
    }
    .header-wrap {
      
    }
  }
`;

export default Home;