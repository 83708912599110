import styled from 'styled-components';

const GoTop = ({ showGoTop, onScrollTop }) => {
  return (
    <Button type="button" className={showGoTop ? 'go-top active' : 'go-top'} onClick={onScrollTop}>맨 위로</Button>
  )
}

const Button = styled.button`
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  font-size: 0;
  width: 4.1rem;
  height: 4.1rem;
  background-image: url("${require('../../../assets/images/customer/arrow_go_top.png')}");
  background-repeat: no-repeat;
  background-size: 100%;
  visibility: hidden;
  opacity: 0;
  &.active {
    visibility: visible;
    opacity: 1;
    transition: 0.1s;
  }
  @media screen and (min-width: 800px) {
    position: absolute;
    bottom: 11rem;
    width: 6.2rem;
    height: 6.2rem;
    background-image: url("${require('../../../assets/images/site/icon_go_top.png')}");
  }
`;

export default GoTop