import { useState, useRef, useEffect } from 'react';
import useStore from '../../../store/hwadap/store';
import { useTranslation } from 'react-i18next';
import Header from '../layout/Header';
import Arrow from '../layout/Arrow';
import Message from './Message';
import Toast from '../layout/Toast';
import styled from "styled-components";

const List = ({ hwadapList, setHwadapList, handleGetHwadapList }) => {
  const { t } = useTranslation();
  const roomRef = useRef();                                                          // DIV Ref
  const [isSearched, setIsSearched] = useState(false);                               // 검색 여부
  const [searchedWord, setSearchedWord] = useState('');                              // 검색된 단어
  const hwadap = useStore((state) => state.hwadap);                                  // Get 화답 리스트
  const showMoreBtn = useStore((state) => state.showMoreBtn);                        // Get 10건 더보기
  const isIncomplete = useStore((state) => state.isIncomplete);                      // Get 미화답 체크 여부
  const setIsIncomplete = useStore((state) => state.setIsIncomplete);                // Set 미화답 체크 여부
  const lastSendIdx = useStore((state) => state.lastSendIdx);                        // Get lastSendIdx
  const setBeforeLastSendIdx = useStore((state) => state.setBeforeLastSendIdx);      // Set 10건 더보기

  /** 10건 더보기 */
  const onGetHwadapListMore = () => {
    if (isSearched) {
      onSearchClear();
    }
    if (isIncomplete) {
      setIsIncomplete(false);
    }
    setBeforeLastSendIdx(lastSendIdx);
    handleGetHwadapList();
  }

  /** 검색하기 */
  const onSearch = (text) => {
    const list = [...hwadap];
    const filterData = list.filter((i) => {
      if (i.Hwadoo.title.title_ko.includes(text) || i.Hwadoo.msgContent.msgContent_ko.includes(text)) {
        return i;
      }
    });
    setHwadapList(filterData.reverse());
    setIsSearched(true);
    setSearchedWord(text);
  };

  /** 검색 초기화 */
  const onSearchClear = () => {
    if (!isSearched) {
      setIsSearched(false);
      return;
    }
    const list = [...hwadap];
    setHwadapList(list.reverse());
    setIsSearched(false);
    setIsIncomplete(false);
  }

  /** 미화답만 보기 토글 */
  const onIncomplte = (e) => {
    setIsIncomplete(e.target.checked);
  }
  
  /** 미화답 정렬 */
  useEffect(() => {
    window.scrollTo({ top: 0 });
    if (isSearched && !isIncomplete) {
      onSearch(searchedWord);
      return;
    }
    if (isIncomplete) {
      const processList = hwadapList.filter((i) => {
        const length = i.myHdap?.length ?? 0;
        const today = new Date();
        const magamDay = new Date(i.magamTimeZoneDtm);
        if (length < 1 && today < magamDay) {
          return i;
        }
      });
      setHwadapList(processList);
    } else {
      const list = [...hwadap];
      setHwadapList(list.reverse());
    }
  }, [isIncomplete]);

  return (
    <Container ref={roomRef}>
      <Header
        title={t('화답메시지 전체 목록')}
        onSearch={onSearch}
        onSearchClear={onSearchClear}
        setIsSearched={setIsSearched}
      />
      <div className="content">
        <div className="top">
          <div className="all">{t('전체')}<span>{hwadapList.length}</span></div>
          <div className="incomplete">
            <input type="checkbox" value={isIncomplete} checked={isIncomplete} id='viewIncomplete' onChange={onIncomplte} />
            <label htmlFor="viewIncomplete">{t('미화답만 보기')}</label>
          </div>
        </div>
        <div className="list">
          <ul>
            {hwadapList.map((item) => (
              <Message key={item.sendIdx} item={item} />
            ))}
          </ul>
        </div>
      </div>
      <Arrow isSearched={isSearched} roomRef={roomRef} />
      {showMoreBtn &&
        <Toast message={t('10건 더보기')} page='all' type='more' onGetMore={onGetHwadapListMore} />
      }
    </Container>
  )
}

const Container = styled.div`
  padding: 5.02rem 0;
  .content {
    padding: 2.1rem 1.4rem;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .all {
        font-size: 1.4rem;
        color: #777;
        span {
          margin-left: 0.4rem;
          font-weight: 500;
          color: #31c5af;
        }
      }
      .incomplete {
        input[type='checkbox'] + label {
          padding-left: 4rem;
          height: 2rem;
          font-size: 1.3rem;
          font-weight: 500;
          color: #454545;
          background-image: url("${require('../../../assets/images/hwadap/form_check.png')}");
          background-size: auto 100%;
          cursor: pointer;
        }
        input[type='checkbox']:checked + label {
          background-image: url("${require('../../../assets/images/hwadap/form_check_active.png')}");
        }
      }
    }
    .list {
      margin-top: 1.35rem;
    }
  }
`;

export default List