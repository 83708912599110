import client from '../../lib/api/signup';
import useStore from "../../store/signup/store";
import Header from '../../components/signup/Header';
import NewPassword from '../../components/signup/NewPassword';
import Alert from '../../components/signup/Alert';

const NewPasswordPage = () => {
  const popup = useStore((state) => state.popup);              // Get Popup
  const setPopup = useStore((state) => state.setPopup);        // Set Popup

  // 패스워드 재설정
  const handleUserPwdReset = async (userData) => {
    try {
      const resPassword = await client.post('/sha256', { password: userData.pwd });
      userData.pwd = resPassword.data;
      const { data } = await client.post('/member/userPwdReset', userData);
      console.log(data);
      if (data.content.status === 'failed') {
        setPopup({ type: 'alert', message: data.content.Msg, isOpen: true });
        return;
      }
      setPopup({ type: 'newPassword', isOpen: true });
    } catch (e) {
      setPopup({ type: 'alert', message: 'Server Error.', isOpen: true });
    }
  }

  return (
    <div className='signup'>
      <Header prev='prev' />
      <NewPassword handleUserPwdReset={handleUserPwdReset} />
      {popup.isOpen && <Alert />}
    </div>
  )
}

export default NewPasswordPage