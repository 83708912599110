import React from 'react';
import styled from "styled-components";

const Button = ({title, color, onClick, marginTop}) => {
  return (
    <Container
      onClick={onClick}
      style={{
        backgroundColor: color === 'mint' ? '#00d2b4' : color === 'yellow' ? '#FFE26B' : '#999999',
        color: color === 'yellow' ? '#222' : '#fff',
        marginTop: marginTop ? '1rem' : '',
      }}>
      {title}
    </Container>
  )
}

const Container = styled.button`
  width: 100%;
  height: 4.7rem;
  font-size: 1.6rem;
  font-weight: 700;
  border-radius: 2.4rem;
`;

export default Button;