import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import useCommonStore from "./store/common/store";
import useHomepageStore from "./store/site/common";
import TestPage from "./pages/Test";
import HomePage from "./pages/site/Home.page";
import AuthHomePage from "./pages/signup/AuthHome.page";
import RegisterPage from "./pages/signup/Register.page";
import AuthenticationCallbackPage from "./pages/signup/AuthenticationCallback.page";
import FindResultPage from "./pages/signup/FindResult.page";
import NewPasswordPage from "./pages/signup/NewPassword.page";
import AgreeInfoPage from "./pages/signup/AgreeInfo.page";
import OauthCallbackPage from "./pages/signup/OauthCallback.page";
import HwadapLoginPage from "./pages/hwadap/Login.page"; 
import HwadapRoomPage from "./pages/hwadap/Room.page"; 
import HwadapGroupRoomPage from "./pages/hwadap/GroupRoom.page"; 
import HwadapAllListPage from "./pages/hwadap/AllList.page";
import CustomerLoginPage from "./pages/site/Login.page";
import NoticeListPage from "./pages/site/notice/NoticeList.page";
import NoticeDetailPage from "./pages/site/notice/NoticeDetail.page";
import ContactUsPage from "./pages/site/contact/ContactUs.page";
import ContactUsFormPage from "./pages/site/contact/ContactUsForm.page";
import HelpListPage from "./pages/site/help/HelpList.page";
import HelpDetailPage from "./pages/site/help/HelpDetail.page";
import CustomerPolicyPage from "./pages/site/policy/Policy.page";
import CommunityLoginPage from './pages/community/home/Login.page';
import CommunityIntroPage from './pages/community/home/Intro.page';
import CommunityAlarmPage from './pages/community/etc/Alarm.page';
import CommunitySearchPage from './pages/community/etc/Search.page';
import CommunitySearchResultPage from './pages/community/etc/SearchResult.page';
import CommunityFeedListPage from './pages/community/feed/List.page';
import CommunityFeedDetailPage from './pages/community/feed/Detail.page';
import CommunityMypageHomePage from './pages/community/mypage/Home.page';
import CommunityMypageProfilePage from './pages/community/mypage/Profile.page';
import './App.css';

function App() {
  const location = useLocation();
  const { i18n } = useTranslation();
  const setLangCd = useCommonStore((state) => state.setLangCd);
  const setNationCd = useCommonStore((state) => state.setNationCd);
  const setIsPC = useCommonStore((state) => state.setIsPC);
  const homepageLangCd = useHomepageStore((state) => state.langCd);
  const setHomepageLangCd = useHomepageStore((state) => state.setLangCd);
  const setOsTypeCd = useHomepageStore((state) => state.setOsTypeCd);
  const setIsWeb = useHomepageStore((state) => state.setIsWeb);
  const searchParams = new URLSearchParams(location.search);
  const langCd = searchParams.get('langcd') ?? '';
  const nationCd = searchParams.get('nationcd') ?? '';
  
  useEffect(() => {

    /** Init */
    i18n.changeLanguage(langCd);
    if (langCd === 'ja') {
      document.body.classList.add('jp');
    }
    setLangCd(langCd);
    setNationCd(nationCd);
    setIsPC(navigator.userAgent.includes('Windows') || navigator.userAgent.includes('Macintosh'));

    /** for Homepage + Customer */
    const agent = navigator.userAgent.toLowerCase();
    const browser = ["chrome", "safari", "edge", "firefox", "whale", "msie"];
    const os = ["and", "ios", "mac", "win"];

    if (browser.some((i) => agent.includes(i))) {
      setHomepageLangCd(navigator.language.substring(0, 2));
      setIsWeb(true);
    }
    os.some((i) => {
      if (agent.includes(i)) {
        setOsTypeCd(i);
      }
    });
  }, []);

  return (
    <>
        <Helmet>
          {homepageLangCd === "ko"
          ?
            <>
              <title>Zero(지로)</title>
              <meta name="title" property="og:title" content="Zero(지로)" />
              <meta name="description" property="og:description" content="1억 지구시민 놀이터, Zero" />
            </>
          :
            <>
              <title>Zero</title>
              <meta name="title" property="og:title" content="Zero" />
              <meta name="description" property="og:description" content="From Zero, connect to all" />
            </>
          }
          <meta name="url" property="og:url" content="https://www.zerohsp.com" />
        </Helmet>
      <Routes>
        <Route path="/test" element={<TestPage />} />
        {/** 홈페이지 */}
        <Route path="/" element={<HomePage />} />
        <Route path="/customer/login" element={<CustomerLoginPage />} />
        <Route path="/customer/notice" element={<NoticeListPage />} />
        <Route path="/customer/notice/:id" element={<NoticeDetailPage />} />
        <Route path="/customer/contact" element={<ContactUsPage />} />
        <Route path="/customer/contact/form" element={<ContactUsFormPage />} />
        <Route path="/customer/help" element={<HelpListPage />} />
        <Route path="/customer/help/:id" element={<HelpDetailPage />} />
        <Route path="/policy/:id" element={<CustomerPolicyPage />} />

        {/** 회원가입 */}
        <Route path="/signup/auth/:type" element={<AuthHomePage />} />
        <Route path="/signup/register" element={<RegisterPage />} />
        <Route path="/signup/authentication/callback" element={<AuthenticationCallbackPage />} />
        <Route path="/signup/findresult" element={<FindResultPage />} />
        <Route path="/signup/newpassword" element={<NewPasswordPage />} />
        <Route path="/signup/agreeinfo" element={<AgreeInfoPage />} />
        <Route path="/signup/sns" element={<OauthCallbackPage />} />
        
        {/** 화답하기 */}
        <Route path="/hwadap/login" element={<HwadapLoginPage />} />
        <Route path="/hwadap/room" element={<HwadapRoomPage />} />
        <Route path="/hwadap/room/:id" element={<HwadapGroupRoomPage />} />
        <Route path="/hwadap/all" element={<HwadapAllListPage />} />

        {/** 커뮤니티 */}
        <Route path="/cummunity/login" element={<CommunityLoginPage />} />
        <Route path="/cummunity/intro" element={<CommunityIntroPage />} />
        <Route path="/cummunity/alarm" element={<CommunityAlarmPage />} />
        <Route path="/cummunity/search" element={<CommunitySearchPage />} />
        <Route path="/cummunity/search/:word" element={<CommunitySearchResultPage />} />
        <Route path="/cummunity/feed" element={<CommunityFeedListPage />} />
        <Route path="/cummunity/feed/:id" element={<CommunityFeedDetailPage />} />
        <Route path="/cummunity/mypage" element={<CommunityMypageHomePage />} />
        <Route path="/cummunity/proflie" element={<CommunityMypageProfilePage />} />
      </Routes>
    </>
  );
}

export default App;
