import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import useStore from '../../../store/site/store';
import styled from 'styled-components';

const ContactUs = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const contact = useStore((state) => state.contact);
  const setCategoryName = useStore((state) => state.setCategoryName);

  const onContactForm = (categoryCd, categoryNm) => {
    setCategoryName(categoryNm);
    navigate(`/customer/contact/form?category=${categoryCd}`)
  }

  return (
    <Container className="content-wrap">
      <h1 className="page-title">{t('무엇이 궁금하신가요?')}</h1>
      <ul>
        {contact.map((i) => (
          <li key={i.categoryCd}>
            <button type="button" onClick={() => onContactForm(i.categoryCd, i.categoryNm)}>{i.categoryNm}</button>
          </li>
        ))}
      </ul>
    </Container>
  )
}

const Container = styled.div`
  padding: 2.5rem;
  h1 {
    font-size: 1.9rem;
    font-weight: bold;
    color: #222;
    line-height: 2.4rem;
  }
  ul {
    margin-top: 1.5rem;
    li {
      border-bottom: 1px solid #ededed;
      button {
        display: block;
        width: 100%;
        padding: 1.8rem 0 2.1rem 0;
        text-align: left;
        font-size: 1.6rem;
        line-height: 1;
      }
    }
  }
  @media screen and (min-width: 800px) {
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 3.5rem;
      li {
        width: calc(50% - 1rem);
        margin-bottom: 2rem;
        border: 2px solid #EDEDED;
        border-radius: 1rem;
        &:hover {
          font-weight: 600;
          color: #1DC1AE;
          background-color: #F6FFFD;
          border-color: #00D2B4;
          transition: 0.1s;
        }
        button {
          padding: 3.7rem 3.5rem;
          font-size: 1.9rem;
          font-weight: 500;
        }
      }
    }
  }
`;

export default ContactUs