import { useEffect, useRef, useState } from 'react';
import useCommonStore from "../../store/common/store";
import useStore from "../../store/signup/store";
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Auth = ({
    seconds,
    minutes,
    restart,
    isSent,
    setIsSent,
    authResendRef,
    handleCallSMS,
    handleExtendTime,
    showSendBtn,
    setShowSubmitBtn,
    authResendCount,
    setAuthResendCount,
    hideSendBtn,
    findAfterReset,
    scrollToBottom,
  }) => {
  const { t } = useTranslation();                                  // 다국어
  const langCd = useCommonStore((state) => state.langCd);          // Get 언어
  const user = useStore((state) => state.user);                    // Get 유저
  const setUser = useStore((state) => state.setUser);              // Set 유저
  const nation = useStore((state) => state.nation);                // Get 국가 리스트
  const error = useStore((state) => state.error);                  // Get 에러문구
  const setError = useStore((state) => state.setError);            // Set 에러문구
  const descript = useStore((state) => state.descript);            // Get 안내문구
  const setDescript = useStore((state) => state.setDescript);      // Set 안내문구
  const setPopup = useStore((state) => state.setPopup);            // Set Popup
  const [showSendMsg, setShowSendMsg] = useState(false);           // 인증번호 발송 메세지
  const sendMsgTimerRef = useRef(null);                            // 인증번호 발송 메세지 타이머
  const authCodeRef = useRef(null);                                // 인증번호 Input
  
  // 국가코드
  const onChangePhoneNationNum = (e) => {
    const match = nation.filter((n) => (n.nationCd === e.target.value));
    setUser({ ...user, phoneNationNum: match[0].phoneNationNum, nationCd: match[0].nationCd });
  }

  // 휴대폰 번호
  const onChangeMPhoneNum = (e) => {
    if (e.target.value.length > 20){
      e.target.value = e.value.slice(0, 20);
    }
    setError({ ...error, mPhoneNum: '' });
    setUser({ ...user, mPhoneNum: e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1') });
  }

  // 휴대폰 번호 체크
  const onCheckMPhoneNum = (e) => {
    if (e.target.value.length < 10) {
      setError({ ...error, mPhoneNum: t("필수 입력 사항이에요.") });
      return;
    }
    setError({ ...error, mPhoneNum: '' });
  }

  // 인증번호 발송
  const onCallSMS = () => {
    if (authResendRef.current > 0) {
      if (langCd !== 'ko' && langCd !=='ja') {
        setPopup({ type: 'alert', message: 'Please wait ' + authResendRef.current + t("초 후 인증 번호 재발송이 가능합니다."), isOpen: true });
      } else {
        setPopup({ type: 'alert', message: authResendRef.current + t("초 후 인증 번호 재발송이 가능합니다."), isOpen: true });
      }
      return;
    }
    if (authResendCount > 4) {
      setPopup({ type: 'alert', message: t("재발송 횟수가 5회가 넘었어요. 5분간 발송이 제한돼요."), isOpen: true });
      authResendRef.current = 300;
      const time = new Date();
      time.setSeconds(time.getSeconds());
      restart(time);
      setAuthResendCount(authResendCount - 4);
      return;
    }
    setShowSendMsg(true);
    if (authResendCount === 0 || seconds < 1) {
      const time = new Date();
      time.setSeconds(time.getSeconds() + 300);
      restart(time);
    }
    
    authResendRef.current = 30;
    setAuthResendCount(authResendCount + 1);
    handleCallSMS();

    sendMsgTimerRef.current = setTimeout(() => {
      setShowSendMsg(false);
    }, 2000);
  }

  // 인증번호
  const onchangeAuthCode = (e) => {
    setUser({ ...user, authCode: e.target.value });
    if (e.target.value.length > 3) {
      setShowSubmitBtn(true);
    } else {
      setShowSubmitBtn(false);
    }
  }

  // 인증 시간 연장
  const onExtendTime = () => {
    if (minutes < 1 && seconds < 1) {
      return;
    }
    handleExtendTime();
  }

  // 인증번호 입력 후 키패드 닫기
  const onHideKeypad = (key) => {
    if (key.keyCode == 13) {
      key.preventDefault();
      authCodeRef.current.blur();
    }
  }

  useEffect(() => {
    let nationCd = new URL(encodeURI(window.location.href)).searchParams.get("nationcd") ?? '';
    // nationCd가 있으면
    if (nation?.length > 0 && nationCd?.length > 0 && nationCd !== 'null') {
      const match = nation.filter((n) => (n.nationCd.toUpperCase() === nationCd.toUpperCase()));
      setUser({ ...user, phoneNationNum: match[0]?.phoneNationNum, nationCd: match[0]?.nationCd });
    }
    // nationCd가 없으면
    else {
      let num = '';
      if (langCd === 'ko') {
        num = '82';
        nationCd = 'KR'
      } else if (langCd === 'ja') {
        num = '81';
        nationCd = 'JP';
      } else {
        num = '1';
        nationCd = 'US';
      }
      setUser({ ...user, phoneNationNum: num, nationCd: nationCd });
    }

    return () => {
      clearTimeout(sendMsgTimerRef.current);
    }
  }, [langCd, nation]);
  
  // 인증번호 재발송 타이머
  let reSendTimer = null;
  useEffect(() => {
    if (authResendCount > 0) {
      reSendTimer = setInterval(() => {
        authResendRef.current -= 1;
        if (authResendRef.current < 1) {
          clearInterval(reSendTimer);
        }
      }, 1000);
    }
    return () => {
      clearInterval(reSendTimer);
    }
  }, [authResendCount]);

  return (
    <Container>
      <div className="select-wrap">
        {nation &&
          <select onChange={onChangePhoneNationNum} value={user.nationCd} className='black' disabled={findAfterReset}>
            {nation.map((item) => (
              <option key={item.nationCd} value={item.nationCd}>(+{item.phoneNationNum}) {item.nationNm}</option>
            ))}
          </select>
        }
      </div>
      <div className="input-wrap">
        <div className="wrap">
          <input
            type="number"
            pattern="[0-9]*"
            onBlur={onCheckMPhoneNum}
            onChange={onChangeMPhoneNum}
            value={user.mPhoneNum}
            disabled={findAfterReset}
          />
          <label>{t('휴대폰 번호 입력')}</label>
          {showSendBtn &&
            <button type='button' className='btn sm round green' onClick={onCallSMS}>{t("인증번호 발송")}</button>
          }
          {isSent &&
            <button type='button' className='btn sm round gray' onClick={onCallSMS}>{t("인증번호 재발송")}</button>
          }
          {isSent &&
            <button type='button' className='btn sm round gray' onClick={onCallSMS}>{t("인증번호 재발송")}</button>
          }
          {!showSendBtn && !isSent
            ? <button type='button' className={hideSendBtn ? 'btn sm round gray hide' : 'btn sm round gray'}>{t("인증번호 발송")}</button>
            : <></>
          }
        </div>
        <p>
          {descript.mPhoneNum && <span>{descript.mPhoneNum}</span>}
          {error.mPhoneNum && <span className='error'>{error.mPhoneNum}</span>}
        </p>
      </div>
      <div className={isSent ? 'input-wrap show' : 'input-wrap hide'}>
        <input
          ref={authCodeRef}
          type="number"
          pattern="[0-9]*"
          value={user.authCode}
          onFocus={scrollToBottom}
          onChange={onchangeAuthCode}
          onKeyDown={onHideKeypad}
        />
        {isSent &&
          <div className="time-box">
            <div className="time">
              <span>{minutes < 10 && 0}{minutes}:{seconds < 10 && 0}{seconds}</span>  
            </div>
            <button type='button' className='extension-btn' onClick={onExtendTime}>{t("인증시간 연장")}</button>
          </div>
        }
      </div>
      <div className={showSendMsg ? 'send-message active' : 'send-message'}>
        <span>{t("인증번호가 문자로 발송되었어요.")}</span>
      </div>
    </Container>
  )
}

const Container = styled.div`
  .input-wrap {
    button.hide {
      visibility: hidden;
    }
  }
  .send-message {
    position: absolute;
    bottom: 11rem;
    left: 0;
    width: 100%;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    z-index: 3;
    &.active {
      visibility: visible;
      opacity: 1;
      transition: visibility opacity 0.2s;
    }
    span {
      display: inline-block;
      height: 3.25rem;
      padding: 0 1.5rem;
      font-size: 1.3rem;
      color: #fff;
      line-height: 3.25rem;
      border-radius: 1.7rem;
      background-color: rgba(0,0,0,0.7);
    }
  }
  .time-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.95rem;
    .time {
      padding-left: 1.6rem;
      background-image: url("${require('../../assets/images/signup/icon_time.png')}");
      background-repeat: no-repeat;
      background-position: 0 4px;
      background-size: auto 1.25rem;
      span {
        color: #00d2b4;
      }
    }
    .extension-btn {
      font-weight: 500;
      color: #454545;
      border-bottom: 1px solid #454545;
    }
  }
`;

export default Auth