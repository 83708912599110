import { useEffect, useRef } from "react";
import useStore from "../../../store/signup/store";
import { useTranslation } from 'react-i18next';

const Email = () => {
  const { t } = useTranslation();                                // 다국어
  const user = useStore((state) => state.user);                  // Get 유저
  const setUser = useStore((state) => state.setUser);            // Set 유저
  const error = useStore((state) => state.error);                // Get 에러문구
  const setError = useStore((state) => state.setError);          // Set 에러문구
  const descript = useStore((state) => state.descript);          // Get 안내문구
  const setDescript = useStore((state) => state.setDescript);    // Set 안내문구
  const emailRef = useRef('');                                   // 이메일 Ref

  // 이메일
  const onChangeEmail = (e) => {
    setUser({ ...user, email: e.target.value });
    const reg_email = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
    if (e.target.value.length > 0 && !reg_email.test(e.target.value)) {
      setError({ ...error, email: t("이메일 형식에 맞지 않아요.") });
      return;
    }
    setError({ ...error, email: '' });      
  }

  // 마운트 시 자동 포커스
  useEffect(() => {
    emailRef.current.focus();
  }, []);

  return (
    <>
      <div className="input-wrap">
        <input
          type="email"
          ref={emailRef}
          onChange={onChangeEmail}
          value={user.email}
          tabIndex="1"
          placeholder={t('이메일')}
        />
        <label>{t('이메일 입력')}</label>
        <p>
          {descript.email && <span>{descript.email}</span>}
          {error.email && <span className='error'>{error.email}</span>}
        </p>
      </div>
    </>
  )
}

export default Email