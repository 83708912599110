import { useState, useEffect } from 'react';
import useStore from "../../store/signup/store";
import { useTranslation } from 'react-i18next';
import Password from './Password';
import styled from 'styled-components';

const NewPassword = ({ handleUserPwdReset }) => {
  const { t } = useTranslation();                                    // 다국어
  const user = useStore((state) => state.user);                      // Get 유저
  const error = useStore((state) => state.error);                    // Get 에러문구
  const setError = useStore((state) => state.setError);              // Set 에러문구
  const [showSubmitBtn, setShowSubmitBtn] = useState(false);         // 로그인 페이지로 버튼 토글

  // 로그인 페이지로 이동 클릭
  const onRegister = () => {
    if (user.password !== user.confirmPassword) {
      setError({ ...error, password: '', confirmPassword: t("비밀번호가 일치하지 않아요.") });
      return;
    }
    const userData = {
      userID: user.userID,
      pwd: user.password
    }
    handleUserPwdReset(userData);
  }

  // 로그인 페이지로 버튼 핸들링
  useEffect(() => {
    if (user.password === '' || user.confirmPassword === '') {
      setShowSubmitBtn(false);
      return;
    }
    if (error.password !== '' || error.confirmPassword !== '') {
      setShowSubmitBtn(false);
      return;
    }  
    setShowSubmitBtn(true);
  }, [user.password, user.confirmPassword]);

  return (
    <Container className='container'>
      <div className="content-wrap">
        <h2>{t("새로운 비밀번호를 설정해주세요.")}</h2>
        <div className="result">
          <div className="id">{t("아이디")} : <span>{user.userID}</span></div>
        </div>
        <div className="password-wrap">
          <Password setShowSubmitBtn={setShowSubmitBtn} />
        </div>
      </div>
      <div className="btn-wrap fixed">
        {showSubmitBtn
          ? <button type='button' className='btn lg green' onClick={onRegister}>{t("로그인 페이지")}</button>
          : <button type='button' className='btn lg gray'>{t("로그인 페이지")}</button>
        }
      </div>
    </Container>
  )
}

const Container = styled.div`
  .content-wrap {
    h2 {
      padding: 0 2.5rem;
      font-size: 1.9rem;
      color: #222;
    }
    .result {
      margin-top: 1.15rem;
      padding: 0 2.5rem;
      .id {
        font-size: 1.9rem;
        font-weight: 700;
        color: #00d2b4;
      }
    }
  }
  .password-wrap {
    margin-top: 4.9rem;
  }
`;

export default NewPassword